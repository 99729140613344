import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WfhRequestActionModel } from 'src/app/shared/models/wfh-request-action-model';
import { wfhRequestModel } from 'src/app/shared/models/wfh-request-model';
import { HttpRequestService } from '..';

@Injectable({
  providedIn: 'root'
})
export class WfhRequestApproverService {
  private stateUrl = 'api/v1/action/wfh-request';

  constructor(
    private httpRequest: HttpRequestService
  ) { }

  fetchAllPendingWfhRequest(): Observable<WfhRequestActionModel[]> {
    return this.httpRequest.get(this.stateUrl + '/');
  }

  approveWfh(WfhRequestActionModel: WfhRequestActionModel): Observable<any> {
    return this.httpRequest.put(this.stateUrl + '/approved/', WfhRequestActionModel);
  }
  
  rejectWfh(WfhRequestActionModel: WfhRequestActionModel): Observable<any> {
    return this.httpRequest.put(this.stateUrl + '/rejected/', WfhRequestActionModel);
  }
  // fetchHistoricalLeaveRequest(searchFormFields):  Observable<HistoricalLeaveRequestModel[]> {
  //   return this.httpRequest.post(this.stateUrl + '/historical-leave-request', searchFormFields);
  // }
  approvedWfhCancelRequestAction(data): Observable<any> {
    return this.httpRequest.put(this.stateUrl + '/approved-wfh-cancel-request-action/', data);
  }
  rejectWfhCancelRequestAction(data): Observable<any> {
    return this.httpRequest.put(this.stateUrl + '/rejecte-wfh-cancel-request-action/', data);
  }
  fetchChartData():Observable<any>{
    return this.httpRequest.get(this.stateUrl + '/chart');
  }
}
