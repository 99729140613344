import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { customValidators } from '../factory/common.service';
import { LeaveRequestDetailModel } from './leave-request-detail-dtos-model';
export interface LeaveActionModel {
    id: number,
    reasonForLeave: string,
    leaveTypeId: number,
    teamNotificationEmail: string,
   // employeeDTOLite: string,
    //leaveRequestState: string,
    leaveRequestApprovers: Array<any>,
    totalLeaveCount: number,

    approverActionComment: string
    //: Array<Date>;
    // leaveRequestDetailDTOs: Array<LeaveRequestDetailModel>; 
    leaveRequestDetailDTOs: LeaveRequestDetailModel[];
    missingAttendance: string;
}

export enum MissingAttendance{
    MISSINGATTENDANCE = 'MISSINGATTENDANCE'
}

export class  LeaveActionBuilder {
    leaveActionModel: LeaveActionModel = {
        id: null,
        reasonForLeave: '',
        leaveTypeId: null,
        teamNotificationEmail: '',
        // employeeDTOLite: 'pqr',
        //  leaveRequestState: '',
        leaveRequestApprovers: [],
        totalLeaveCount: null,
        approverActionComment: '',
        //  leaveDate: [],
        leaveRequestDetailDTOs: [],
        missingAttendance: MissingAttendance.MISSINGATTENDANCE
    };

    leaveActionRequestForm: FormGroup = new FormGroup({
        reasonForLeave: new FormControl(this.leaveActionModel.reasonForLeave),
        leaveTypeId: new FormControl(this.leaveActionModel.leaveTypeId),
        teamNotificationEmail: new FormControl(this.leaveActionModel.teamNotificationEmail),
          //employeeDTOLite: new FormControl(this.leaveActionModel.employeeDTOLite),
        // leaveRequestState: new FormControl(this.leaveActionModel.leaveRequestState, [Validators.required]),
        leaveRequestApprovers: new FormControl(this.leaveActionModel.leaveRequestApprovers),
        totalLeaveCount: new FormControl(this.leaveActionModel.totalLeaveCount),
        fromDate: new FormControl(''),
        toDate: new FormControl(''),
        approverActionComment: new FormControl(this.leaveActionModel.approverActionComment),
        //leaveDate: new FormControl(this.leaveRequestModel.leaveRequestDetailDTOs.leaveDate),
        // leaveRequestType: new FormControl(this.leaveRequestModel.leaveRequestDetailDTOs.leaveRequestType)
        leaveRequestDetailDTOs: new FormArray([])
    });
    constructor() {
        this.leaveActionRequestForm.valueChanges.subscribe(val => {
            this.leaveActionModel.reasonForLeave = val.reasonForLeave;
            this.leaveActionModel.leaveTypeId = val.leaveTypeId;
            this.leaveActionModel.teamNotificationEmail = val.teamNotificationEmail;
            // this.leaveActionModel.employeeDTOLite = val.employeeDTOLite;
            // this.leaveActionModel.leaveRequestState = val.leaveRequestState;
            this.leaveActionModel.leaveRequestApprovers = val.leaveRequestApprovers;
            this.leaveActionModel.totalLeaveCount = val.totalLeaveCount;
            //  this.leaveRequestModel.fromDate = val.fromDate;
            //  this.leaveRequestModel.toDate = val.toDate;
            this.leaveActionModel.approverActionComment = val.approverActionComment;
            //this.leaveRequestModel.leaveRequestDetailDTOs.leaveDate = val.leaveDate;
            //this.leaveRequestModel.leaveRequestDetailDTOs.leaveRequestType = val.leaveRequestType;
            this.leaveActionModel.leaveRequestDetailDTOs = val.leaveRequestDetailDTOs;
            this.leaveActionModel.missingAttendance = val.missingAttendance;
        });
    }
    setModelVals(res: LeaveActionModel) {
        this.leaveActionRequestForm.controls.reasonForLeave.setValue(res.reasonForLeave);
        this.leaveActionRequestForm.controls.leaveTypeId.setValue(res.leaveTypeId);
        this.leaveActionRequestForm.controls.teamNotificationEmail.setValue(res.teamNotificationEmail);
          // this.leaveRequestForm.controls.employeeDTOLite.setValue(res.employeeDTOLite);
        // this.leaveActionRequestForm.controls.leaveRequestState.setValue(res.leaveRequestState);
        this.leaveActionRequestForm.controls.leaveRequestApprovers.setValue(res.leaveRequestApprovers);
        this.leaveActionRequestForm.controls.totalLeaveCount.setValue(res.totalLeaveCount);
        this.leaveActionRequestForm.controls.approverActionComment.setValue(res.approverActionComment);
        // this.leaveRequestForm.controls.fromDate.setValue(res.fromDate);
        // this.leaveRequestForm.controls.toDate.setValue(res.toDate);
        //  this.leaveRequestForm.controls.leaveDate.setValue(res.leaveRequestDetailDTOs.leaveDate);
        //this.leaveRequestForm.controls.leaveRequestType.setValue(res.leaveRequestDetailDTOs.leaveRequestType);
        for (var i = 0; i < res.leaveRequestDetailDTOs.length; i++) {
            let leaveDetailformGroup = new FormGroup({
                leaveDate: new FormControl(res.leaveRequestDetailDTOs[i].leaveDate),
                leaveRequestType: new FormControl(res.leaveRequestDetailDTOs[i].leaveRequestType)
            })
            let leaveRequestDetailArray = this.leaveActionRequestForm.get('leaveRequestDetailDTOs') as FormArray;
            leaveRequestDetailArray.push(leaveDetailformGroup);
            //this.leaveRequestForm.controls.leaveRequestDetailDTOs.setValue(res.leaveRequestDetailDTOs);
        }
    }
}