import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { customValidators } from '../factory/common.service';
import { LeaveRequestDetailModel } from './leave-request-detail-dtos-model';
export interface HistoricalLeaveRequestModel {
    id: number,
    leaveTypeId: number,
    leaveTypeName: string,
    employeeDTOLite: {
        id: number,
        firstName: string,
        lastName: string,
    },// id, firstName, lastName
    leaveRequestState: string,
    leaveRequestApprovers: Array<any>,
    totalLeaveCount: number, 
    leaveRequestDetailDTOs: LeaveRequestDetailModel[];
}

export class HistoricalLeaveRequestBuilder {
    historicalLeaveRequestModel: HistoricalLeaveRequestModel = {
        id: null,
        leaveTypeId: null,
        leaveTypeName: '',
        employeeDTOLite: {
            id: null,
            firstName: '',
            lastName: ''
        },
        leaveRequestState: '',
        leaveRequestApprovers: [],
        totalLeaveCount: null,
        leaveRequestDetailDTOs: []
    };

    historicalLeaveRequestForm: FormGroup = new FormGroup({
       
        leaveTypeId: new FormControl(this.historicalLeaveRequestModel.leaveTypeId, [Validators.required]),
        leaveTypeName: new FormControl(this.historicalLeaveRequestModel.leaveTypeName),
        employeeDTOLite: new FormGroup({
            id: new FormControl(this.historicalLeaveRequestModel.employeeDTOLite.id),
            firstName: new FormControl(this.historicalLeaveRequestModel.employeeDTOLite.firstName),
            lastName: new FormControl(this.historicalLeaveRequestModel.employeeDTOLite.lastName)
        }),
        leaveRequestState: new FormControl(this.historicalLeaveRequestModel.leaveRequestState, [Validators.required]),
        leaveRequestApprovers: new FormControl(this.historicalLeaveRequestModel.leaveRequestApprovers, [Validators.required]),
        totalLeaveCount: new FormControl(this.historicalLeaveRequestModel.totalLeaveCount),
      
        leaveRequestDetailDTOs: new FormArray([])

    });

    constructor() {
        this.historicalLeaveRequestForm.valueChanges.subscribe(val => {

            this.historicalLeaveRequestModel.leaveTypeId = val.leaveTypeId;
            this.historicalLeaveRequestModel.leaveTypeName = val.leaveTypeName;
            this.historicalLeaveRequestModel.employeeDTOLite = val.employeeDTOLite;
            this.historicalLeaveRequestModel.leaveRequestState = val.leaveRequestState;
            this.historicalLeaveRequestModel.leaveRequestApprovers = val.leaveRequestApprovers;
            this.historicalLeaveRequestModel.totalLeaveCount = val.totalLeaveCount;
            this.historicalLeaveRequestModel.leaveRequestDetailDTOs = val.leaveRequestDetailDTOs;
        });
    }
    setModelVals(res: HistoricalLeaveRequestModel) {
        this.historicalLeaveRequestForm.controls.leaveTypeId.setValue(res.leaveTypeId);
        this.historicalLeaveRequestForm.controls.leaveTypeName.setValue(res.leaveTypeName);
        this.historicalLeaveRequestForm.controls.employeeDTOLite.setValue(res.employeeDTOLite);
        this.historicalLeaveRequestForm.controls.leaveRequestState.setValue(res.leaveRequestState);
        this.historicalLeaveRequestForm.controls.leaveRequestApprovers.setValue(res.leaveRequestApprovers);
        this.historicalLeaveRequestForm.controls.totalLeaveCount.setValue(res.totalLeaveCount);
    
        let employeeDetailformGroup = this.historicalLeaveRequestForm.get('employeeDTOLite') as FormGroup;
        employeeDetailformGroup.controls.id.setValue(res.employeeDTOLite.id);
        employeeDetailformGroup.controls.firstName.setValue(res.employeeDTOLite.firstName);
        employeeDetailformGroup.controls.lastName.setValue(res.employeeDTOLite.lastName);

        for (var i = 0; i < res.leaveRequestDetailDTOs.length; i++) {
            let leaveDetailformGroup = new FormGroup({
                leaveDate: new FormControl(res.leaveRequestDetailDTOs[i].leaveDate),
                leaveRequestType: new FormControl(res.leaveRequestDetailDTOs[i].leaveRequestType)
            })
            let leaveRequestDetailArray = this.historicalLeaveRequestForm.get('leaveRequestDetailDTOs') as FormArray;
            leaveRequestDetailArray.push(leaveDetailformGroup);
           
        }
    }
}