import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpRequestService } from '../http/http-request.service';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  private stateUrl = 'api/v1/metabase';

  constructor(private httpRequest: HttpRequestService) { }

  fetchMetadata(): Observable<any> {
    return this.httpRequest.get(this.stateUrl).pipe(
      map(response => {
        console.log(response)
        return response;
      })
    );
  }

}
