import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LinkCalendarModel } from 'src/app/shared/models/leave-calendar-model';
import { HttpRequestService } from '..';

@Injectable({
  providedIn: 'root'
})
export class UserCalendarService {

  private stateUrl = 'api/v1/integration';
  private calendarLinkUrl = this.stateUrl + "/link-calendar";
  private listCalendarUrl = this.stateUrl + "/list-calendar";
  private calendarSummaryUrl = this.stateUrl + "/calendar-details";
  private unlinkUrl = this.stateUrl + "/unlink";
  private calendarSummaryUpdaetUrl = this.stateUrl + "/link-calendar-summary";

  constructor(private httpRequest: HttpRequestService) { }

  connectToUserCalendar(userCalendarModel): Observable<any> {
    return this.httpRequest.post(this.calendarLinkUrl,userCalendarModel);
  }

  fetchUserCalendars(calendarEventType): Observable<any> {
    return this.httpRequest.get(this.listCalendarUrl + '?calendarEventType=' + calendarEventType);
  }

  getCalendarSummary(userCalendarId) {
    return this.httpRequest.get(this.calendarSummaryUrl + "/"+ userCalendarId);
  }

  unlinkUserCalendar(userCalendarId): Observable<any>{
    return this.httpRequest.get(this.unlinkUrl + "/"+ userCalendarId);
  }

  updateCalendarLink(calendarSummaryDTO) {
    return this.httpRequest.put(this.calendarSummaryUpdaetUrl, calendarSummaryDTO);
  }

  fetchCalendarSummary(calendarId) {
    return this.httpRequest.get(this.stateUrl + "/get-user-calendar-link/" + calendarId)
}
}
