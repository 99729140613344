import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { LeaveEncashmentRequestApproverService } from 'src/app/core/services/leave-encashment-request-approver.service';
import { LoaderService } from 'src/app/shared/factory/loader.service';
import { MessageService } from 'src/app/shared/factory/message.service';
import { LeaveEncashmentActionBuilder, LeaveEncashmentActionModel } from 'src/app/shared/models/leave-encashment-action-model';

@Component({
  selector: 'app-leave-encashment-action',
  templateUrl: './leave-encashment-action.component.html',
  styleUrls: ['./leave-encashment-action.component.css']
})
export class LeaveEncashmentActionComponent implements OnInit {

  leaveEncashmentActionBuilder: LeaveEncashmentActionBuilder = new LeaveEncashmentActionBuilder();
  leaveEncashmentActionModel: LeaveEncashmentActionModel = this.leaveEncashmentActionBuilder.leaveEncashmentActionModel;
  leaveEncashmentActionForm: FormGroup = this.leaveEncashmentActionBuilder.leaveEncashmentActionForm;

  displayedColumn = ['id', 'employeeLite', 'leaveEncashmentCount', 'leaveEncashmentRequestState', 'action'];

  dataSource: MatTableDataSource<LeaveEncashmentActionModel>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  employees = [];
  param1: number;

  constructor(private route: ActivatedRoute,
    private messageService: MessageService,
    private leaveEncashmentApproverRequestService: LeaveEncashmentRequestApproverService,
    private loaderService: LoaderService) {
    // this.route.queryParams.subscribe(params => {
    //   this.param1 = params['leaveRequestId'];
    // });
  }

  ngOnInit() {
    this.fetchAllPendingLeaveEncashmentRequest()
    this.dataSource = new MatTableDataSource<LeaveEncashmentActionModel>([]);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  fetchAllPendingLeaveEncashmentRequest() {
    this.leaveEncashmentApproverRequestService.fetchAllPendingLeaveEncashmentRequest().subscribe((res: LeaveEncashmentActionModel[]) => {
      this.dataSource = new MatTableDataSource<LeaveEncashmentActionModel>(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  approverLeaveEncashmentRequest(id, leaveTypeId, employeeDTOLite, leaveEncashmentCount) {
    this.leaveEncashmentActionModel.leaveRequestId = id;
    this.leaveEncashmentActionModel.leaveTypeId = leaveTypeId;
    this.leaveEncashmentActionModel.employeeId = employeeDTOLite.id;
    this.leaveEncashmentActionModel.leaveEncashmentCount = leaveEncashmentCount;
    this.leaveEncashmentActionModel.listOfUserForNotificationReference = this.leaveEncashmentActionModel.listOfUserForNotificationReference;
    var retVal = confirm("Do you want to Approve Leave Encashment?");
    if (retVal == true) {
      this.leaveEncashmentApproverRequestService.approveLeave(this.leaveEncashmentActionModel).subscribe((res) => {
        this.fetchAllPendingLeaveEncashmentRequest();
        this.messageService.display('Leave Encashment Request approved  successfully!', 'OK', 'success');
        this.loaderService.updateInboxAction(true);
      });
    }
    else {
      return false;
    }
  }

  RejectLeaveEncashmentRequest(id, leaveTypeId, employeeDTOLite) {
    var retVal = confirm("Do you want to Reject Leave Encashment?");
    if (retVal == true) {
      this.leaveEncashmentActionModel.leaveRequestId = id;
      this.leaveEncashmentActionModel.leaveTypeId = leaveTypeId;
      this.leaveEncashmentActionModel.employeeId = employeeDTOLite.id;
      this.leaveEncashmentActionModel.listOfUserForNotificationReference = this.leaveEncashmentActionModel.listOfUserForNotificationReference;
      this.leaveEncashmentApproverRequestService.rejectLeave(this.leaveEncashmentActionModel).subscribe((res) => {
        this.fetchAllPendingLeaveEncashmentRequest();
        this.messageService.display('Leave Encashment Request rejected  successfully!  ', 'OK', 'success');
        this.loaderService.updateInboxAction(true);
      });
    }
    else {
      return false;
    }
  }

}
