import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { LeaveEncashmentActionModel } from "src/app/shared/models/leave-encashment-action-model";
import { LeaveEncashmentRequestModel } from "src/app/shared/models/leave-encashment-model";
import { HttpRequestService } from "..";


@Injectable({
  providedIn: 'root'
})
export class LeaveEncashmentRequestApproverService {
  private stateUrl = 'api/v1/action/leave-encashment-request';

  constructor(
    private httpRequest: HttpRequestService
  ) { }

  fetchAllPendingLeaveEncashmentRequest(): Observable<LeaveEncashmentActionModel[]> {
    return this.httpRequest.get(this.stateUrl);
  }

  approveLeave(leaveEncashmentRequestActionModel: LeaveEncashmentActionModel): Observable<any> {
    return this.httpRequest.put(this.stateUrl + '/approve', leaveEncashmentRequestActionModel);
  }

  rejectLeave(leaveRequestActionModel: LeaveEncashmentActionModel): Observable<any> {
    return this.httpRequest.put(this.stateUrl + '/reject', leaveRequestActionModel);
  }
}