import { Injectable } from '@angular/core';
import { HttpRequestService } from '../http/http-request.service';
import { Observable } from 'rxjs';
import { HolidayModel } from '../../shared/models/holiday-model';

@Injectable({
  providedIn: 'root'
})
export class HolidayService {

  private stateUrl = 'api/v1/holiday';

  constructor(
    private httpRequest: HttpRequestService
  ) { }

  fetchHolidays(financialYearId): Observable<HolidayModel[]> {
    return this.httpRequest.get(this.stateUrl + '/' + financialYearId);
  }

  
  updateHoliday(holidayModel: HolidayModel): Observable<any> {
    return this.httpRequest.put(this.stateUrl, holidayModel);
  }

  addHoliday(holidayModel: HolidayModel): Observable<any> {
    return this.httpRequest.post(this.stateUrl, holidayModel);
  }

  fetchHoliday(financialYearId): Observable<HolidayModel> {
    return this.httpRequest.get(this.stateUrl + '/' + financialYearId);
  }

  deleteHoliday(Id:number): Observable<number> {
    return this.httpRequest.delete(this.stateUrl + '/delete-holiday/'+Id);
  }
}
