import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormArray, FormGroup, Validators } from '@angular/forms';
// import { WfhRequestActionBuilder, WfhRequestActionModel } from 'src/app/shared/models/wfh-request-action-model';
import { Location } from '@angular/common';
import { dateTimeFormatters } from 'src/app/shared/factory/common.service';
import { WfhRequestService } from 'src/app/core/services/wfh-request.service';
import { UserService } from 'src/app/core/services/user.service';
import { MessageService } from 'src/app/shared/factory/message.service';
import { LoaderService } from 'src/app/shared/factory/loader.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserModel } from 'src/app/shared/models/user-model';
import { WfhApplyRequestBuilder, WfhApplyRequestModel } from 'src/app/shared/models/wfh-apply-request-model';
import { MissingAttendance } from 'src/app/shared/models/leave-action-model';
@Component({
  selector: 'app-apply-wfh',
  templateUrl: './apply-wfh.component.html',
  styleUrls: ['./apply-wfh.component.css']
})
export class ApplyWfhComponent implements OnInit {

  wfhApplyReq: WfhApplyRequestBuilder = new WfhApplyRequestBuilder();
  wfhApplyModel: WfhApplyRequestModel = this.wfhApplyReq.WfhApplyRequestModel;
  wfhApplyRequestForm: FormGroup = this.wfhApplyReq.wfhApplyRequestForm;
  
  dateFilter = (date: Date) => date.getDay() == 1 || date.getDay() == 2 || date.getDay() == 3 || date.getDay() == 4 || date.getDay() == 5;
  dateTimeFormatters = dateTimeFormatters;

  approvers = [];
  selectedApprovers  = this.approvers[1];
  rowData: any;
  isMissingAttendanceMode = false;

  constructor(
    public location: Location,
    private route: ActivatedRoute,
    private wfhRequestService: WfhRequestService,
    private userService: UserService,
    private messageService: MessageService,
    private loaderService: LoaderService,
    public router: Router
  ) { 
    this.route.paramMap.subscribe((params) => {
      let state = this.router.getCurrentNavigation()?.extras?.state;
      if (state) {
        this.rowData = state.data;
        if (state.isMissingAttendance) {
          this.isMissingAttendanceMode = true;
        }
      }
    });
  }

  get wfhDetailFormGroup(){
    return this.wfhApplyRequestForm.get('wfhRequestDetailDTOs')as FormArray;
  }
  ngOnInit(): void {
    this.userService.fetchWfhRequestApproversLite().subscribe((res: UserModel[]) => {
      this.approvers = res;
    });

    if (this.isMissingAttendanceMode) {
      this.wfhApplyRequestForm.patchValue({
        wfhFromDate: new Date(this.rowData.actionDate),
        wfhToDate: new Date(this.rowData.actionDate),
      });
    }
  }


  dateFilterForPreviousDaySelect = (date: Date | null): boolean => {
    var wfhFromDate = this.wfhApplyReq.WfhApplyRequestModel.wfhFromDate

     if (!date) {
       return false;
     }
     const day = date.getDay();
     const isWeekdays = day === 1 || day === 2 || day ===  3 || day === 4 || day === 5 ;
     return (isWeekdays) && date >= wfhFromDate;
   };
 

  addUpdateWfhRequest(){
    
    if (this.isMissingAttendanceMode) {
      this.wfhApplyModel.missingAttendance = MissingAttendance.MISSINGATTENDANCE;
    } 

    if(this.wfhApplyModel.id){
      this.wfhRequestService.updateWfhRequest(this.wfhApplyModel).subscribe((res) =>{
        this.messageService.display('Work from home request updated sucessfull! wfh ID: '+ this.wfhApplyModel.id,'ok','success');
        this.loaderService.updateInboxAction(true);
        this.router.navigate(['/wfhRequest']);
      });
    }else{
      this.wfhRequestService.addWfhRequest(this.wfhApplyModel).subscribe((res)=>{
        this.messageService.display('Work from home request is created successfully! wfh ID: '+ res,'ok','success');
        this.loaderService.updateInboxAction(true);
        this.router.navigate(['/wfhRequest']);
      })
    }
  }
}
