import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { EmployeeService } from 'src/app/core/services/employee.service';
import { HelpService } from 'src/app/core/services/help.service';
import { ReportsService } from 'src/app/core/services/my-dashboard.service';
import { EmployeeModel } from 'src/app/shared/models/employee-model';

@Component({
  selector: 'app-my-dashboard',
  templateUrl: './my-dashboard.component.html',
  styleUrls: ['./my-dashboard.component.css']
})

export class MyDashboardComponent implements OnInit {
  public iframeUrl: SafeResourceUrl[] = [];
  @Input() tenantId: number = 0;
  @Input() leaveRequestState: string = '';

  constructor(
    private sanitizer: DomSanitizer,
    private helpService: HelpService,
    private reportsService: ReportsService,
  ) {}

  ngOnInit(): void {
    this.fetchAndSetIframeUrl(); // Call the method to fetch and set URL
  }

  fetchAndSetIframeUrl(): void {
    this.reportsService.fetchMetadata().subscribe(
      response => {
        const tokens = JSON.parse(JSON.stringify(response)); // Assume response is a JSON array of tokens
        console.log('Fetched Tokens:', tokens);

        tokens.forEach(element => {
          this.iframeUrl.push(this.sanitizer.bypassSecurityTrustResourceUrl(element))
        });

        console.log('Metabase iframe URLs:', this.iframeUrl);
      },
      error => {
        console.error('Error fetching metadata:', error);
      }
    );
  }

  findHelpUrlPage(): void {
    const currentPage = "/#_4_4_employee_leave_state";
    window.open(`${this.helpService.HELP_BASE_URL}${currentPage}`, "_blank");
  }
}
function findHelpUrlPage() {
  throw new Error('Function not implemented.');
}
