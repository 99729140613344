import { NumberInput } from "@angular/cdk/coercion";
import { Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { LeaveEncashmentRequestModel } from "./leave-encashment-model";

export interface LeaveEncashmentActionModel {
    id: number,
    leaveTypeId: number,
    leaveRequestId: number,
    leaveEncashmentRequestApprovers: any,
    leaveEncashmentRequestState: string,
    leaveEncashmentCount: number,
    leaveTypeName: String,
    employeeId: number
    listOfUserForNotificationReference: any;

}

export class LeaveEncashmentActionBuilder {
    leaveEncashmentActionModel: LeaveEncashmentActionModel = {
        id: 0,
        leaveTypeId: 0,
        leaveEncashmentCount: 0,
        leaveEncashmentRequestState: "",
        leaveEncashmentRequestApprovers: [],
        leaveTypeName: "",
        employeeId: 0,
        listOfUserForNotificationReference: [],
        leaveRequestId: 0
    };

    leaveEncashmentActionForm: FormGroup = new FormGroup({
        leaveTypeId: new FormControl(this.leaveEncashmentActionModel.leaveTypeId, [Validators.required]),
        leaveTypeName: new FormControl(this.leaveEncashmentActionModel.leaveTypeName),
        leaveRequestId:new FormControl(this.leaveEncashmentActionModel.leaveRequestId),
        leaveEncashmentRequestState: new FormControl(this.leaveEncashmentActionModel.leaveEncashmentRequestState, [Validators.required]),
        leaveEncashmentRequestApprovers: new FormControl(this.leaveEncashmentActionModel.leaveEncashmentRequestApprovers, [Validators.required]),
        totalLeaveEncashmentCount: new FormControl(this.leaveEncashmentActionModel.leaveEncashmentCount, [Validators.required]),
        employeeId: new FormControl(this.leaveEncashmentActionModel.employeeId),
        listOfUserForNotificationReference: new FormControl(this.leaveEncashmentActionModel.listOfUserForNotificationReference)

    });

    constructor() {
        this.leaveEncashmentActionForm.valueChanges.subscribe(val => {
            this.leaveEncashmentActionModel.employeeId = val.employeeId;
            this.leaveEncashmentActionModel.leaveEncashmentRequestApprovers = val.leaveEncashmentRequestApprovers;
            this.leaveEncashmentActionModel.leaveRequestId= val.leaveRequestId;
            this.leaveEncashmentActionModel.leaveEncashmentRequestState = val.leaveEncashmentRequestState;
            this.leaveEncashmentActionModel.leaveEncashmentCount = val.totalLeaveCount;
            this.leaveEncashmentActionModel.listOfUserForNotificationReference = val.listOfUserForNotificationReference;
        });
    }
    setModelVals(res: LeaveEncashmentActionModel) {
        this.leaveEncashmentActionForm.controls.employeeId.setValue(res.employeeId);
        this.leaveEncashmentActionForm.controls.listOfUserForNotificationReference.setValue(res.listOfUserForNotificationReference);
    }

}
