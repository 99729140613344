import { FormControl, FormGroup } from "@angular/forms";
import { MissingAttendance } from "./leave-action-model";

export interface AttendanceApplyRequestModel {
    id: number,
    reasonForAttendanceRequest: string,
    attendanceType: string,
    attendanceRequestApprovers: any,
    attendanceFromDate: Date,
    entryTime: Date,
    exitTime: Date,
    approverActionComment: string
    missingAttendance: string;
}


export class AttendanceApplyRequestBuilder {
    AttendanceApplyRequestModel: AttendanceApplyRequestModel = {
        id: null,
        reasonForAttendanceRequest: '',
        attendanceType: '',
        attendanceRequestApprovers: [],
        attendanceFromDate: null,
        entryTime: null,
        exitTime: null,
        approverActionComment: '',
        missingAttendance: MissingAttendance.MISSINGATTENDANCE
    };

    attendanceApplyRequestForm: FormGroup = new FormGroup({
        id: new FormControl(this.AttendanceApplyRequestModel.id),
        reasonForAttendanceRequest: new FormControl(this.AttendanceApplyRequestModel.reasonForAttendanceRequest),
        attendanceType: new FormControl(this.AttendanceApplyRequestModel.attendanceType),
        attendanceRequestApprovers: new FormControl(this.AttendanceApplyRequestModel.attendanceRequestApprovers),
        attendanceFromDate: new FormControl(this.AttendanceApplyRequestModel.attendanceFromDate),
        entryTime: new FormControl(this.AttendanceApplyRequestModel.entryTime),
        exitTime: new FormControl(this.AttendanceApplyRequestModel.exitTime),
        approverActionComment: new FormControl(this.AttendanceApplyRequestModel.approverActionComment)
    })

    constructor() {
        this.attendanceApplyRequestForm.valueChanges.subscribe(val => {
            this.AttendanceApplyRequestModel.id = val.id;
            this.AttendanceApplyRequestModel.reasonForAttendanceRequest = val.reasonForAttendanceRequest;
            this.AttendanceApplyRequestModel.attendanceType = val.attendanceType;
            this.AttendanceApplyRequestModel.attendanceRequestApprovers = val.attendanceRequestApprovers;
            this.AttendanceApplyRequestModel.attendanceFromDate = val.attendanceFromDate;
            this.AttendanceApplyRequestModel.entryTime = val.entryTime;
            this.AttendanceApplyRequestModel.exitTime = val.exitTime;
            this.AttendanceApplyRequestModel.approverActionComment = val.approverActionComment;
            this.AttendanceApplyRequestModel.missingAttendance = val.missingAttendance;

        });
    }
    setModelVals(res: AttendanceApplyRequestModel) {
        this.attendanceApplyRequestForm.controls.id.setValue(res.id);
        this.attendanceApplyRequestForm.controls.reasonForAttendanceRequest.setValue(res.reasonForAttendanceRequest);
        this.attendanceApplyRequestForm.controls.attendanceType.setValue(res.attendanceType);
        this.attendanceApplyRequestForm.controls.attendanceRequestApprovers.setValue(res.attendanceRequestApprovers);
        this.attendanceApplyRequestForm.controls.approverActionComment.setValue(res.approverActionComment);
        this.attendanceApplyRequestForm.controls.attendanceFromDate.setValue(res.attendanceFromDate.toLocaleDateString());
        this.attendanceApplyRequestForm.controls.entryTime.setValue(res.entryTime.toLocaleTimeString());
        this.attendanceApplyRequestForm.controls.exitTime.setValue(res.exitTime.toLocaleTimeString());
    }
}