
import { Injectable } from '@angular/core';
import { HttpRequestService } from '../http/http-request.service';
import { Observable } from 'rxjs';
import { LeaveRequestModel } from '../../shared/models/leave-request-model';
import { LeaveActionModel } from '../../shared/models/leave-action-model';
import { EmployeeLeaveStatModel } from 'src/app/shared/models/employee-leave-stat-model';
import { LeaveRequestActionModel } from '../../shared/models/leave-request-action-model';

@Injectable({
  providedIn: 'root'
})
export class LeaveRequestService {
  rejectLeave(leaveRequestActionModel: LeaveRequestActionModel) {
      throw new Error('Method not implemented.');
  }
  approveLeave(leaveRequestActionModel: LeaveRequestActionModel) {
      throw new Error('Method not implemented.');
  }
  private stateUrl = 'api/v1/leave-request';

  constructor(
    private httpRequest: HttpRequestService
  ) { }


  fetchLeaveRequest(): Observable<LeaveRequestModel[]> {
    return this.httpRequest.get(this.stateUrl + '/');
  }

  
  updateLeaveRequest(leaveActionModel: LeaveActionModel): Observable<any> {
    return this.httpRequest.put(this.stateUrl, leaveActionModel);
  }

  addLeaveRequest(leaveActionModel: LeaveActionModel): Observable<any> {
    return this.httpRequest.post(this.stateUrl, leaveActionModel);
  }

  fetchLeaveByIdWithDetail(leaveRequestId): Observable<LeaveRequestModel> {
    return this.httpRequest.get(this.stateUrl + '/' + leaveRequestId);
  }

  CancelLeave(data): Observable<any> {
    return this.httpRequest.put(this.stateUrl + '/cancel' , data);
  }
 
  fetchLeaveBalance(): Observable<EmployeeLeaveStatModel[]> {
    return this.httpRequest.get(this.stateUrl + '/leave-stats');
  }

  fetchAllEmployeeLeaveBalance():  Observable<EmployeeLeaveStatModel[]> {
    return this.httpRequest.get(this.stateUrl + '/employee-leave-stats');
  }
  
  fetchAllLeaveRequests(currentMonthStartDate, currentMonthLastDate): Observable<any> {
    return this.httpRequest.post('api/v1/leave-request/leave/date/filter', { "startDate": currentMonthStartDate, "endDate": currentMonthLastDate });
  }

  cancelApprovedLeave(data): Observable<any> {
    return this.httpRequest.post(this.stateUrl + '/approved-leave-cancel-request' , data, null);
  }

  approvedLeaveCancelRequestAction(data): Observable<any> {
    return this.httpRequest.post(this.stateUrl + '/approved-leave-cancel-request-action', data);
  }

}