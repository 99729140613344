import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { HolidaysComponent } from './components/holidays/holidays.component';
import { HolidayAddEditComponent } from './components/holiday-add-edit/holiday-add-edit.component';
import { FinancialConfigYearComponent } from './components/financial-config-year/financial-config-year.component';
import { LeaveTypeComponent } from './components/leave-type/leave-type.component';
import { LeaveTypeAddEditComponent } from './components/leave-type-add-edit/leave-type-add-edit.component';
import { LeaveRequestComponent } from './components/leave-request/leave-request.component';
import { ApplyLeaveComponent } from './components/apply-leave/apply-leave.component';
import { LeaveActionComponent } from './components/leave-action/leave-action.component';
import { EmployeeLeaveStateComponent } from './components/employee-leave-state/employee-leave-state.component';
import { CarryForwardComponent } from './components/carry-forward/carry-forward.component';
import { CarryForwardDetailComponent } from './components/carry-forward-detail/carry-forward-detail.component';
import { HistoricalLeaveRequestComponent } from './components/historical-leave-request/historical-leave-request.component';
import { SSOGatewayComponent } from './components/sso-gateway/sso-gateway.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { AuthGuard } from './core/guards/auth.guard';
import { ActivitiesComponent } from './components/activities/activities.component';
import { LinkCalendarComponent } from './components/link-calendar/link-calendar.component';
import { LinkCalendarAddEditComponent } from './components/link-calendar-add-edit/link-calendar-add-edit.component';
import { LeaveEncashmentRequestComponent } from './components/leave-encashment-request/leave-encashment-request/leave-encashment-request.component';
import { LeaveEncashmentActionComponent } from './components/leave-encashment-action/leave-encashment-action/leave-encashment-action.component';
import { ApplyEncashmentLeaveComponent } from './components/apply-encashment-leave/apply-encashment-leave/apply-encashment-leave.component';
import { WfhRequestComponent } from './components/wfh-request/wfh-request.component';
import { ApplyWfhComponent } from './components/apply-wfh/apply-wfh.component';
import { WfhActionComponent } from './components/wfh-action/wfh-action.component'
import { ApplyAttendanceRegularisationRequestComponent } from './components/apply-attendance-regularisation/apply-attendance-regularisation-request.component';
import { MyAttendanceComponent } from './components/my-attendance/my-attendance/my-attendance.component';
import { AttendanceRegularisationActionComponent } from './components/attendance-regularisation-action/attendance-regularisation-action.component';
import { MissingAttendanceActionComponent } from './components/missing-attendance-action/missing-attendance-action.component';
import { LeaveAdjustComponent } from './components/leave-adjust/leave-adjust.component';
import { MyDashboardComponent } from './components/my-dashboard/my-dashboard.component';

const routes: Routes = [
  { path: '', redirectTo: '/', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'sso-gateway', component: SSOGatewayComponent },
  {
    path: '', component: HomeComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard], children: [
      { path: '', component: DashboardComponent },
      { path: 'holidays', component: HolidaysComponent },
      //{ path: 'holidays/new', component: HolidayAddEditComponent },
      { path: 'holidays/:id', component: HolidayAddEditComponent },
      { path: 'add_holiday', component: HolidayAddEditComponent },
      { path: 'financialYear', component: FinancialConfigYearComponent },
      { path: 'leaveType', component: LeaveTypeComponent },
      { path: 'leaveTypeAdd', component: LeaveTypeAddEditComponent },
      { path: 'leaveRequest', component: LeaveRequestComponent },
      { path: 'applyLeave', component: ApplyLeaveComponent },
      { path: 'leaveActionRequest', component: LeaveActionComponent },
      { path: 'myAttendance', component: MyAttendanceComponent },
      { path: 'employee_leave_state', component: EmployeeLeaveStateComponent },
      { path: 'carryForward', component: CarryForwardComponent },
      { path: 'carryForwardDetail', component: CarryForwardDetailComponent },
      { path: 'historical_leave_request', component: HistoricalLeaveRequestComponent },
      { path: 'activities', component: ActivitiesComponent },
      { path: 'link-calendar', component: LinkCalendarComponent },
      { path: 'link-calendar/new', component: LinkCalendarAddEditComponent },
      { path: 'leaveEncashmentRequest', component: LeaveEncashmentRequestComponent },
      { path: 'leaveEncashmentActionRequest', component: LeaveEncashmentActionComponent },
      { path: 'applyEncashmentLeave', component: ApplyEncashmentLeaveComponent },
      { path: 'wfhRequest', component: WfhRequestComponent },
      { path: 'applyWfh', component: ApplyWfhComponent },
      { path: 'wfhAction', component: WfhActionComponent },
      { path: 'attendanceAction', component: AttendanceRegularisationActionComponent },
      { path: 'applyAttendanceRegularisation/:new', component: ApplyAttendanceRegularisationRequestComponent },
      { path: 'applyAttendanceRegularisation/:id', component: ApplyAttendanceRegularisationRequestComponent },
      { path: 'missingAttendanceAction', component: MissingAttendanceActionComponent },
      { path: 'leaveRequest/missingAttendance', component: ApplyLeaveComponent },
      { path: 'applyWfh/missingAttendance', component: ApplyWfhComponent },
      { path: 'applyAttendanceRegularisation/missingAttendance', component: ApplyAttendanceRegularisationRequestComponent },
      { path: 'leaveAdjust', component: LeaveAdjustComponent},
      {path:'myDashboard',component:MyDashboardComponent}
    ]
  },
  { path: '**', component: PageNotFoundComponent, canActivate: [AuthGuard] } // need to work on design
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes
    // { enableTracing: true } // <-- debugging purposes only
  )],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
