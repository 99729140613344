import { FormControl, Validators } from '@angular/forms';
export interface HolidayModel {
    id: number,
    name: string,
    date: any,
    financialYearId: number
}
export class HolidayFormBuilder {
   // id = new FormControl();
    name = new FormControl('');
    date = new FormControl('');
    financialYearId = new FormControl('');
}