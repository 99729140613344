import { FormGroup, FormControl, Validator, Validators, FormArray } from '@angular/forms';


export interface AttendanceRequestModel {
    id: number,
    reasonForAttendanceRequest: string,
    isDisabled: boolean,
    attendanceFromDate: Date,
    entryTime: Date,
    exitTime: Date,
    employeeDTOLite: {
        id: number,
        firstName: string,
        lastName: string,
        fullName: String,
    }
    attendanceRequestStatus: string,
    attendanceSummaryId: number,
    attendanceRequestApprovers: Array<any>,
    totalAttendanceCount: number,
    attendanceType: string,
    approverActionComment: string,
    listOfUserForNotificationReference: Array<any>,
    attendanceCancelReason: string,
    attendanceRequestedDate: Date,
}

export class AttendanceRequestBuilder {
    attendanceRequestModel: AttendanceRequestModel = {
        id: null,
        reasonForAttendanceRequest: '',
        isDisabled: false,
        attendanceFromDate: null,
        entryTime: null,
        exitTime: null,
        attendanceType: '',
        employeeDTOLite: {
            id: null,
            firstName: '',
            lastName: '',
            fullName: ''
        },
        attendanceRequestStatus: '',
        attendanceRequestApprovers: [],
        totalAttendanceCount: null,
        approverActionComment: '',
        listOfUserForNotificationReference: [],
        attendanceCancelReason: '',
        attendanceRequestedDate: null,
        attendanceSummaryId: null
    };

    attendanceRequestForm: FormGroup = new FormGroup({
        reasonForAttendanceRequest: new FormControl(this.attendanceRequestModel.reasonForAttendanceRequest, [Validators.required]),
        employeeDTOLite: new FormGroup({
            id: new FormControl(this.attendanceRequestModel.employeeDTOLite.id),
            firstName: new FormControl(this.attendanceRequestModel.employeeDTOLite.firstName),
            lastName: new FormControl(this.attendanceRequestModel.employeeDTOLite.lastName),
            fullName: new FormControl(this.attendanceRequestModel.employeeDTOLite.firstName + this.attendanceRequestModel.employeeDTOLite.lastName),
        }),
        attendanceType: new FormControl(this.attendanceRequestModel.attendanceType),
        attendanceSummaryId: new FormControl(this.attendanceRequestModel.attendanceSummaryId),
        attendanceRequestStatus: new FormControl(this.attendanceRequestModel.attendanceRequestStatus, [Validators.required]),
        attendanceRequestApprovers: new FormControl(this.attendanceRequestModel.attendanceRequestApprovers, [Validators.required]),
        totalAttendanceCount: new FormControl(this.attendanceRequestModel.totalAttendanceCount),
        attendanceFromDate: new FormControl(this.attendanceRequestModel.attendanceFromDate, [Validators.required]),
        entryTime: new FormControl(this.attendanceRequestModel.entryTime),
        exitTime: new FormControl(this.attendanceRequestModel.exitTime),
        approverActionComment: new FormControl(this.attendanceRequestModel.approverActionComment, [Validators.required]),
        listOfUserForNotificationReference: new FormControl(this.attendanceRequestModel.listOfUserForNotificationReference, [Validators.required]),
        attendanceCancelReason: new FormControl(this.attendanceRequestModel.attendanceCancelReason),
        attendanceRequestedDate: new FormControl(this.attendanceRequestModel.attendanceRequestedDate)
    });

    constructor() {
        this.attendanceRequestForm.valueChanges.subscribe(val => {

            this.attendanceRequestModel.reasonForAttendanceRequest = val.reasonForAttendance;
            this.attendanceRequestModel.employeeDTOLite = val.employeeDTOLite;
            this.attendanceRequestModel.attendanceRequestStatus = val.attendanceRequestStatus;
            this.attendanceRequestModel.attendanceRequestApprovers = val.attendanceRequestApprovers;
            this.attendanceRequestModel.totalAttendanceCount = val.totalAttendanceCount;
            this.attendanceRequestModel.attendanceSummaryId = val.attendanceSummaryId;
            this.attendanceRequestModel.approverActionComment = val.approverActionComment;
            this.attendanceRequestModel.listOfUserForNotificationReference = val.listOfUserForNotificationReference;
            this.attendanceRequestModel.attendanceFromDate = val.attendanceFromDate;
            this.attendanceRequestModel.entryTime = val.entryTime;
            this.attendanceRequestModel.exitTime = val.exitTime;
            this.attendanceRequestModel.attendanceType = val.attendanceType;
            this.attendanceRequestModel.attendanceCancelReason = val.attendanceCancelReason;
            this.attendanceRequestModel.attendanceRequestedDate = val.attendanceRequestedDate;
        });
    }

    setModelVals(res: AttendanceRequestModel) {
        this.attendanceRequestForm.controls.reasonForAttendanceRequest.setValue(res.reasonForAttendanceRequest);
        this.attendanceRequestForm.controls.attendanceRequestStatus.setValue(res.attendanceRequestStatus);
        this.attendanceRequestForm.controls.attendanceRequestApprovers.setValue(res.attendanceRequestApprovers);
        this.attendanceRequestForm.controls.totalAttendanceCount.setValue(res.totalAttendanceCount);
        this.attendanceRequestForm.controls.attendanceSummaryId.setValue(res.attendanceSummaryId);
        this.attendanceRequestForm.controls.approverActionComment.setValue(res.approverActionComment);
        this.attendanceRequestForm.controls.attendanceFromDate.setValue(res.attendanceFromDate);
        this.attendanceRequestForm.controls.attendanceType.setValue(res.attendanceType);
        this.attendanceRequestForm.controls.attendanceCancelReason.setValue(res.attendanceCancelReason);
        this.attendanceRequestForm.controls.attendanceRequestedDate.setValue(res.attendanceRequestedDate);
        this.attendanceRequestForm.controls.entryTime.setValue(res.entryTime);
        this.attendanceRequestForm.controls.exitTime.setValue(res.exitTime);
        let employeeDetailFormGroup = this.attendanceRequestForm.get('employeeDTOLite') as FormGroup;
        
        employeeDetailFormGroup.controls.id.setValue(res.employeeDTOLite.id);
        employeeDetailFormGroup.controls.firstName.setValue(res.employeeDTOLite.firstName);
        employeeDetailFormGroup.controls.lastName.setValue(res.employeeDTOLite.lastName);
        employeeDetailFormGroup.controls.fullName.setValue(res.employeeDTOLite.firstName + res.employeeDTOLite.lastName);

    }
}