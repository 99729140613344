import { Injectable } from '@angular/core';
import { HttpRequestService } from '../http/http-request.service';
import { Observable } from 'rxjs';
import { MissingAttendanceActionModel } from 'src/app/shared/models/missing-attendance-action-model';

@Injectable({
  providedIn: 'root'
})
export class MissingAttendanceService {

  private stateUrl = 'api/v1';


  constructor(private httpRequest: HttpRequestService) { }


  fetchMissingAttendance(): Observable<MissingAttendanceActionModel[]> {
    return this.httpRequest.get(this.stateUrl + '/missing-attendance')
  }

}
