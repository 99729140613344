import { Component, OnInit, ViewChild } from '@angular/core';
import { LeaveTypeService } from '../../core/services/leave-type.service';
import { FormControl } from '@angular/forms';
import { LeaveTypeModel } from '../../shared/models/leave-type-model';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { HelpService } from '../../core/services/help.service';

@Component({
  selector: 'app-leave-type',
  templateUrl: './leave-type.component.html',
  styleUrls: ['./leave-type.component.css']
})
export class LeaveTypeComponent implements OnInit {

  displayedColumns = ['id', 'name', 'code', 'accural', 'leave-association', 'category'];
  dataSource: MatTableDataSource<LeaveTypeModel>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private leaveTypeService: LeaveTypeService,
    private helpService: HelpService
  ) { }

  ngOnInit() {
    this.fetchLeaveType();
    this.dataSource = new MatTableDataSource<LeaveTypeModel>([]);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  fetchLeaveType() {
    this.leaveTypeService.fetchLeaveType().subscribe((res: LeaveTypeModel[]) => {
      this.dataSource = new MatTableDataSource<LeaveTypeModel>(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  findHelpUrlPage() {
    const currentPage = "/#_4_3_manage_leave_type";
    window.open(this.helpService.HELP_BASE_URL + currentPage, "_blank");

  }
}
