import { Component, OnInit, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';
import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours } from 'date-fns';
import { dateTimeFormatters } from '../../shared/factory/common.service';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent } from 'angular-calendar';
import { EmployeeService } from '../../core/services/employee.service';
import { CandidateService } from '../../core/services/candidate.service';
//import { DashboardHelpDialogComponent } from '../../shared/popups/popups';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { HolidayService } from 'src/app/core/services/holiday.service';
import { HolidayModel, HolidayFormBuilder } from 'src/app/shared/models/holiday-model';
import { EmployeeLeaveStatModel, EmployeeLeaveStatFormBuilder } from 'src/app/shared/models/employee-leave-stat-model';
import { LeaveRequestService } from '../../core/services/leave-request.service';
import { FinancialYearConfigFormBuilder, FinancialYearConfigModel } from 'src/app/shared/models/financial-year-config-model';
import { OrganizationModel } from 'src/app/shared/models/organization-model';
import { OrganizationService } from 'src/app/core/services/organization.service';
import { HelpService } from '../../core/services/help.service';
import { HistoricalLeaveComponent } from 'src/app/shared/popups/popups';
import { LoaderService } from 'src/app/shared/factory/loader.service';
import { Chart} from 'chart.js';
import { LeaveRequestApproverService } from 'src/app/core/services/leave-request-approver.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { WfhRequestApproverService } from 'src/app/core/services/wfh-request-approver.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  holidayForm: HolidayFormBuilder = new HolidayFormBuilder();
  holidayModel: HolidayModel = {
    id: null,
    name: '',
    date: null,
    financialYearId: null
  };
  financialYearConfig: FinancialYearConfigFormBuilder = new FinancialYearConfigFormBuilder();
  financialYearConfigModel: FinancialYearConfigModel = this.financialYearConfig.financialYearConfigModel;
  leaveBalance = [];
  leaveTotal: number = 0;
  displayedColumns = ['id', 'name', 'date'];
  dataSource: MatTableDataSource<HolidayModel>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('modalContent') modalContent: TemplateRef<any>;

  view: string = 'month';

  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  refresh: Subject<any> = new Subject();

  events: CalendarEvent[] = [];

  activeDayIsOpen: boolean = false;
  //for Leave Graph
  canvas: any;
  ctx: any;
  title = 'dashboard';
  @ViewChild('leavechart') leavechart:any;
  @ViewChild('wfhchart') wfhchart:any;
  paidLeave: any = [];
  unpaidLeave: any = [];
  monthVal: any= [];
  wfhRequest: any = [];
  wfhMonthVal: any= [];
  private LEAVE_APPROVER_ROLE:string = "ROLE_LEAVE_REQUEST_APPROVER";
  constructor(
    private eleRef: ElementRef,
    private holidayService: HolidayService,
    private candidateService: CandidateService,
    private helpService: HelpService,
    private leaveRequestService: LeaveRequestService,
    private organizationService: OrganizationService,
    private employeeService: EmployeeService,
    public dialog: MatDialog,
    private loaderService: LoaderService,
    private leaveRequestApproverService: LeaveRequestApproverService,
    private permissionsService: NgxPermissionsService,
    private wfhRequestApproverService: WfhRequestApproverService
  ) { }

  ngOnInit() {
    //var currentFinancialYearInstanceId = null;
    this.organizationService.fetchOrganization().subscribe((res: OrganizationModel[]) => {
      this.holidayModel.financialYearId = res[0].currentFinancialYearInstanceId;
      this.fetchHolidays();
      this.dataSource = new MatTableDataSource<HolidayModel>([]);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.fetchLeaveBalance();
      this.fetchAllLeaveRequest();
      this.fetchChartData();
    });

  }

  fetchChartData(){
    this.leaveReport();
    this.wfhReport();
  }

  leaveReport(){
    this.permissionsService.hasPermission(this.LEAVE_APPROVER_ROLE).then((permission) => {
      if(permission){
        this.leaveRequestApproverService.fetchChartData().subscribe(res => {
          res?.chart?.map(result =>{
            this.monthVal.push(result?.month);
            this.paidLeave.push(result?.paidLeaves);
            this.unpaidLeave.push(result?.unpaidLeaves);
          })
          this.createLeaveChart();
        });
      }
    });
  }
  createLeaveChart(){
    this.canvas = this.leavechart.nativeElement;
    this.ctx = this.canvas.getContext('2d');
    
    new Chart(this.ctx, {
      type: 'bar',
      data: {
        datasets: [
          {
            type: 'bar',
            label: 'Monthly Paid leave',
            // data: [15.5, 75.0, 18.1, 8.2, 14.0, 15.4, 74.1, 53.3, 56.1, 85.12, 78.10 ,10.0],
            data: this.paidLeave,
            backgroundColor:'rgba(119, 221, 119, 0.7)',
            borderColor: "#77dd76",
            borderWidth: 1
          },
          {
            type: 'bar',
            label: 'Monthly Unpaid leave',
            // data: [ 5, 17, 3, 1,10],
            data: this.unpaidLeave,
            backgroundColor:'rgba(255, 105, 108, 0.7)',
            borderColor: "#FF6962",
            borderWidth: 1
          }
        ],
        // labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun','Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        labels: this.monthVal,
      },
      options: {
        responsive: true,
      }
    });
  }

  wfhReport(){
    this.permissionsService.hasPermission(this.LEAVE_APPROVER_ROLE).then((permission) => {
      if(permission){
        this.wfhRequestApproverService.fetchChartData().subscribe(res => {
          res?.chart?.map(result =>{
            this.wfhMonthVal.push(result?.month);
            this.wfhRequest.push(result?.wfhRequest);
          })
          this.createWFHChart();
        });
      }
    });
  }

  createWFHChart(){
    this.canvas = this.wfhchart.nativeElement;
    this.ctx = this.canvas.getContext('2d');
    
    new Chart(this.ctx, {
      type: 'bar',
      data: {
        datasets: [
          {
            type: 'bar',
            label: 'Monthly Work from home Requests',
            data: this.wfhRequest,
            backgroundColor:'rgba(119, 221, 119, 0.7)',
            borderColor: "#77dd76",
            borderWidth: 1
          }
        ],
        labels: this.wfhMonthVal,
      },
      options: {
        responsive: true,
      }
    });
  }
  fetchHolidays() {
    this.holidayService.fetchHolidays(this.holidayModel.financialYearId).subscribe((res: HolidayModel[]) => {
      this.dataSource = new MatTableDataSource<HolidayModel>(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  fetchLeaveBalance() {
    this.leaveRequestService.fetchLeaveBalance().subscribe((res: EmployeeLeaveStatModel[]) => {
      this.leaveBalance = res;
      var total = 0;
      this.leaveBalance.forEach(function (value) {
        total += value.leaveCount;
      })
      this.leaveTotal = total;

    });
  }


  fetchAllLeaveRequest(currentMonthDate?) {
    var dt = currentMonthDate ? new Date(currentMonthDate) : new Date();
    var month = dt.getMonth(), year = dt.getFullYear();
    var FirstDay = new Date(year, month, 1);
    var LastDay = new Date(year, month + 1, 0);
    this.leaveRequestService.fetchAllLeaveRequests(dateTimeFormatters.formatDate(FirstDay), dateTimeFormatters.formatDate(LastDay)).subscribe(res => {
      this.events = [];
      for (var i = 0; i < res.length; i++) {
        for(var j=0; j<res[i].leaveRequestDetailDTOs.length; j++){
        this.addEvent(res[i].id,res[i].leaveRequestDetailDTOs[j], res[i].leaveRequestState , res[i].employeeDTOLite.firstName , res[i].employeeDTOLite.lastName, res[i].leaveCategory ); // require round id also for each event, to fetch or update particular event.
      }
    }
    });
  }

  onSelect(event) {
  }


  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
        this.viewDate = date;
      }
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd
  }: CalendarEventTimesChangedEvent): void {
    event.start = newStart;
    event.end = newEnd;
    this.handleEvent('Dropped or resized', event);
    this.refresh.next();
  }

  handleEvent(action: string, event: CalendarEvent): void {
   this.openLeaveActionDialog(event.id)
  }


  openLeaveActionDialog(leaveRequestId) {
    const dialogRef = this.dialog.open(HistoricalLeaveComponent, {
      data: { leaveRequestId }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'applied') {
        this.loaderService.updateInboxAction(true);
      };
    });
  }

  colors: any = {
    PAID: {
      primary: '#AFB83B',
      secondary: '#D1E8FF'
    },
    APPROVED: {
      primary: '#0d6efd',
      secondary: '#D1E8FF'
    },
    PENDING_APPROVAL: {
      primary: '#ad2121',
      secondary: '#FAE3E3'
    },
    UNPAID: {
      primary: '#1e90ff',
      secondary: '#D1E8FF'
    },
  };

 
  addEvent(leaveRequestId,eventDetails, leaveRequestState, firstName, lastName, leaveCategory): void {
    this.events.push({
      id: leaveRequestId,
      // color: leaveCategory && leaveCategory == 'UNPAID' && leaveRequestState == 'PENDING_APPROVAL'  &&  leaveCategory && leaveCategory == 'PAID' && leaveRequestState == 'APPROVED' ? this.colors['PAID']: this.colors[leaveRequestState],
      color:  leaveCategory && leaveCategory == 'PAID' && leaveRequestState == 'APPROVED' ? this.colors['PAID'] : this.colors[leaveRequestState],
      title: firstName + ' ' + lastName + ' ' +  eventDetails.leaveDate + ' ( ' + eventDetails.leaveRequestType + ' )' + ' ' + leaveRequestState + ' ',
      start: addHours(new Date(eventDetails.leaveDate), 0),
      end: addHours(new Date(eventDetails.leaveDate), 1),
      draggable: false,
      resizable: {
        beforeStart: false,
        afterEnd: false
      }
    });
    this.refresh.next();
  }

  handleHourSegament(event: CalendarEvent) {}

  monthNextPrevChange(event) {
    this.activeDayIsOpen = false;
    this.fetchAllLeaveRequest(event);
  }

  findHelpUrlPage() {
    const currentPage = "/#_2_1_dashboard";
    window.open(this.helpService.HELP_BASE_URL + currentPage, "_blank");

  }

}