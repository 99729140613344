import { EmployeeService } from './../../core/services/employee.service';
import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, Validators, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { dateTimeFormatters, base64toBlob, CommonService } from '../factory/common.service';
import { MessageService } from '../../shared/factory/message.service';
import { ElementRef } from '@angular/core';
import { LeaveRequestBuilder, LeaveRequestModel } from '../models/leave-request-model';
import { CancelApprovedLeaveRequestActionModel, CancelApprovedLeaveRequestActionBuilder, CancelLeaveRequestBuilder, CancelLeaveRequestModel } from '../models/leave-request-action-model';
import { LeaveRequestDetailModel } from '../models/leave-request-detail-dtos-model';
import { LeaveRequestService } from '../../core/services/leave-request.service';
import { UserService } from 'src/app/core/services/user.service';
import { Router } from '@angular/router';
import { LeaveRequestApproverService } from 'src/app/core/services/leave-request-approver.service';
import { LeaveRequestActionModel, LeaveRequestActionBuilder } from '../models/leave-request-action-model';
import { FinancialYearConfigFormBuilder, FinancialYearConfigModel } from '../models/financial-year-config-model';
import { FinancialYearConfigService } from 'src/app/core/services/financial-year-config-service.service';
import { HelpService } from 'src/app/core/services/help.service';
import { LoaderService } from '../factory/loader.service';
import { UserCalendarService } from 'src/app/core/services/user-calendar.service';
import { AttendanceService } from 'src/app/core/services/attendance.service';
import { formatDate } from '@angular/common';
import { AttendanceEntryLogs } from '../models/attendance-entry-log-model';
import { LeaveAdjustActionBuilder, LeaveAdjustFormBuilder, LeaveAdjustModel } from '../models/leave-adjust-model';
import { LeaveAdjustService } from 'src/app/core/services/leave-adjust.service';

//LeaveAction  Dialog
@Component({
  selector: 'app-leave-action-dialog',
  template: `<div [formGroup]="leaveActionForm"><h1 mat-dialog-title align="center"> Leave Action </h1>
  <div mat-dialog-content>
 
  <div class="col-sm" formGroupName="employeeDTOLite">
  <div class="row">
  <div class="col-sm">
          <mat-label>Employee Name</mat-label>
          </div>
          <div class="col-sm">
        
          <input matInput readonly formControlName="fullName">
          </div>
     </div>
  </div>

  <hr/>
  <div class="col-sm">
  <div class="row">
      <div class="col-sm">
              <mat-label>Leave Type Name</mat-label>
              </div>
              <div class="col-sm">
              <input matInput readonly formControlName="leaveTypeName">
         </div>
      </div>
 </div>
 <hr/>
      <div class="col-sm">
      <div class="row">
      <div class="col-sm">
              <mat-label>Total Leave</mat-label>
              </div>
              <div class="col-sm">    
              <input matInput readonly formControlName="totalLeaveCount">
         </div>
         </div>
      </div>

      <hr/>


      <div class="col-sm">
      <div class="row">
      <div class="col-sm">
              <mat-label>Reason</mat-label>
              </div>
              <div class="col-sm">  
              <mat-label>{{leaveActionForm.controls.reasonForLeave.value}}</mat-label>
             
              </div>
              </div>
      </div>
      <hr/>
      <div class="col-sm">
      <div class="row">
      <div class="col-sm">
              <mat-label>Leave Requested Date</mat-label>
              </div>
              <div class="col-sm">  
              <mat-label>{{leaveRequestedDate}}</mat-label>
              </div>
              </div>
      </div>
      <hr/>
      <div class="col-sm">
  <div formArrayName="leaveRequestDetailDTOs">
      <div 
          *ngFor="let leaveRequestDetailDTO of leaveActionForm.get('leaveRequestDetailDTOs').controls; let i = index;">
          <div class="row">

              <div class="col-sm">
                  <input type="text" readonly class="form-control-plaintext"
                      value="{{leaveActionForm.controls.leaveRequestDetailDTOs.controls[i].controls.leaveDate.value}}">
              </div>

              <div class="col-sm">
                  <input type="text" readonly class="form-control-plaintext"
                      value="{{leaveActionForm.controls.leaveRequestDetailDTOs.controls[i].controls.leaveRequestType.value}}">
              </div>
          </div>
      </div>
  </div>
</div>

<div class="col-sm mt-3">
<p>
  <mat-form-field class="w-100" appearance="outline">
    <mat-label>Select Users</mat-label>
    <mat-select formControlName="listOfUserForNotificationReference" multiple>
       <mat-option *ngFor="let user of userList" [value]="{id:user.id, emailOrUsername:user.emailOrUsername, name:user.name}">{{user.name}}</mat-option>
    </mat-select>
    <mat-hint>Select users to whom you want to share email notification in case of leave request approved</mat-hint>
 </mat-form-field>
</p>
</div>

  <div class="col-sm">
      <p>
          <mat-form-field class="w-100" appearance="outline">
              <mat-label>Approver Comment</mat-label>
              <input formControlName="approverActionComment" maxlength="40" minlength="8" matInput
                  placeholder="Enter Approver Comment" required>
              <mat-hint>Enter comment</mat-hint>
              <mat-error *ngIf="leaveActionForm.controls.approverActionComment.invalid">Approver comment is required!</mat-error>
          </mat-form-field>
      </p>
  </div>

</div>
<div class="text-right">

<button  type="submit" mat-raised-button color="primary" class="mat-control w-50 mt-2" (click)="ApproveAction()" [disabled]="isActionTaken || leaveActionForm.controls.approverActionComment.invalid">Approve</button>
<button type="submit"  mat-raised-button color="primary" class="mat-control w-50 mt-2" (click)="RejectAction()" [disabled]="isActionTaken || leaveActionForm.controls.approverActionComment.invalid">Reject</button>
  <button mat-raised-button mat-dialog-close class="w-100 mt-2">Cancel</button>
</div>
    </div>`
})
export class LeaveActionRequestComponent implements OnInit {

  leaveRequest: LeaveRequestBuilder = new LeaveRequestBuilder();
  leaveRequestModel: LeaveRequestModel = this.leaveRequest.leaveRequestModel;
  leaveActionForm: FormGroup = this.leaveRequest.leaveRequestForm;
  dataSource: MatTableDataSource<LeaveRequestModel>;

  leaveRequestAction: LeaveRequestActionBuilder = new LeaveRequestActionBuilder();
  leaveRequestActionModel: LeaveRequestActionModel = this.leaveRequestAction.leaveRequestActionModel;
  leaveRequestActionForm: FormGroup = this.leaveRequestAction.leaveRequestActionForm;

  leaveRequestForm: any;
  dateTimeFormatters: any;
  userList = [];
  loggedUserId: number;
  leaveRequestedDate: string;
  isActionTaken: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private leaveRequestService: LeaveRequestService,
    private leaveRequestApproverService: LeaveRequestApproverService,
    public router: Router,
    public commonServices: CommonService,
    public messageService: MessageService,
    public dialogRef: MatDialogRef<LeaveActionRequestComponent>,
    private loaderService: LoaderService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.populateLeaveRequestDtoData();
    this.leaveRequestModel.listOfUserForNotificationReference = [];
  }

  fetchAllUsers() {
    const loggedUser = sessionStorage.getItem('loginData');
    this.loggedUserId = JSON.parse(loggedUser).id;
    this.userService.fetchUsers().subscribe(res=>{
     res.filter(userData => {
          this.userList.push(userData);
      });
      });
 }


  populateLeaveRequestDtoData() {
    this.leaveRequestService.fetchLeaveByIdWithDetail(this.data.leaveRequestId).subscribe((res) => {
      this.leaveRequest.setModelVals(res);
      this.leaveRequestedDate = res['leaveRequestedDate'];
      this.fetchAllUsers();
      //this.dialogRef.close();
      // this.messageService.display('Boarding process is successfully completed ! Module name : ' + this.leaveRequestModel.id, 'OK', 'success');
    });
  }

  ApproveAction() {
    var retVal = confirm("Do you want to Approve Leave?");
    if (retVal == true) {
      this.isActionTaken = true;
      this.leaveRequestActionModel.approverActionComment = this.leaveRequestModel.approverActionComment;
      this.leaveRequestActionModel.leaveRequestId = this.data.leaveRequestId;
      this.leaveRequestActionModel.employeeId = this.leaveRequestModel.employeeDTOLite.id;
      this.leaveRequestActionModel.listOfUserForNotificationReference = this.leaveRequestModel.listOfUserForNotificationReference;
      this.messageService.display('Loding...' ,'OK','success');
      this.leaveRequestApproverService.approveLeave(this.leaveRequestActionModel).subscribe((res) => {
        this.messageService.display('LeaveRequest approved  successfully!', 'OK', 'success');
        this.dialogRef.close('applied');
        this.loaderService.updateInboxAction(true);

      });
    }
    else {
      return false;
    }
  }

  RejectAction() {
    var retVal = confirm("Do you want to Reject Leave?");
    if (retVal == true) {
      this.isActionTaken = true; 
      this.leaveRequestActionModel.approverActionComment = this.leaveRequestModel.approverActionComment;
      this.leaveRequestActionModel.leaveRequestId = this.data.leaveRequestId;
      this.leaveRequestActionModel.employeeId = this.leaveRequestModel.employeeDTOLite.id;
      this.leaveRequestApproverService.rejectLeave(this.leaveRequestActionModel).subscribe((res) => {
        this.messageService.display('LeaveRequest rejected  successfully!  ', 'OK', 'success');
        this.dialogRef.close('applied');
        this.loaderService.updateInboxAction(true);

      });
    }
    else {
      return false;
    }
  }

}

//historical leave request Dialogbox


@Component({
  selector: 'app-historical-leave-request-dialog',
  template: `<div [formGroup]="historicalLeaveRequestForm"><h1 mat-dialog-title align="center">Historical Leave Request Details</h1>
  <div mat-dialog-content>
 
  <div class="col-sm" formGroupName="employeeDTOLite">
  <div class="row">
  <div class="col-sm">
          <mat-label>Employee Name</mat-label>
          </div>
          <div class="col-sm">    
          <input matInput readonly formControlName="fullName">
          </div>
     </div>
  </div>

  <hr/>
  <div class="col-sm">
  <div class="row">
      <div class="col-sm">
              <mat-label>Leave Type Name</mat-label>
              </div>
              <div class="col-sm">
              <input matInput readonly formControlName="leaveTypeName">
         </div>
      </div>
 </div>
 <hr/>
      <div class="col-sm">
      <div class="row">
      <div class="col-sm">
              <mat-label>Total Leave</mat-label>
              </div>
              <div class="col-sm">    
              <input matInput readonly formControlName="totalLeaveCount">
         </div>
         </div>
      </div>

      <hr/>


      <div class="col-sm">
      <div class="row">
      <div class="col-sm">
              <mat-label>Reason</mat-label>
              </div>
              <div class="col-sm">  
              <mat-label>{{historicalLeaveRequestForm.controls.reasonForLeave.value}}</mat-label>
             
              </div>
              </div>
      </div>
      <hr/>
      <div class="col-sm">
      <div class="row">
      <div class="col-sm">
              <mat-label>Leave Requested Date</mat-label>
              </div>
              <div class="col-sm">  
              <mat-label>{{leaveRequestedDate}}</mat-label>
              </div>
              </div>
      </div>
      <hr/>
      <div class="col-sm">
  <div formArrayName="leaveRequestDetailDTOs">
      <div 
          *ngFor="let leaveRequestDetailDTO of historicalLeaveRequestForm.get('leaveRequestDetailDTOs').controls; let i = index;">
          <div class="row">

              <div class="col-sm">
                  <input type="text" readonly class="form-control-plaintext"
                      value="{{historicalLeaveRequestForm.controls.leaveRequestDetailDTOs.controls[i].controls.leaveDate.value}}">
              </div>

              <div class="col-sm">
                  <input type="text" readonly class="form-control-plaintext"
                      value="{{historicalLeaveRequestForm.controls.leaveRequestDetailDTOs.controls[i].controls.leaveRequestType.value}}">
              </div>
          </div>
      </div>
  </div>
</div>
<hr/>
<div class="col-sm">
<div class="row">
<div class="col-sm">
        <mat-label>Approver Comment</mat-label>
        </div>
        <div class="col-sm">  
        <mat-label>{{historicalLeaveRequestForm.controls.approverActionComment.value}}</mat-label>
       
        </div>
        </div>
</div>

</div>
<hr/>
<div class="text-right">
  <button mat-raised-button mat-dialog-close class="w-100 mt-2">Close</button>
</div>
    </div>`
})
export class HistoricalLeaveComponent implements OnInit {

  leaveRequest: LeaveRequestBuilder = new LeaveRequestBuilder();
  leaveRequestModel: LeaveRequestModel = this.leaveRequest.leaveRequestModel;
  historicalLeaveRequestForm: FormGroup = this.leaveRequest.leaveRequestForm;
  dataSource: MatTableDataSource<LeaveRequestModel>;
  leaveRequestedDate: string;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private leaveRequestService: LeaveRequestService,
    private leaveRequestApproverService: LeaveRequestApproverService,
    public router: Router,
    public commonServices: CommonService,
    public messageService: MessageService,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    this.populateLeaveRequestDtoData();
  }


  populateLeaveRequestDtoData() {
    this.leaveRequestService.fetchLeaveByIdWithDetail(this.data.leaveRequestId).subscribe((res) => {
      this.leaveRequestedDate = res['leaveRequestedDate'];
      this.leaveRequest.setModelVals(res);
      //this.dialogRef.close();
      // this.messageService.display('Boarding process is successfully completed ! Module name : ' + this.leaveRequestModel.id, 'OK', 'success');
    });
  }
}

//boarding Dialog
@Component({
  selector: 'Boarding-Financial-Config-Year-component',
  template: `<div [formGroup]="financialYearConfigForm"><h1 mat-dialog-title align="center"> Boarding process </h1>
  <div mat-dialog-content style="overflow-y: hidden !important; ">
    <div class="row">
      <div class="col-sm">
        <p>
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>StartDay of Month</mat-label>
            <input matInput formControlName="startDayOfMonth" type="number" ng-model="day.day" (input)="maxLengthCheck($event.target)" maxlength = "2" placeholder="Enter day"
              max="31" min="1" autofocus>
            <mat-hint>Enter Start Day</mat-hint>
            <mat-error *ngIf="financialYearConfigForm.controls.startDayOfMonth.invalid">Day is required!</mat-error>
          </mat-form-field>
        </p>
      </div>
      <div class="col-sm">
        <p>
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Start Month</mat-label>
            <input matInput formControlName="startMonth" type="number" ng-model="month.month"
              placeholder="Enter month" max="12" min="1" (input)="maxLengthCheck($event.target)" maxlength = "2" autofocus>
            <mat-hint>Enter Start Month</mat-hint>
            <mat-error *ngIf="financialYearConfigForm.controls.startMonth.invalid">Month is required!</mat-error>
          </mat-form-field>
        </p>
      </div>
      <div class="col-sm">
        <p>
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Start Year</mat-label>
            <input matInput formControlName="startYear" type="number" ng-model="year.year" placeholder="Enter year"
              max="2030" (input)="maxLengthCheck($event.target)" maxlength = "4" autofocus>
            <mat-hint>Enter Start Year</mat-hint>
            <mat-error *ngIf="financialYearConfigForm.controls.startYear.invalid">Year is required!</mat-error>
          </mat-form-field>
        </p>
      </div>
    </div>
    <div class="row">
        <div class="col-sm">
          <p>
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>EndDay of Month</mat-label>
              <input matInput formControlName="endDayOfMonth" type="number" ng-model="day.day" placeholder="Enter day"
                max="31" min="1" (input)="maxLengthCheck($event.target)" maxlength = "2" autofocus>
              <mat-hint>Enter End Day</mat-hint>
              <mat-error *ngIf="financialYearConfigForm.controls.endDayOfMonth.invalid">Day is required!</mat-error>
            </mat-form-field>
          </p>
        </div>

        <div class="col-sm">
          <p>
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>End Month</mat-label>
              <input matInput formControlName="endMonth" type="number" ng-model="month.month"
                placeholder="Enter month" max="12" min="1" (input)="maxLengthCheck($event.target)" maxlength = "2" autofocus>
              <mat-hint>Enter End Month</mat-hint>
              <mat-error *ngIf="financialYearConfigForm.controls.endMonth.invalid">Month is required!</mat-error>
            </mat-form-field>
          </p>
        </div>

        <div class="col-sm">
          <p>
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>End Year</mat-label>
              <input matInput formControlName="endYear" type="number" ng-model="year.year" placeholder="Enter year"
                max="2030" (input)="maxLengthCheck($event.target)" maxlength = "4" autofocus>
              <mat-hint>Enter Start Year</mat-hint>
              <mat-error *ngIf="financialYearConfigForm.controls.endYear.invalid">Year is required!</mat-error>
            </mat-form-field>
          </p>
        </div>
      </div>
    <hr />
    <div class="col-sm">
    <div mat-dialog-actions align="center" style="padding-bottom: 30px;">
    <button class="mat-raised-button mat-primary" align="end" (click)="updateFinancialYears()">Save</button>
    </div>
</div>

  </div>`
})

export class BoardingFinancialConfigYearComponent implements OnInit {
  financialYearConfig: FinancialYearConfigFormBuilder = new FinancialYearConfigFormBuilder();
  financialYearConfigModel: FinancialYearConfigModel = this.financialYearConfig.financialYearConfigModel;
  financialYearConfigForm: FormGroup = this.financialYearConfig.financialYearConfigForm;
  days;
  months;
  years;

  dayStartSelected: number;
  dayEndSelected: number;

  module = {
    name: ''
  };

  constructor(
    private financialYearConfigService: FinancialYearConfigService,
    private _commonServices: CommonService,
    private helpService: HelpService,
    private messageService: MessageService,
    public dialogRef: MatDialogRef<BoardingFinancialConfigYearComponent>,
    private userService: UserService
  ) { }


  ngOnInit() {

    this.fetchFinancialYears();
    const temp = sessionStorage.getItem('loginData');
    //this.boardingModel.moduleName = JSON.parse(temp).moduleName;
    this._commonServices.fetchDays().subscribe(res => {
      this.days = res.days;
    });
    this.dayStartSelected = 1;
    this.dayEndSelected = 31;
    this._commonServices.fetchMonths().subscribe(res => {
      this.months = res.months;
    });
    this._commonServices.fetchYears().subscribe(res => {
      this.years = res.years;
    });
  }
  fetchFinancialYears() {
    this.financialYearConfigService.fetchFinancialYears().subscribe((res: FinancialYearConfigModel[]) => {
      this.financialYearConfig.setModelVals(res);

    });
  }

  updateFinancialYears() {
    this.userService.checkLmsBoardingStatus(this.financialYearConfigModel).subscribe(res => {
      this.dialogRef.close();
      this.messageService.display('Boarding process is successfully completed ! Module name : ' + this.financialYearConfigModel.id, 'OK', 'success');
    });
  }

  findHelpUrlPage() {
    const currentPage = "/#_3_1_dashboard";
    window.open(this.helpService.HELP_BASE_URL + currentPage, "_blank");

  }
  maxLengthCheck(object: any) {
    if (object.value.length > object.maxLength)
      object.value = object.value.slice(0, object.maxLength)
  }
}


@Component({
  selector: 'app-attendance-entry-request-dialog',
  template: `
  <div><h1 mat-dialog-title align="center">Attendance Log {{data?.date}} </h1>
    <div mat-dialog-content>
      <table class="table table-bordered" style="width:500px">
        <tbody>
          <ng-container *ngIf = "attendanceLog.length >0; else empty">
            <ng-container *ngFor="let attendance of attendanceLog">
              <tr>
                <ng-container>
                  <td>
                    <ng-container *ngIf ="attendance.terminalNumber ===1;  else outEntry">
                      <strong class="text-success">In</strong> 
                    </ng-container>
                    <ng-template #outEntry>
                      <strong class="text-danger">Out</strong>
                    </ng-template>
                  </td>
                  <td [ngClass]="attendance.terminalNumber ===1 ? 'text-success' : 'text-danger'"> 
                    {{attendance.dateTime | date:'yyyy-MM-dd  HH:mm:ss'}}
                  </td>
                </ng-container>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
        <ng-template #empty>
            <span style="display: block;text-align: center;">No Logs For {{data?.date}} Date</span>
        </ng-template>
      </table>
    </div>
    <div class="text-right">
        <button mat-raised-button mat-dialog-close class="w-100 mt-2">Close</button>
    </div>
  </div>`
})


export class AttendanceEntryLogComponent implements OnInit {

  attendanceLog: AttendanceEntryLogs[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private attendanceService: AttendanceService,
  ) { }

  ngOnInit() {
    this.populateLeaveRequestDtoData();
  }

  populateLeaveRequestDtoData() {
    const currentDate = formatDate(this.data?.date, 'yyyy/MM/dd', 'en-US');
    this.attendanceService.fetchAllEntryAttendanceLog(currentDate).subscribe((res) => {
      this.attendanceLog = res;
    }, (err) => {
      alert("Something went wrong")
    });
  }
}


@Component({
  selector: 'app-confirmation-dialog',
  template: `<div mat-dialog-content>
  {{message}}
 </div>
 <div mat-dialog-actions>
   <button mat-button (click)="onNoClick()">Cancel</button>
   <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Confirm</button>
 </div>`
})
export class ConfirmationDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public message: string) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
  }

}

/* Begin Arroved Leave Action */
@Component({
  selector: 'app-approved-leave-action-dialog',
  template: `<div [formGroup]="leaveActionForm"><h1 mat-dialog-title align="center"> Approved Leave Cancel Action</h1>
  <div mat-dialog-content>
 
  <div class="col-sm" formGroupName="employeeDTOLite">
  <div class="row">
  <div class="col-sm">
          <mat-label>Employee Name</mat-label>
          </div>
          <div class="col-sm">
        
          <input matInput readonly formControlName="fullName">
          </div>
     </div>
  </div>

  <hr/>
  <div class="col-sm">
  <div class="row">
      <div class="col-sm">
              <mat-label>Leave Type Name</mat-label>
              </div>
              <div class="col-sm">
              <input matInput readonly formControlName="leaveTypeName">
         </div>
      </div>
 </div>
 <hr/>
      <div class="col-sm">
      <div class="row">
      <div class="col-sm">
              <mat-label>Approved Leave</mat-label>
              </div>
              <div class="col-sm">    
              <input matInput readonly formControlName="totalLeaveCount">
         </div>
         </div>
      </div>

      <hr/>


      <div class="col-sm">
      <div class="row">
      <div class="col-sm">
              <mat-label>Leave cancel reason</mat-label>
              </div>
              <div class="col-sm">  
              <mat-label>{{reasonForLeaveCancel}}</mat-label>
             
              </div>
              </div>
      </div>
      <hr/>
      <div class="col-sm">
      <div class="row">
      <div class="col-sm">
              <mat-label>Leave Requested Date</mat-label>
              </div>
              <div class="col-sm">  
              <mat-label>{{leaveRequestedDate}}</mat-label>
              </div>
              </div>
      </div>
      <hr/>
      <div class="col-sm">
  <div formArrayName="leaveRequestDetailDTOs">
      <div 
          *ngFor="let leaveRequestDetailDTO of leaveActionForm.get('leaveRequestDetailDTOs').controls; let i = index;">
          <div class="row">

              <div class="col-sm">
                  <input type="text" readonly class="form-control-plaintext"
                      value="{{leaveActionForm.controls.leaveRequestDetailDTOs.controls[i].controls.leaveDate.value}}">
              </div>
              
              <div class="col-sm">
                  <input type="text" readonly class="form-control-plaintext"
                      value="{{leaveActionForm.controls.leaveRequestDetailDTOs.controls[i].controls.leaveRequestType.value}}">
              </div>
          </div>
      </div>
  </div>
</div>
<div class="col-sm mt-3">
<p>
  <mat-form-field class="w-100" appearance="outline">
    <mat-label>Select Users</mat-label>
    <mat-select formControlName="listOfUserForNotificationReference" multiple>
       <mat-option *ngFor="let user of userList" [value]="{id:user.id, emailOrUsername:user.emailOrUsername, name:user.name}">{{user.name}}</mat-option>
    </mat-select>
    <mat-hint>Select users to whom you want to share email notification in case of cancel leave request approved</mat-hint>
 </mat-form-field>
</p>
</div>
<div class="col-sm" [formGroup] = 'leaveRequestActionForm'>
<p>
    <mat-form-field class="w-100" appearance="outline">
        <mat-label>Approver Comment</mat-label>
        <input formControlName="reviewerComment" maxlength="40" minlength="8" matInput
            placeholder="Enter Approver Comment" required>
        <mat-hint>Enter comment</mat-hint>
        <mat-error *ngIf="leaveRequestActionForm.controls.reviewerComment.invalid">Approver comment is required!</mat-error>
    </mat-form-field>
</p>
</div>
</div>
<div class="text-right">

  <button  type="submit" mat-raised-button color="primary" class="mat-control w-50 mt-2" (click)="approveAction()" [disabled]="leaveRequestActionForm.controls.reviewerComment.invalid">Approve</button>
  <button type="submit"  mat-raised-button color="primary" class="mat-control w-50 mt-2" (click)="rejectAction()" [disabled]="leaveRequestActionForm.controls.reviewerComment.invalid">Reject</button>
  <button mat-raised-button mat-dialog-close class="w-100 mt-2">Cancel</button>
</div>
    </div>`
})
export class ApprovedLeaveActionRequestComponent implements OnInit {

  leaveRequest: LeaveRequestBuilder = new LeaveRequestBuilder();
  leaveRequestModel: LeaveRequestModel = this.leaveRequest.leaveRequestModel;
  leaveActionForm: FormGroup = this.leaveRequest.leaveRequestForm;
  dataSource: MatTableDataSource<LeaveRequestModel>;

  leaveRequestAction: CancelApprovedLeaveRequestActionBuilder = new CancelApprovedLeaveRequestActionBuilder();
  leaveRequestActionModel: CancelApprovedLeaveRequestActionModel = this.leaveRequestAction.cancelLeaveRequestActionModel;
  leaveRequestActionForm: FormGroup = this.leaveRequestAction.leaveRequestActionForm;

  leaveRequestForm: any;
  dateTimeFormatters: any;
  reasonForLeaveCancel: string
  userList = [];
  loggedUserId: number;
  leaveRequestedDate: string;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private leaveRequestService: LeaveRequestService,
    private leaveRequestApproverService: LeaveRequestApproverService,
    public router: Router,
    public commonServices: CommonService,
    public messageService: MessageService,
    public dialogRef: MatDialogRef<ApprovedLeaveActionRequestComponent>,
    private loaderService: LoaderService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.populateLeaveRequestDtoData();
  }

  fetchAllUsers(appliedLeaveUserId) {
    const loggedUser = sessionStorage.getItem('loginData');
    this.loggedUserId = JSON.parse(loggedUser).id;
    this.userService.fetchUsers().subscribe(res=>{
      res.filter(userData => {
        if(userData.id !== this.loggedUserId && userData.id !== appliedLeaveUserId) {
          this.userList.push(userData);
        }
      });
      });
 }

  populateLeaveRequestDtoData() {
    this.leaveRequestService.fetchLeaveByIdWithDetail(this.data.leaveRequestId).subscribe((res) => {
      this.leaveRequestedDate = res['leaveRequestedDate']
      this.leaveRequest.setModelVals(res);
      this.reasonForLeaveCancel = res['reasonForLeaveCancel']
      this.fetchAllUsers(res['userId']);
    });
  }

  approveAction() {

    var retVal = confirm("Do you want to approve this leave request?");
    if (retVal) {
      this.leaveRequestActionModel.leaveRequestState = 'APPROVED';
      this.leaveRequestActionModel.leaveRequestId = this.data.leaveRequestId;
      this.leaveRequestActionModel.employeeId = this.leaveRequestModel.employeeDTOLite.id;
      this.leaveRequestActionModel.listOfUserForNotificationReference = this.leaveRequestModel.listOfUserForNotificationReference;
      this.messageService.display('Loding...' ,'OK','success');
      this.leaveRequestApproverService.approvedLeaveCancelRequestAction(this.leaveRequestActionModel).subscribe((res) => {
        this.messageService.display('Cancel LeaveRequest approved  successfully!', 'OK', 'success');
        this.dialogRef.close('applied');
        this.loaderService.updateInboxAction(true);

      });
    }
    else {
      return false;
    }
  }

  rejectAction() {
    var retVal = confirm("Do you want to reject this leave request?");
    if (retVal) {
      this.leaveRequestActionModel.leaveRequestState = 'REJECTED';
      this.leaveRequestActionModel.leaveRequestId = this.data.leaveRequestId;
      this.leaveRequestActionModel.employeeId = this.leaveRequestModel.employeeDTOLite.id;
      this.leaveRequestApproverService.approvedLeaveCancelRequestAction(this.leaveRequestActionModel).subscribe((res) => {
        this.messageService.display('Cancel LeaveRequest rejected  successfully!  ', 'OK', 'success');
        this.dialogRef.close('applied');
        this.loaderService.updateInboxAction(true);
      });
    }
    else {
      return false;
    }
  }

}

/* End Approved Leave Action */



/*Begin Cancel Approved Leave */

@Component({
  selector: 'app-approved-leave-action-dialog',
  template: `
  <form [formGroup] = "cancelLeaveRequestForm">
  <div mat-dialog-content>
    <p>Do you want to cancel leave?</p>
    <mat-form-field class="w-100">
      <mat-label>Reason for cancel leave</mat-label>
      <input matInput  formControlName="reasonForLeaveCancel">
      <mat-error *ngIf="cancelLeaveRequestForm.controls.reasonForLeaveCancel.invalid">Reason for leave cancel is required!</mat-error>
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button cdkFocusInitial (click)="onOkClick()" [disabled]="cancelLeaveRequestForm.invalid">Ok</button>
  </div>
  </form>`
})
export class CancelApprovedComponent implements OnInit {

  cancelLeaveRequestBuilder: CancelLeaveRequestBuilder = new CancelLeaveRequestBuilder();
  cancelLeaveRequestModel: CancelLeaveRequestModel = this.cancelLeaveRequestBuilder.cancelLeaveRequestModel;
  cancelLeaveRequestForm: FormGroup = this.cancelLeaveRequestBuilder.cancelLeaveRequestForm;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private leaveRequestService: LeaveRequestService,
    private leaveRequestApproverService: LeaveRequestApproverService,
    public router: Router,
    public commonServices: CommonService,
    public messageService: MessageService,
    public dialogRef: MatDialogRef<CancelApprovedComponent>,
    private loaderService: LoaderService,
  ) { }

  ngOnInit() {}

 
  onNoClick(): void {
    this.dialogRef.close();
  }

  onOkClick() {
    this.cancelLeaveRequestModel.leaveRequestId = this.data.leaveRequestId;
    this.leaveRequestService.cancelApprovedLeave(this.cancelLeaveRequestModel).subscribe(() => {
      this.messageService.display('You have successfully applied for leave cancellation!  ', 'OK', 'success');
      this.dialogRef.close('applied');
      this.router.navigate(['/leaveRequest']);
    }, err => {
      this.messageService.display(err.error.errors.LeaveRequestApproveValidation[0].message, 'OK', 'error');
    });
    this.dialogRef.close();
  }
}
/*End Cancel Approved Leave */

//Calendar Summary Component

@Component({
  selector: 'app-calendar-summary-list',
  template: `<h2 mat-dialog-title class="d-inline-flex align-items-center mb-0"><mat-icon matSuffix>calendar_today</mat-icon><span>&nbsp;&nbsp;Add Calendar Type</span></h2>
  <hr/>
  <mat-dialog-content>
    <p class="mb-0">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Select Calendar</mat-label>
        <mat-select name="feddbackRequest" [formControl]="calendars" required>
        <ng-container *ngFor="let calenderType of calendarList">
        <mat-option  [value] = "calenderType.id">{{calenderType.name}}</mat-option>
        </ng-container>
        </mat-select>
        <mat-hint>Select calendar type</mat-hint>
        <mat-error *ngIf="calendars.invalid">Calendar type is required!</mat-error>
      </mat-form-field>
    </p>
  </mat-dialog-content>
  <hr/>
  <div >
    <button (click)="addSelectedCalendar()" mat-raised-button color="primary" class="w-100" [disabled]="calendars.invalid">Add</button>
    <button mat-raised-button mat-dialog-close class="w-100 mt-2">Cancel</button>
  </div>`
})
export class CalendarSummaryComponent implements OnInit {

  calendarList = [];
  calendars = new FormControl('', [Validators.required]);

  constructor(
      public dialogRef: MatDialogRef<CalendarSummaryComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private userCalendarService: UserCalendarService,
      private messageService: MessageService
  ) { }

  ngOnInit() {
      this.calendarList = this.data.res;
      this.getCalendarSummary();
   }

   addSelectedCalendar() {
       let calendarSummaryDTO = {calendarLinkId: this.data.calendarId, calendarSummaryId: this.calendars.value}
       this.userCalendarService.updateCalendarLink(calendarSummaryDTO).subscribe(()=>{
          this.messageService.display('Linked calendar type sucessfully added!', 'OK', 'success');
          this.dialogRef.close('applied');
       });      
   }

   getCalendarSummary() {
    this.userCalendarService.fetchCalendarSummary(this.data.calendarId).subscribe(res=> {
        this.calendars.setValue(res.calendarSummaryId)
    });
}
}

//confirmation box


@Component({
  selector: 'app-confirm-box',
  template: `<div  mat-dialog-title><span>{{title}}</span>
  </div>
  <div mat-dialog-content style="margin-top:20px">
    <p style="font-size:16px;">{{message+"?"}} </p>
  </div>
  <div mat-dialog-actions class="float-right">
    <button mat-button (click)="onNoClick()">No</button>
    <button mat-flat-button color="warn" [mat-dialog-close]="true" cdkFocusInitial (click)="onYesClick()">Yes</button>
  </div>`
})
export class ConfirmationBoxComponent implements OnInit {

  title:string;
  message:string;
  constructor(
      public dialogRef: MatDialogRef<ConfirmationBoxComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
      this.title = this.data.title;
      this.message = this.data.message;
   }

   onNoClick(): void {
      this.dialogRef.close();
    }

    onYesClick() {
          this.dialogRef.close("applied");
    }
}

@Component({
  selector: 'app-increment-leave-count-dialog',
  template: `
<div [formGroup]="leaveAdjustForm">
  <h1 mat-dialog-title align="center">Leave Action</h1>
  <div mat-dialog-content>
    <hr/>
    <div class="col-sm">
      <div class="row">
        <div class="col-sm">
          <div class="d-flex align-items-center">
            <mat-label class="mr-2">Leave Count : </mat-label>
            <mat-form-field appearance="outline" class="custom-width">
              <input matInput type="number" formControlName="leaveAdjustmentCount" step="0.5" min="0" (input)="onSalaryInput($event)">
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-sm">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Select Leave Adjustment Type</mat-label>
          <mat-select formControlName="leaveTypeName">
            <mat-option *ngFor="let action of actions" [value]="action">{{ action.name }}</mat-option>
          </mat-select>
          <mat-hint>Enter Leave Action</mat-hint>
        </mat-form-field>
      </div>
    </div>
    <hr/>
  </div>
  <div class="text-right">
    <button type="button" mat-raised-button color="primary" class="mat-control w-50 mt-2" (click)="approveAction()" [disabled]="leaveAdjustForm.invalid">Confirm</button>
    <button mat-raised-button mat-dialog-close class="mat-control w-50 mt-2">Cancel</button>
  </div>
</div>
  `
})


export class AddLeaveAdjustRequestComponent implements OnInit {

  leaveAdjust: LeaveAdjustFormBuilder = new LeaveAdjustFormBuilder();
  leaveAdjustModel: LeaveAdjustModel = this.leaveAdjust.leaveAdjustModel;
  leaveAdjustForm: FormGroup = this.leaveAdjust.leaveAdjustForm;
  dataSource: MatTableDataSource<LeaveAdjustModel>;
  actions = [{"id" : "INCREASE", "name" : "INCREASE"}, {"id" : "DECREASE", "name" : "DECREASE"}];

  leaveAdjustAction: LeaveAdjustActionBuilder = new LeaveAdjustActionBuilder();
  leaveAdjustActionModel: LeaveAdjustActionBuilder = this.leaveAdjustAction.leaveAdjustActionBuilder;
  leaveAdjustActionForm: FormGroup = this.leaveAdjustAction.leaveAdjustActionForm;

  leaveAdjustRequestForm: any;
  dateTimeFormatters: any;
  userList = [];
  loggedUserId: number;
  leaveAdjustDate: string;
  reasonForLeaveCancel: any;

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      private leaveAdjustService: LeaveAdjustService,
      public router: Router,
      public commonServices: CommonService,
      public messageService: MessageService,
      public dialogRef: MatDialogRef<AddLeaveAdjustRequestComponent>,
      private loaderService: LoaderService,
      private userService: UserService
  ) { }

  ngOnInit() {
      console.log(this.actions);
      console.log(this.data);
  }
  onSalaryInput(event: any): void {
    const input = event.target as HTMLInputElement;
    const value = parseFloat(input.value);
    if (isNaN(value) || value < 0) {
       input.value = ''; // Set the value to 0 if it's NaN or negative
    }
 }

  approveAction() {
    console.log(this.leaveAdjustForm.value);

    var retVal = confirm("Do you want to approve this leave adjustment request?");
    if (retVal == true) {

      var obj = {
        "leaveAdjustmentCount" : this.leaveAdjustForm.value.leaveAdjustmentCount, 
        "action": this.leaveAdjustForm.value.leaveTypeName.name, 
        "employeeId" : this.data.emplId
      }

      console.log(JSON.stringify(obj));

        // this.leaveAdjustActionModel.leaveRequestState = 'APPROVED';
        // this.leaveAdjustActionModel.leaveRequestId = this.data.leaveRequestId;
        // this.leaveAdjustActionModel.employeeId = this.data.empId;
        this.messageService.display('Loding...', 'OK', 'success');
        this.leaveAdjustService.saveLeaveAdjustmentRequest(JSON.stringify(obj)).subscribe((res) => {
            this.messageService.display('Leave adjusted successfully!', 'OK', 'success');
            this.dialogRef.close('applied');
            this.loaderService.updateInboxAction(true);
        });
    }
    else {
        return false;
    }
  }

}