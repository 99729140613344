import { Injectable } from '@angular/core';
import { HttpRequestService } from '../http/http-request.service';
import { Observable } from 'rxjs';
import { LeaveRequestModel } from '../../shared/models/leave-request-model';
import { LeaveActionModel } from '../../shared/models/leave-action-model';
import { EmployeeLeaveStatModel } from 'src/app/shared/models/employee-leave-stat-model';
import { wfhRequestModel } from 'src/app/shared/models/wfh-request-model';
import { WfhRequestActionBuilder, WfhRequestActionModel } from 'src/app/shared/models/wfh-request-action-model';
import { WfhApplyRequestModel } from 'src/app/shared/models/wfh-apply-request-model';

@Injectable({
  providedIn: 'root'
})
export class WfhRequestService {
  // for api call
  private stateUrl = 'api/v1/wfh-request';

  constructor(
    private httpRequest: HttpRequestService
    ) { }

  fetchWfhRequest(): Observable<wfhRequestModel[]> {
    return this.httpRequest.get(this.stateUrl + '/');
  }

  
  updateWfhRequest(WfhApplyRequestModel: WfhApplyRequestModel): Observable<any> {
    return this.httpRequest.put(this.stateUrl, WfhApplyRequestModel);
  }

  addWfhRequest(wfhApplyModel: WfhApplyRequestModel): Observable<any> {
    return this.httpRequest.post(this.stateUrl, wfhApplyModel);
  }

  fetchWfhByIdWithDetail(Id): Observable<wfhRequestModel> {
    return this.httpRequest.get(this.stateUrl + '/' + Id);
  }

  CancelWfhRequest(data): Observable<any> {
    return this.httpRequest.put(this.stateUrl + '/cancel' , data);
  }
  
  fetchAllWfhRequests(currentMonthStartDate, currentMonthLastDate): Observable<any> {
    return this.httpRequest.post('api/v1/wfh-request', { "startDate": currentMonthStartDate, "endDate": currentMonthLastDate });
  }

  cancelApprovedWfhRequest(data): Observable<any> {
    return this.httpRequest.put(this.stateUrl + '/cancel-approved-wfh-request' , data);
  }

  approvedWfhCancelRequestAction(data): Observable<any> {
    return this.httpRequest.post(this.stateUrl + '/approved-wfh-cancel-request-action', data);
  }
}
