import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { LeaveEncashmentRequestService } from 'src/app/core/services/leave-encashment-request.service';
import { LeaveTypeService } from 'src/app/core/services/leave-type.service';
import { UserService } from 'src/app/core/services/user.service';
import { LoaderService } from 'src/app/shared/factory/loader.service';
import { MessageService } from 'src/app/shared/factory/message.service';
import { LeaveEncashmentRequestBuilder, LeaveEncashmentRequestModel } from 'src/app/shared/models/leave-encashment-model';
import { LeaveTypeModel } from 'src/app/shared/models/leave-type-model';
import { UserModel } from 'src/app/shared/models/user-model';


@Component({
  selector: 'app-apply-encashment-leave',
  templateUrl: './apply-encashment-leave.component.html',
  styleUrls: ['./apply-encashment-leave.component.css']

})
export class ApplyEncashmentLeaveComponent implements OnInit {

  leaveEncashment: LeaveEncashmentRequestBuilder = new LeaveEncashmentRequestBuilder();
  leaveEncashmentModel: LeaveEncashmentRequestModel = this.leaveEncashment.leaveEncashmentRequestModel;
  leaveEncashmentRequestForm: FormGroup = this.leaveEncashment.leaveEncashmentRequestForm;
  leaveTypes = [];
  approvers = [];
  totalLeaveEncashmentArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30];

  constructor(public location: Location,
    private userService: UserService,
    public router: Router,
    private messageService: MessageService,
    private leaveEncashmentRequestService: LeaveEncashmentRequestService,
    private loaderService: LoaderService,
    private leaveTypeService: LeaveTypeService) {
  }

  ngOnInit() {
    this.getApproverDetails();
  }

  getApproverDetails() {
    this.leaveTypeService.fetchLeaveType().subscribe((res: LeaveTypeModel[]) => {
      this.leaveTypes = res.filter(leaveType => leaveType.name === 'Normal Leave Type');
    })
    this.userService.fetchLeaveEncashmentApproversEmployeesLite().subscribe((res: UserModel[]) => {
      this.approvers = res;
    });
  }

  addUpdateleaveEncashmentRequest() {
    this.leaveEncashmentRequestService.addLeaveEncashmentRequest(this.leaveEncashmentModel).subscribe((res) => {
      this.messageService.display('Leave Encashment Request add is successfull! Leave ID : ' + res, 'OK', 'success');
      this.loaderService.updateInboxAction(true);
      this.router.navigate(['/leaveEncashmentRequest']);
    });

  }
}
