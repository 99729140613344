import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';

export interface LeaveAdjustModel {
    employeeId: number,
    employeeName: String,
    leaveTypeName: number,
    leaveAdjustmentCount: number,
    leaveAction: Array<any>
}
export class LeaveAdjustFormBuilder {
    leaveAdjustModel: LeaveAdjustModel = {
        employeeId: null,
        employeeName: '',
        leaveTypeName: null,
        leaveAction: [],
        leaveAdjustmentCount: null
    };
    leaveAdjustForm: FormGroup = new FormGroup({
    id: new FormControl(this.leaveAdjustModel.employeeId),
    employeeName: new FormControl(this.leaveAdjustModel.employeeName),
    leaveAdjustmentCount: new FormControl(this.leaveAdjustModel.leaveAdjustmentCount),
    leaveTypeName: new FormControl(this.leaveAdjustModel.leaveTypeName),
    leaveAction: new FormControl(this.leaveAdjustModel.leaveAction)
});

    constructor(){
        this.leaveAdjustForm.valueChanges.subscribe(val => {
        this.leaveAdjustModel.employeeId = val.employeeId;
        this.leaveAdjustModel.employeeName = val.employeeName;
        this.leaveAdjustModel.leaveTypeName = val.leaveTypeName;
        this.leaveAdjustModel.leaveAdjustmentCount = val.leaveAdjustmentCount;
        this.leaveAdjustModel.leaveAction = val.leaveAction;
    });
}

setModelVals(res: LeaveAdjustModel) {
    this.leaveAdjustForm.controls.employeeId.setValue(res.employeeId);
    this.leaveAdjustForm.controls.employeeName.setValue(res.employeeName);
    this.leaveAdjustForm.controls.leaveTypeName.setValue(res.leaveTypeName);
    this.leaveAdjustForm.controls.leaveAdjustmentCount.setValue(res.leaveAdjustmentCount);
    this.leaveAdjustForm.controls.leaveAction.setValue(res.leaveAction);
}

}

export interface LeaveAdjsutActionModel {
    employeeId: number;
    employeeName: string;
    leaveTypeName: number;
    leaveAdjustmentCount: number;
    leaveAction: Array<any>;
}

export class LeaveAdjustActionBuilder {
    leaveAdjustModel: LeaveAdjsutActionModel = {
        employeeId: null,
        employeeName: '',
        leaveTypeName: null,
        leaveAdjustmentCount: null,
        leaveAction: []
    };

    leaveAdjustForm: FormGroup = new FormGroup({
        id: new FormControl(this.leaveAdjustModel.employeeId),
        employeeName: new FormControl(this.leaveAdjustModel.employeeName),
        leaveAdjustmentCount: new FormControl(this.leaveAdjustModel.leaveAdjustmentCount),
        leaveTypeName: new FormControl(this.leaveAdjustModel.leaveTypeName),
        leaveAction: new FormControl(this.leaveAdjustModel.leaveAction)
    });
    leaveAdjustActionForm: FormGroup;
    leaveAdjustActionBuilder: LeaveAdjustActionBuilder;
    leaveAdjustState: string;
    leaveAdjustId: any;
    leaveRequestState: string;
    leaveRequestId: any;
 
    constructor() {
        this.leaveAdjustForm.valueChanges.subscribe(val => {
            this.leaveAdjustModel.employeeId = val.employeeId;
            this.leaveAdjustModel.employeeName = val.employeeName;
            this.leaveAdjustModel.leaveTypeName = val.leaveTypeName;
            this.leaveAdjustModel.leaveAdjustmentCount = val.leaveAdjustmentCount;
            this.leaveAdjustModel.leaveAction = val.leaveAction;
        });
    }

    setModelVals(res: LeaveAdjsutActionModel) {
        this.leaveAdjustForm.controls.employeeId.setValue(res.employeeId);
        this.leaveAdjustForm.controls.employeeName.setValue(res.employeeName);
        this.leaveAdjustForm.controls.leaveTypeName.setValue(res.leaveTypeName);
        this.leaveAdjustForm.controls.leaveAdjustmentCount.setValue(res.leaveAdjustmentCount);
        this.leaveAdjustForm.controls.leaveAction.setValue(res.leaveAction);
    }
}
