import { Component, OnInit } from '@angular/core';
import { FinancialYearConfigModel, FinancialYearConfigFormBuilder } from '../../shared/models/financial-year-config-model';
import { FinancialYearConfigService } from '../../core/services/financial-year-config-service.service';
import { FormGroup } from '@angular/forms';
import { CommonService } from '../../shared/factory/common.service';
import { MessageService } from '../../shared/factory/message.service';
import { HelpService } from '../../core/services/help.service';

@Component({
  selector: 'app-financial-config-year',
  templateUrl: './financial-config-year.component.html',
  styleUrls: ['./financial-config-year.component.css']
})
export class FinancialConfigYearComponent implements OnInit {
  financialYearConfig: FinancialYearConfigFormBuilder = new FinancialYearConfigFormBuilder();
  financialYearConfigModel: FinancialYearConfigModel = this.financialYearConfig.financialYearConfigModel;
  financialYearConfigForm: FormGroup = this.financialYearConfig.financialYearConfigForm;
  days;
  months;
  years;
  
  dayStartSelected: number;
  dayEndSelected: number;

 constructor(
    private financialYearConfigService: FinancialYearConfigService,
    private _commonServices: CommonService,
    private helpService: HelpService,
    private messageService: MessageService
  ) { }


  ngOnInit() {
    
    this.fetchFinancialYears();
    this._commonServices.fetchDays().subscribe(res => {
      this.days = res.days;
    });
    this.dayStartSelected=1;
    this.dayEndSelected=31;
    this._commonServices.fetchMonths().subscribe(res => {
      this.months = res.months;
    });
    this._commonServices.fetchYears().subscribe(res => {
      this.years = res.years;
    });
  }
  fetchFinancialYears() {
    this.financialYearConfigService.fetchFinancialYears().subscribe((res: FinancialYearConfigModel[]) => {
      this.financialYearConfig.setModelVals(res);
    });
  }

  updateFinancialYears() {
    this.financialYearConfigService.updateFinancialYears(this.financialYearConfigModel).subscribe(res => {
      this.messageService.display('FinancialYear update is successfull! FinancialYear ID : ' + this.financialYearConfigModel.id, 'OK', 'success');
    });
  }

  findHelpUrlPage(){
    const currentPage = "/#_4_2_manage_financial_year";
    window.open(this.helpService.HELP_BASE_URL + currentPage, "_blank");
   
  }

  maxLengthCheck(object : any) {
    if (object.value.length > object.maxLength)
      object.value = object.value.slice(0, object.maxLength)
  }
}

