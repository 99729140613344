import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { FormArray, FormGroup } from '@angular/forms';
import { AttendanceService } from 'src/app/core/services/attendance.service';
import { HelpService } from 'src/app/core/services/help.service';
import { LoaderService } from 'src/app/shared/factory/loader.service';
import { MessageService } from 'src/app/shared/factory/message.service';
import { EmployeeAttendanceDTOsBuilder, EmployeeAttendanceDTOsModel } from 'src/app/shared/models/employee-attendance-dto-model';
import { map } from 'rxjs/operators';
import { formatDate } from '@angular/common';
import { AttendanceEntryLogComponent } from 'src/app/shared/popups/popups';
import { AttendanceRequestActionModel } from 'src/app/shared/models/attendance-request-action-model';


@Component({
  selector: 'app-my-attendance',
  templateUrl: './my-attendance.component.html',
  styleUrls: ['./my-attendance.component.css']
})
export class MyAttendanceComponent implements OnInit {

  attendanceReq: EmployeeAttendanceDTOsBuilder = new EmployeeAttendanceDTOsBuilder();
  attendanceModel: EmployeeAttendanceDTOsModel = this.attendanceReq.employeeAttendanceDTOsModel;
  attendanceForm: FormGroup = this.attendanceReq.employeeAttendanceDTOsForm;

  /* get attendanceFormGroup() {
    return this.attendanceForm.get('employeeAttendanceDTOsArray') as FormArray;
  } */

  displayedColumns: string[] = ['id', 'date', 'entryTime', 'exitTime', 'totalHours', 'viewDetails', 'action'];
  dataSource: MatTableDataSource<EmployeeAttendanceDTOsModel>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private attendanceService: AttendanceService,
    private helpService: HelpService,
    public messageService: MessageService,
    public router: Router,
    private dialog: MatDialog,
    private loaderService: LoaderService,
  ) { }

  ngOnInit() {
    this.fetchAttendance();
  }

  fetchAttendance() {
    // const format = 'yyyy/MM/dd';
    // const locale = 'en-US';
    const curDate = formatDate(new Date(), 'yyyy/MM/dd', 'en-US');
    const lastDate = formatDate(new Date(new Date().setDate(new Date().getDate() - 60)), 'yyyy/MM/dd', 'en-US');
    this.attendanceService.fetchAllAttendance(0, 100, curDate, lastDate).pipe(map(result => result["employeeAttendanceDTOs"])).subscribe((res: EmployeeAttendanceDTOsModel[]) => {
      this.dataSource = new MatTableDataSource<EmployeeAttendanceDTOsModel>(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  //do we need to change this?
  findHelpUrlPage() {
    const currentPage = "/#_2_2_leave_request";
    window.open(this.helpService.HELP_BASE_URL + currentPage, "_blank");

  }

  openLeaveActionDialog(date) {
    const dialogRef = this.dialog.open(AttendanceEntryLogComponent, {
      data: { date }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'applied') {
        this.loaderService.updateInboxAction(true);
      };
    });
  }

  routeToSectionFieldsId(attendanceRequestDataSource, id: number) {
  
    this.attendanceService.routeToSectionFieldsIds(attendanceRequestDataSource);
    this.router.navigate(['/applyAttendanceRegularisation', id]);
  }
}
