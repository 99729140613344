import { Injectable } from '@angular/core';
import { UserService } from '../../core/services/user.service';
import { SecurityService } from '../../core/services/security.service';
import { HolidayService } from '../../core/services/holiday.service';
import { forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
class DataCachingService {

  constructor(
    private _userService: UserService,
    private _securityService: SecurityService
  ) { }

  fetchAll() {
    this._securityService.loadAppAccessPermissions();
    if (!sessionStorage.getItem('cached')) {
     // let jobDescriptions = this._jobDescriptionService.fetchJobDescriptions();
      // let jobTitles = this._jobTitleService.fetchJobTitles();
      // let jobPositions = this._jobPositionService.fetchJobPositions();
      // let organizations = this._organizationService.fetchOrganization();
      // let departments = this._departmentService.fetchDepartments();
      // let employees = this._userService.fetchEmployees();
      // let qualifications = this._qualificationService.fetchQualifications();
      // let skills = this._skillService.fetchSkills();
      // let recruiters = this._recruiterService.fetchRecruiters();
      // let jobRequisitions = this._jobRequisitionService.fetchJobRequisitions();
      // forkJoin([jobDescriptions, jobTitles, organizations, departments, employees, qualifications, skills, recruiters, jobRequisitions, jobPositions]).subscribe(results => {
      //   sessionStorage.setItem('jobDescriptions', JSON.stringify(results[0]));
      //   sessionStorage.setItem('jobTitles', JSON.stringify(results[1]));
      //   sessionStorage.setItem('organizations', JSON.stringify(results[2]));
      //   sessionStorage.setItem('departments', JSON.stringify(results[3]));
      //   sessionStorage.setItem('employees', JSON.stringify(results[4]));
      //   sessionStorage.setItem('qualifications', JSON.stringify(results[5]));
      //   sessionStorage.setItem('skills', JSON.stringify(results[6]));
      //   sessionStorage.setItem('recruiters', JSON.stringify(results[7]));
      //   sessionStorage.setItem('jobRequisitions', JSON.stringify(results[8]));
      //   sessionStorage.setItem('jobPositions', JSON.stringify(results[9]));
      //   sessionStorage.setItem('cached', 'true');
      // });

      let employees = this._userService.fetchEmployees();
       forkJoin([ employees]).subscribe(results => {
      sessionStorage.setItem('employees', JSON.stringify(results[0]));
            sessionStorage.setItem('cached', 'true');
      });
    }
  }

  refreshAll() {
    sessionStorage.removeItem('cached');
    this.fetchAll();
  }
}

function fetchCachedData(dataType) {
  return sessionStorage.getItem(dataType) ? JSON.parse(sessionStorage.getItem(dataType)) : [];
}

export { fetchCachedData, DataCachingService };