import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CarryForwardDetailService } from 'src/app/core/services/carry-forward-detail.service';
import { DataService } from 'src/app/shared/factory/data.service';
import { MessageService } from 'src/app/shared/factory/message.service';
import { CarryForwardDetailModel } from 'src/app/shared/models/carry-forward-detail-model';
import { ConfirmationDialogComponent } from 'src/app/shared/popups/popups';
import { CarryForwardService } from '../../core/services/carry-forward.service';
import { CarryForwardActionRequestModel } from 'src/app/shared/models/carry-forward-request-action-model';

@Component({
  selector: 'app-carry-forward-detail',
  templateUrl: './carry-forward-detail.component.html',
  styleUrls: ['./carry-forward-detail.component.css']
})
export class CarryForwardDetailComponent implements OnInit {

  carryForwardId: string
  displayedColumns: string[] = ['Id', 'employeeName', 'countOfPreviousFinancialYearCreditLeave', 'countOfCurrentFinancialYearCreditLeave', 'carryForwardLimit', 'maxAccumulationLimit', 'totalCarryForward', 'finalBalance', 'carryForwardRequestStatus', 'Action'];
  dataSource: MatTableDataSource<CarryForwardDetailModel>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  carryForwardActionRequestDetail: CarryForwardActionRequestModel = {
    id: 0,
    carryForwardRequestStatus: null
  };



  constructor(
    private carryForwardService: CarryForwardService,
    private data: DataService,
    private router: Router,
    private dialog: MatDialog,
    private carryForwardServiceDetail: CarryForwardDetailService,
    private messageService: MessageService,
  ) { }

  ngOnInit() {
    this.data.currentMessage.subscribe(message => this.carryForwardId = message);
    this.listOfCarryForwardDetail(this.carryForwardId);
  }

  listOfCarryForwardDetail(carryForwardId) {
    this.carryForwardService.fetchCarryForwardDetailList(this.carryForwardId).subscribe((res: CarryForwardDetailModel[]) => {
      this.dataSource = new MatTableDataSource<CarryForwardDetailModel>(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  getStatus(carryForwardDetailId, approveaction) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: "Are you sure , you want to " + approveaction + " this request ?"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (approveaction == 'approve') {
          approveaction = "APPROVED";
        }
        else {
          approveaction = "REJECT";
        }
        this.carryForwardActionRequestDetail.id = carryForwardDetailId;
        this.carryForwardActionRequestDetail.carryForwardRequestStatus = approveaction;
        this.carryForwardServiceDetail.approvalAction(this.carryForwardActionRequestDetail).subscribe((res: any) => {
          this.messageService.display('For CarryForwardActionRequestDetail ' + res.id + ' Approval is : ' + res.carryForwardRequestStatus, 'OK', 'success');
          this.listOfCarryForwardDetail(this.carryForwardId);
        });
      }
    });
  }
  RedirectToBack() {
    this.router.navigate(['/carryForward']);
  }
}
