import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { customValidators } from '../factory/common.service';
import { LeaveRequestDetailModel } from './leave-request-detail-dtos-model';
export interface LeaveRequestModel {
    id: number,
    reasonForLeave: string,
    isDisabled: boolean;
    leaveTypeId: number,
    leaveTypeName: string,
    teamNotificationEmail: string,
    employeeDTOLite: {
        id: number,
        firstName: string,
        lastName: string,
        fullName: String,
    },// id, firstName, lastName
    leaveRequestState: string,
    leaveRequestApprovers: Array<any>,
    totalLeaveCount: number,

    approverActionComment: string,
    listOfUserForNotificationReference: Array<any>,
    //: Array<Date>;
    // leaveRequestDetailDTOs: Array<LeaveRequestDetailModel>; 
    leaveRequestDetailDTOs: LeaveRequestDetailModel[];
}

export class LeaveRequestBuilder {
    leaveRequestModel: LeaveRequestModel = {
        id: null,
        reasonForLeave: '',
        isDisabled: false,
        leaveTypeId: null,
        leaveTypeName: '',
        teamNotificationEmail: '',
        employeeDTOLite: {
            id: null,
            firstName: '',
            lastName: '',
            fullName: ''
        },
        leaveRequestState: '',
        leaveRequestApprovers: [],
        totalLeaveCount: null,
        approverActionComment: '',
        listOfUserForNotificationReference: [],
        //  leaveDate: [],
        leaveRequestDetailDTOs: []
    };

    leaveRequestForm: FormGroup = new FormGroup({
        reasonForLeave: new FormControl(this.leaveRequestModel.reasonForLeave, [Validators.required]),
        leaveTypeId: new FormControl(this.leaveRequestModel.leaveTypeId, [Validators.required]),
        leaveTypeName: new FormControl(this.leaveRequestModel.leaveTypeName),
        teamNotificationEmail: new FormControl(this.leaveRequestModel.teamNotificationEmail, [Validators.required]),
        employeeDTOLite: new FormGroup({
            id: new FormControl(this.leaveRequestModel.employeeDTOLite.id),
            firstName: new FormControl(this.leaveRequestModel.employeeDTOLite.firstName),
            lastName: new FormControl(this.leaveRequestModel.employeeDTOLite.lastName),
            fullName: new FormControl(this.leaveRequestModel.employeeDTOLite.firstName + this.leaveRequestModel.employeeDTOLite.lastName ),
        }),
        leaveRequestState: new FormControl(this.leaveRequestModel.leaveRequestState, [Validators.required]),
        leaveRequestApprovers: new FormControl(this.leaveRequestModel.leaveRequestApprovers, [Validators.required]),
        totalLeaveCount: new FormControl(this.leaveRequestModel.totalLeaveCount),
        fromDate: new FormControl(''),
        toDate: new FormControl(''),
        approverActionComment: new FormControl(this.leaveRequestModel.approverActionComment, [Validators.required]),
        listOfUserForNotificationReference: new FormControl(this.leaveRequestModel.listOfUserForNotificationReference, [Validators.required]),
        //leaveDate: new FormControl(this.leaveRequestModel.leaveRequestDetailDTOs.leaveDate),
        // leaveRequestType: new FormControl(this.leaveRequestModel.leaveRequestDetailDTOs.leaveRequestType)
        leaveRequestDetailDTOs: new FormArray([])

    });

    constructor() {
        this.leaveRequestForm.valueChanges.subscribe(val => {

            this.leaveRequestModel.reasonForLeave = val.reasonForLeave;
            this.leaveRequestModel.leaveTypeId = val.leaveTypeId;
            this.leaveRequestModel.leaveTypeName = val.leaveTypeName;
            this.leaveRequestModel.teamNotificationEmail = val.teamNotificationEmail;
            this.leaveRequestModel.employeeDTOLite = val.employeeDTOLite;
            this.leaveRequestModel.leaveRequestState = val.leaveRequestState;
            this.leaveRequestModel.leaveRequestApprovers = val.leaveRequestApprovers;
            this.leaveRequestModel.totalLeaveCount = val.totalLeaveCount;
            //  this.leaveRequestModel.fromDate = val.fromDate;
            //  this.leaveRequestModel.toDate = val.toDate;
            this.leaveRequestModel.approverActionComment = val.approverActionComment;
            this.leaveRequestModel.listOfUserForNotificationReference = val.listOfUserForNotificationReference;
            //this.leaveRequestModel.leaveRequestDetailDTOs.leaveDate = val.leaveDate;
            //this.leaveRequestModel.leaveRequestDetailDTOs.leaveRequestType = val.leaveRequestType;
            this.leaveRequestModel.leaveRequestDetailDTOs = val.leaveRequestDetailDTOs;
        });
    }
    setModelVals(res: LeaveRequestModel) {
        this.leaveRequestForm.controls.reasonForLeave.setValue(res.reasonForLeave);
        this.leaveRequestForm.controls.leaveTypeId.setValue(res.leaveTypeId);
        this.leaveRequestForm.controls.leaveTypeName.setValue(res.leaveTypeName);
        this.leaveRequestForm.controls.teamNotificationEmail.setValue(res.teamNotificationEmail);
        //  this.leaveRequestForm.controls.employeeDTOLite.setValue(res.employeeDTOLite);
        this.leaveRequestForm.controls.leaveRequestState.setValue(res.leaveRequestState);
        this.leaveRequestForm.controls.leaveRequestApprovers.setValue(res.leaveRequestApprovers);
        this.leaveRequestForm.controls.totalLeaveCount.setValue(res.totalLeaveCount);
        this.leaveRequestForm.controls.approverActionComment.setValue(res.approverActionComment);
        this.leaveRequestForm.controls.listOfUserForNotificationReference.setValue(res.listOfUserForNotificationReference);


        let employeeDetailformGroup = this.leaveRequestForm.get('employeeDTOLite') as FormGroup;

        employeeDetailformGroup.controls.id.setValue(res.employeeDTOLite.id);
        employeeDetailformGroup.controls.firstName.setValue(res.employeeDTOLite.firstName);
        employeeDetailformGroup.controls.lastName.setValue(res.employeeDTOLite.lastName);
        employeeDetailformGroup.controls.fullName.setValue(res.employeeDTOLite.firstName + res.employeeDTOLite.lastName);
        // new FormGroup({
        //     id: new FormControl(res.employeeDTOLite.id),
        //     firstName: new FormControl(res.employeeDTOLite.firstName),
        //     lastName: new FormControl(res.employeeDTOLite.lastName)
        // });
        //this.leaveRequestForm.controls.employeeDTOLite.setValue(employeeDetailformGroup);

        for (var i = 0; i < res.leaveRequestDetailDTOs.length; i++) {
            let leaveDetailformGroup = new FormGroup({
                leaveDate: new FormControl(res.leaveRequestDetailDTOs[i].leaveDate),
                leaveRequestType: new FormControl(res.leaveRequestDetailDTOs[i].leaveRequestType)
            })
            let leaveRequestDetailArray = this.leaveRequestForm.get('leaveRequestDetailDTOs') as FormArray;
            leaveRequestDetailArray.push(leaveDetailformGroup);
            //this.leaveRequestForm.controls.leaveRequestDetailDTOs.setValue(res.leaveRequestDetailDTOs);
        }
    }
}