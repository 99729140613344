import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LeaveRequestModel, LeaveRequestBuilder } from '../../shared/models/leave-request-model';
import { LeaveActionModel, LeaveActionBuilder, MissingAttendance } from '../../shared/models/leave-action-model';

import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { LeaveRequestService } from '../../core/services/leave-request.service';
import { Validators } from '@angular/forms';
import { MessageService } from '../../shared/factory/message.service';
import { fetchCachedData } from '../../shared/factory/data-caching.service';
import { Location } from '@angular/common';
import { LeaveTypeService } from '../../core/services/leave-type.service';
import { LeaveTypeModel } from '../../shared/models/leave-type-model';
import { UserModel } from '../../shared/models/user-model';
import { UserService } from '../../core/services/user.service';
import { dateTimeFormatters } from '../../shared/factory/common.service';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { LeaveRequestDetailModel, LeaveRequestDetailModelFormBuilder } from '../../shared/models/leave-request-detail-dtos-model';
import { LoaderService } from 'src/app/shared/factory/loader.service';
import { HolidayService } from 'src/app/core/services/holiday.service';
import { HolidayFormBuilder, HolidayModel } from 'src/app/shared/models/holiday-model';
import { OrganizationModel } from 'src/app/shared/models/organization-model';
import { OrganizationService } from 'src/app/core/services/organization.service';
import { FinancialYearService } from 'src/app/core/services/financial-year-service.service';
import { FinancialYearModel } from 'src/app/shared/models/financial-year-model';

@Component({
  selector: 'app-apply-leave',
  templateUrl: './apply-leave.component.html',
  styleUrls: ['./apply-leave.component.css']
})
export class ApplyLeaveComponent implements OnInit {

    holidayAddForm: HolidayFormBuilder = new HolidayFormBuilder();
  holidayModel: HolidayModel = {
    id: null,
    name: '',
    date: null,
    financialYearId: null
  };
  financialYears = [];
  dateTimeFormatters = dateTimeFormatters;

  //leaveRequestDetailDTO: LeaveRequestDetailDTO = new LeaveRequestDetailDTO();
  leaveActionReq: LeaveActionBuilder = new LeaveActionBuilder();
  leaveActionModel: LeaveActionModel = this.leaveActionReq.leaveActionModel;
  leaveActionRequestForm: FormGroup = this.leaveActionReq.leaveActionRequestForm;

  dateFilter = (date: Date) => date.getDay() == 1 || date.getDay() == 2 || date.getDay() == 3 || date.getDay() == 4 || date.getDay() == 5;
  // dateTimeFormatters = dateTimeFormatters;
  leaveTypes = [];
  approvers = [];
  get leaveDetailFormGroup() {
    return this.leaveActionRequestForm.get('leaveRequestDetailDTOs') as FormArray;
  }
  rowData: any;
  isMissingAttendanceMode = false;
  holidays: HolidayModel[];

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private leaveRequestService: LeaveRequestService,
    private leaveTypeService: LeaveTypeService,
    private userService: UserService,
    private messageService: MessageService,
    public location: Location,
    private holidayService: HolidayService,
    private financialYearService: FinancialYearService,
    private organizationService: OrganizationService,
    private loaderService: LoaderService) {

    this.route.paramMap.subscribe((params) => {
      let state = this.router.getCurrentNavigation()?.extras?.state;
      if (state) {
        this.rowData = state.data;
        if (state.isMissingAttendance) {
          this.isMissingAttendanceMode = true;
        }
      }
    });
  }

  ngOnInit() {
    this.leaveTypeService.fetchLeaveType().subscribe((res: LeaveTypeModel[]) => {
      //this.leaveRequestModel.leaveTypeId = res[0].id;
      this.leaveTypes = res;
    })
    this.userService.fetchLeaveApproversEmployeesLite().subscribe((res: UserModel[]) => {
      this.approvers = res;
    });
    this.fetchFinancialYear();
    var currentFinancialYearInstanceId = '';
    this.organizationService.fetchOrganization().subscribe((res: OrganizationModel[]) => {
      this.holidayModel.financialYearId = res[0].currentFinancialYearInstanceId;
      this.holidayService.fetchHolidays(this.holidayModel.financialYearId).subscribe((res: HolidayModel[]) => {
        this.holidays = res;
      });
    });


    if (this.isMissingAttendanceMode) {
      this.leaveActionRequestForm.patchValue({
        fromDate: new Date(this.rowData.actionDate),
        toDate: new Date(this.rowData.actionDate),
      });
      this.dateRangeUpdated()
    }
  }
  fetchFinancialYear() {
    this.financialYearService.fetchFinancialYears().subscribe((res: FinancialYearModel[]) => {
      this.financialYears = res;
    });
  }
 // Set the date filter to disable weekdays and non-holidays
   dateFilterForPreviousDaySelect = (date: Date | null): boolean => {
    if (!date) {
      return false;
    }
  
    const day = date.getDay();
    const isWeekday = day >= 1 && day <= 5;
    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
    const isHoliday = this.holidays?.some(holiday => holiday.date === formattedDate);
    const startDate = this.leaveActionRequestForm.controls.fromDate.value;
  
    return isWeekday && !isHoliday && date >= startDate;
  };
  dateRangeUpdated() {
    let leaveRequestDetailArray = this.leaveActionRequestForm.get('leaveRequestDetailDTOs') as FormArray;
  
    var startDate = this.leaveActionRequestForm.controls.fromDate.value;
    var endDate = this.leaveActionRequestForm.controls.toDate.value;
  
    if (startDate || endDate) {
      while (leaveRequestDetailArray.length !== 0) {
        leaveRequestDetailArray.removeAt(0)
      }
    }
  
    var dt = new Date(startDate);
    while (dt <= endDate) {
      if (dt.getDay() !== 0 && dt.getDay() !== 6) {
        const formattedDate = `${dt.getFullYear()}-${(dt.getMonth() + 1).toString().padStart(2, '0')}-${dt.getDate().toString().padStart(2, '0')}`;
        const isHoliday = this.holidays?.some(holiday => holiday.date === formattedDate);
        if (!isHoliday) {
          let leaveDetailformGroup = new FormGroup({
            leaveDate: new FormControl(this.dateTimeFormatters.formatDate(dt)),
            leaveRequestType: new FormControl('FULLDAY')
          });
          leaveRequestDetailArray.push(leaveDetailformGroup);
        }
      }
      dt.setDate(dt.getDate() + 1);
    }
  }
  
  addUpdateleaveRequest() {
    this.leaveActionModel.reasonForLeave = this.leaveActionRequestForm.controls.reasonForLeave.value;
    this.leaveActionModel.leaveTypeId = this.leaveActionRequestForm.controls.leaveTypeId.value;
    this.leaveActionModel.teamNotificationEmail = this.leaveActionRequestForm.controls.teamNotificationEmail.value;
    //this.leaveRequestModel.leaveRequestState = this.leaveRequestForm.controls.leaveRequestState.value;
    this.leaveActionModel.leaveRequestApprovers = this.leaveActionRequestForm.controls.leaveRequestApprovers.value;
    this.leaveActionModel.totalLeaveCount = this.leaveActionRequestForm.controls.totalLeaveCount.value;
    this.leaveActionModel.leaveRequestDetailDTOs = this.leaveActionRequestForm.controls.leaveRequestDetailDTOs.value;
   
    if (this.isMissingAttendanceMode) {
      this.leaveActionModel.missingAttendance = MissingAttendance.MISSINGATTENDANCE;
    } 

    if (this.leaveActionModel.id) {
      this.leaveRequestService.updateLeaveRequest(this.leaveActionModel).subscribe((res) => {
        this.messageService.display('LeaveRequest update is successfull! Leave ID : ' + this.leaveActionModel.id, 'OK', 'success');
        this.loaderService.updateInboxAction(true);
        this.router.navigate(['/leaveRequest']);
      });
    } else {
      this.leaveRequestService.addLeaveRequest(this.leaveActionModel).subscribe((res) => {
        this.messageService.display('LeaveRequest add is successfull! Leave ID : ' + res, 'OK', 'success');
        this.loaderService.updateInboxAction(true);
        this.router.navigate(['/leaveRequest']);
      });
    }
  }
}


