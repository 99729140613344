import { FormControl, FormGroup } from "@angular/forms";
import { AttendanceActionRequestComponent } from "../popups/attendance-popups";
import { th } from "date-fns/locale";

export interface MissingAttendanceActionModel {

    id: number
    employee: number;
    employeeCode: string;
    employeeName: string;
    actionDate: Date;
    leaveDate: Date;
    wfhDate: Date;
    holiday: Date;
    missingAttendanceStatus: string;
}

export class MissingAttendanceActionBuilder {

    MissingAttendanceActionModel: MissingAttendanceActionModel = {
        id: null,
        employee: null,
        employeeCode: '',
        employeeName: '',
        actionDate: null,
        leaveDate: undefined,
        wfhDate: undefined,
        holiday: undefined,
        missingAttendanceStatus: ""
    };

    missingAttendanceActionForm: FormGroup = new FormGroup({
        id: new FormControl(this.MissingAttendanceActionModel.id),
        employee: new FormControl(this.MissingAttendanceActionModel.employee),
        employeeCode: new FormControl(this.MissingAttendanceActionModel.employeeCode),
        employeeName: new FormControl(this.MissingAttendanceActionModel.employeeName),
        actionDate: new FormControl(this.MissingAttendanceActionModel.actionDate),
        leaveDate: new FormControl(this.MissingAttendanceActionModel.leaveDate),
        wfgDate: new FormControl(this.MissingAttendanceActionModel.wfhDate),
        holiday: new FormControl(this.MissingAttendanceActionModel.holiday),
        missingActionStatus: new FormControl(this.MissingAttendanceActionModel.missingAttendanceStatus),
    })

    constructor() {
        this.missingAttendanceActionForm.valueChanges.subscribe(val => {
            this.MissingAttendanceActionModel.id = val.id;
            this.MissingAttendanceActionModel.employee = val.employee;
            this.MissingAttendanceActionModel.employeeCode = val.employeeCode;
            this.MissingAttendanceActionModel.employeeName = val.employeeName;
            this.MissingAttendanceActionModel.actionDate = val.actionDate;
            this.MissingAttendanceActionModel.leaveDate = val.leaveDate;
            this.MissingAttendanceActionModel.wfhDate = val.wfgDate;
            this.MissingAttendanceActionModel.holiday = val.holiday;
            this.MissingAttendanceActionModel.missingAttendanceStatus = val.missingActionStatus;
        })
    }

    setModelVals(res: MissingAttendanceActionModel) {
        this.missingAttendanceActionForm.controls.id.setValue(res.id);
        this.missingAttendanceActionForm.controls.employee.setValue(res.employee);
        this.missingAttendanceActionForm.controls.employeeCode.setValue(res.employeeCode);
        this.missingAttendanceActionForm.controls.employeeName.setValue(res.employeeName);
        this.missingAttendanceActionForm.controls.actionDate.setValue(res.actionDate);
        this.missingAttendanceActionForm.controls.leaveDate.setValue(res.leaveDate);
        this.missingAttendanceActionForm.controls.wfgDate.setValue(res.wfhDate);
        this.missingAttendanceActionForm.controls.holiday.setValue(res.holiday);
        this.missingAttendanceActionForm.controls.missingActionStatus.setValue(res.missingAttendanceStatus);
    }
}