import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { HelpService } from 'src/app/core/services/help.service';
import { LeaveRequestService } from 'src/app/core/services/leave-request.service';
import { LeaveTypeService } from 'src/app/core/services/leave-type.service';
import { EmployeeLeaveStatModel,EmployeeLeaveStatFormBuilder } from 'src/app/shared/models/employee-leave-stat-model';
import { LeaveAdjustModel } from 'src/app/shared/models/leave-adjust-model';
import { AddLeaveAdjustRequestComponent } from 'src/app/shared/popups/popups';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-leave-adjust',
  templateUrl: './leave-adjust.component.html',
  styleUrls: ['./leave-adjust.component.css']
})
export class LeaveAdjustComponent implements OnInit {
 

  leaveBalance = [];
  leaveTotal: number = 0;
  displayedColumns = ['id', 'employeeDTOLite.firstName', 'leaveTypeName', 'leaveCount', 'action'];
  dataSource: MatTableDataSource<EmployeeLeaveStatModel>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  loaderService: any;
  param1: null;

  sortingDataAccessor(item, property) {
    if (property.includes('.')) {
      return property.split('.')
        .reduce((object, key) => object[key], item);
    }
    return item[property];
  }


  constructor(
    private leaveTypeService: LeaveTypeService,
    private helpService: HelpService,
    private leaveRequestService: LeaveRequestService,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.fetchAllEmployeeLeaveBalance();
    
    if(this.param1 != null){
      this.openLeaveAdjustDialog(this.param1);
     }
     this.dataSource = new MatTableDataSource<EmployeeLeaveStatModel>([]);
     this.dataSource.paginator = this.paginator;
  }

  fetchAllEmployeeLeaveBalance() {
    this.leaveRequestService.fetchAllEmployeeLeaveBalance().subscribe((res: EmployeeLeaveStatModel[]) => {
     // this.leaveBalance = res;
     this.dataSource = new MatTableDataSource<EmployeeLeaveStatModel>(res);
     this.dataSource.paginator = this.paginator;
     this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
     this.dataSource.sort = this.sort;
      var total = 0;
      this.leaveBalance.forEach(function (value) {
        total += value.leaveCount;
      })
      this.leaveTotal = total;
    });
  }
  openLeaveAdjustDialog(emplId, leaveRequestState?) {
    console.log(this.dialog,"dialog")
    if (leaveRequestState === 'PENDING_APPROVED_LEAVE_CANCELLATION') {
     const dialogRef = this.dialog.open(AddLeaveAdjustRequestComponent, {
        data: { emplId }
      });
    dialogRef.afterClosed().subscribe(result => {
        if (result == 'applied') {
         this.fetchAllEmployeeLeaveBalance();
         this.loaderService.updateInboxAction(true);
         
        }
      });
    }
    else {
       const dialogRef = this.dialog.open(AddLeaveAdjustRequestComponent, {
        data: { emplId }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result == 'applied') {
         this.fetchAllEmployeeLeaveBalance();
         this.loaderService.updateInboxAction(true);
        }
      });
    }
  }
  
  findHelpUrlPage() {
    const currentPage = "/#_4_4_employee_leave_state";
    window.open(this.helpService.HELP_BASE_URL + currentPage, "_blank");

  }
}
