import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { Validators } from '@angular/forms';
import { MessageService } from '../../shared/factory/message.service';
import { fetchCachedData } from '../../shared/factory/data-caching.service';
import { Location } from '@angular/common';
import { UserModel } from '../../shared/models/user-model';
import { UserService } from '../../core/services/user.service';
import { dateTimeFormatters } from '../../shared/factory/common.service';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { LoaderService } from 'src/app/shared/factory/loader.service';
import { AttendanceApplyRequestBuilder, AttendanceApplyRequestModel } from 'src/app/shared/models/attendance-apply-request-model';
import { AttendanceRequestService } from 'src/app/core/services/attendance-regularisation-request.service';
import { AttendanceService } from 'src/app/core/services/attendance.service';
import { MatTableDataSource } from '@angular/material/table';
import { MissingAttendance } from 'src/app/shared/models/leave-action-model';

@Component({
  selector: 'app-apply-attendance-regularisation-request',
  templateUrl: './apply-attendance-regularisation-request.component.html',
  styleUrls: ['./apply-attendance-regularisation-request.component.css']
})
export class ApplyAttendanceRegularisationRequestComponent implements OnInit {

  attendanceApplyReq: AttendanceApplyRequestBuilder = new AttendanceApplyRequestBuilder();
  attendanceApplyModel: AttendanceApplyRequestModel = this.attendanceApplyReq.AttendanceApplyRequestModel;
  attendanceApplyRequestForm: FormGroup = this.attendanceApplyReq.attendanceApplyRequestForm;

  dateFilter = (date: Date) => date.getDay() == 1 || date.getDay() == 2 || date.getDay() == 3 || date.getDay() == 4 || date.getDay() == 5;
  dateTimeFormatters = dateTimeFormatters;
  approvers = [];
  selectedApprovers = this.approvers[1];
  isEdit: boolean = false;
  maxDate;
  dataSource = new MatTableDataSource<AttendanceApplyRequestModel>();
  oldAttendanceFromDate;
  attendanceFromDate;
  id;
  currentEntryTime;
  currentExitTime;
  rowData: any;
  isMissingAttendanceMode = false;

  constructor(
    public location: Location,
    private attendanceRequestService: AttendanceRequestService,
    private attendanceService: AttendanceService,
    private userService: UserService,
    private messageService: MessageService,
    private loaderService: LoaderService,
    private route: ActivatedRoute,
    public router: Router
  ) {
    this.route.paramMap.subscribe((params) => {
      let state = this.router.getCurrentNavigation()?.extras?.state;
      if (state) {
        this.rowData = state.data;
        if (state.isMissingAttendance) {
          this.isMissingAttendanceMode = true;
        }
      }
    });
   }

  get attendanceDetailFormGroup(){
    return this.attendanceApplyRequestForm.get('attendanceRequestDetailDTOs')as FormArray;
  }
  ngOnInit(): void {
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate() - 1); 
    this.userService.fetchAttendanceRequestApproversLite().subscribe((res: UserModel[]) => {
      this.approvers = res;
    });

    if (this.route.snapshot.params['new'] !== 'new') {
      this.isEdit = true;
      this.attendanceService.attendanceFieldsDetails.subscribe((attendanceApplyRequestModel: any) => {
        this.currentEntryTime = attendanceApplyRequestModel.entryTime.split(":");
        this.currentEntryTime = this.currentEntryTime[0] + ":" + this.currentEntryTime[1];
        this.currentExitTime = attendanceApplyRequestModel.exitTime.split(":");
        this.currentExitTime = this.currentExitTime[0] + ":" + this.currentExitTime[1];
        this.attendanceApplyRequestForm.patchValue({
          "id": attendanceApplyRequestModel.id,
          "attendanceFromDate": (new Date(attendanceApplyRequestModel.date)).toISOString(),
          "entryTime": this.currentEntryTime,
          "exitTime": this.currentExitTime
        })
      });
    }

    if (this.isMissingAttendanceMode) {
      this.isEdit = false;
      this.attendanceApplyRequestForm.patchValue({
        "attendanceFromDate": (new Date(this.rowData.actionDate)).toISOString(),
      });
    }
  }

  addUpdateAttendanceRequest() {

    if (this.isMissingAttendanceMode) {
      this.attendanceApplyModel.missingAttendance = MissingAttendance.MISSINGATTENDANCE;
    }


    if (this.attendanceApplyModel.id) {
      this.attendanceRequestService.updateAttendanceRequest(this.attendanceApplyModel).subscribe((res) => {
        this.messageService.display('Attendance request updated successful! attendance ID: ' + this.attendanceApplyModel.id, 'ok', 'success');
        this.loaderService.updateInboxAction(true);
        this.router.navigate(['/myAttendance']);
      });
    } else {
      this.attendanceRequestService.addAttendanceRequest(this.attendanceApplyModel).subscribe((res) => {
        this.messageService.display('Attendance request is created successfully! attendance ID: ' + res, 'ok', 'success');
        this.loaderService.updateInboxAction(true);
        this.router.navigate(['/myAttendance']);
      })
    }
  }

  onChangeHour(event){
    console.log(event);
  }
}