import { FormGroup, FormControl, Validators } from '@angular/forms';
export interface OrganizationModel {
    id: number;
    name: string;
    code: string;
    website: string;
    domain: string;
    description: string;
    incorporationDate: any;
    baseContact: {
        primaryPhoneNumber: string;
        secondaryPhoneNumber: any;
        email: string;
    };
    address: {
        address1: string;
        address2: string;
        country: string;
        state: string;
        city: string;
        zipCode: string;
    };
    organizationType: string;
    timezone: any;
    financialYearConfigId: number;
    currentFinancialYearInstanceId: number;
}
export class OrganizationFormBuilder {
    organizationModel: OrganizationModel = {
        id: 1, // default
        name: '',
        code: '',
        website: '',
        domain: '',
        description: '',
        incorporationDate: null,
        baseContact: {
            primaryPhoneNumber: '',
            secondaryPhoneNumber: null,
            email: ''
        },
        address: {
            address1: '',
            address2: '',
            country: '',
            state: '',
            city: '',
            zipCode: ''
        },
        organizationType: 'ORGANIZATION',
        timezone: null,
        financialYearConfigId: null,
        currentFinancialYearInstanceId: null
    };
    organizationForm: FormGroup = new FormGroup({
        name: new FormControl(this.organizationModel.name, [Validators.required]),
        code: new FormControl(this.organizationModel.code),
        website: new FormControl(this.organizationModel.website),
        domain: new FormControl(this.organizationModel.domain),
        description: new FormControl(this.organizationModel.description),
        incorporationDate: new FormControl(this.organizationModel.incorporationDate ? new Date(this.organizationModel.incorporationDate) : this.organizationModel.incorporationDate),
        primaryPhoneNumber: new FormControl(this.organizationModel.baseContact.primaryPhoneNumber),
        secondaryPhoneNumber: new FormControl(this.organizationModel.baseContact.secondaryPhoneNumber),
        email: new FormControl(this.organizationModel.baseContact.email),
        address1: new FormControl(this.organizationModel.address.address1),
        address2: new FormControl(this.organizationModel.address.address2),
        country: new FormControl(this.organizationModel.address.country),
        state: new FormControl(this.organizationModel.address.state),
        city: new FormControl(this.organizationModel.address.city),
        zipCode: new FormControl(this.organizationModel.address.zipCode),
        timezone: new FormControl(this.organizationModel.timezone),
        financialYearConfigId: new FormControl(this.organizationModel.financialYearConfigId),
        currentFinancialYearInstanceId: new FormControl(this.organizationModel.currentFinancialYearInstanceId)
    });
    constructor() {
        this.organizationForm.valueChanges.subscribe(val => {
            this.organizationModel.name = val.name;
            this.organizationModel.code = val.code;
            this.organizationModel.website = val.website;
            this.organizationModel.domain = val.domain;
            this.organizationModel.description = val.description;
            this.organizationModel.incorporationDate = val.incorporationDate;
            this.organizationModel.timezone = val.timezone;
            this.organizationModel.baseContact.primaryPhoneNumber = val.primaryPhoneNumber;
            this.organizationModel.baseContact.secondaryPhoneNumber = val.secondaryPhoneNumber;
            this.organizationModel.baseContact.email = val.email;
            this.organizationModel.address.address1 = val.address1;
            this.organizationModel.address.address2 = val.address2;
            this.organizationModel.address.city = val.city;
            this.organizationModel.address.country = val.country;
            this.organizationModel.address.state = val.state;
            this.organizationModel.address.zipCode = val.zipCode;
            this.organizationModel.financialYearConfigId = val.financialYearConfigId;
            this.organizationModel.currentFinancialYearInstanceId = val.currentFinancialYearInstanceId;
        });
    }
    setModelVals(res: OrganizationModel[]) {
        this.organizationForm.controls.name.setValue(res[0].name);
        this.organizationForm.controls.code.setValue(res[0].code);
        this.organizationForm.controls.website.setValue(res[0].website);
        this.organizationForm.controls.domain.setValue(res[0].domain);
        this.organizationForm.controls.description.setValue(res[0].description);
        this.organizationForm.controls.incorporationDate.setValue(res[0].incorporationDate);
        this.organizationForm.controls.primaryPhoneNumber.setValue(res[0].baseContact.primaryPhoneNumber);
        this.organizationForm.controls.secondaryPhoneNumber.setValue(res[0].baseContact.secondaryPhoneNumber);
        this.organizationForm.controls.email.setValue(res[0].baseContact.email);
        this.organizationForm.controls.address1.setValue(res[0].address.address1);
        this.organizationForm.controls.address2.setValue(res[0].address.address2);
        this.organizationForm.controls.country.setValue(res[0].address.country);
        this.organizationForm.controls.state.setValue(res[0].address.state);
        this.organizationForm.controls.city.setValue(res[0].address.city);
        this.organizationForm.controls.zipCode.setValue(res[0].address.zipCode);
        this.organizationForm.controls.timezone.setValue(res[0].timezone);
        this.organizationForm.controls.financialYearConfigId.setValue(res[0].financialYearConfigId);
        this.organizationForm.controls.currentFinancialYearInstanceId.setValue(res[0].currentFinancialYearInstanceId);
    }
}