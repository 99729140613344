import { Component, OnInit, ViewChild } from '@angular/core';
import { LeaveRequestApproverService } from '../../core/services/leave-request-approver.service';
import { LeaveRequestModel, LeaveRequestBuilder } from '../../shared/models/leave-request-model';
import { LeaveActionRequestComponent, ApprovedLeaveActionRequestComponent } from '../../shared/popups/popups';
import { LoaderService } from '../../shared/factory/loader.service';
import { UserService } from '../../core/services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { HelpService } from '../../core/services/help.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup } from '@angular/forms';
import { LeaveRequestService } from 'src/app/core/services/leave-request.service';

@Component({
  selector: 'app-leave-action',
  templateUrl: './leave-action.component.html',
  styleUrls: ['./leave-action.component.css']
})
export class LeaveActionComponent implements OnInit {

  leaveRequest: LeaveRequestBuilder = new LeaveRequestBuilder();
  leaveRequestModel: LeaveRequestModel = this.leaveRequest.leaveRequestModel;
  leaveRequestForm: FormGroup = this.leaveRequest.leaveRequestForm;


  displayedColumns = ['id', 'employeeLite', 'reasonForLeave', 'leaveRequestState', 'action'];
  dataSource: MatTableDataSource<LeaveRequestModel>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  employees = [];
  param1: number;

  constructor(
    private userService: UserService,
    private leaveRequestApproverService: LeaveRequestApproverService,
    private helpService: HelpService,
    public router: Router,
    private route: ActivatedRoute,
    private leaveRequestService: LeaveRequestService,
    private dialog: MatDialog,
    private loaderService: LoaderService
  ) {
   // this.param1 = this.route.snapshot.params.param1;

    this.route.queryParams.subscribe(params => {
      this.param1 = params['leaveRequestId'];
     });
   }

  ngOnInit() {
    //this.leaveRequestApproverService.fetchAllPendingLeaveRequest().subscribe((res: LeaveRequestModel[]) => {
    // this.leaveRequestModel.id = res[0].id;
     if(this.param1 != null){
      this.openLeaveActionDialog(this.param1);
     }
     this.dataSource = new MatTableDataSource<LeaveRequestModel>([]);
     this.dataSource.paginator = this.paginator;
  }

  ngAfterViewInit(){
    this.fetchAllPendingLeaveRequest();
    // this.dataSource = new MatTableDataSource<LeaveRequestModel>(res);
    this.paginator.page.subscribe(options => {
     this.fetchAllPendingLeaveRequest();
    });
  }

  fetchAllPendingLeaveRequest() {
    this.leaveRequestApproverService.fetchAllPendingLeaveRequest().subscribe((res: LeaveRequestModel[]) => {
      this.dataSource = new MatTableDataSource<LeaveRequestModel>(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }
  openLeaveActionDialog(leaveRequestId, leaveRequestState?) {
    if (leaveRequestState === 'PENDING_APPROVED_LEAVE_CANCELLATION') {
     const dialogRef = this.dialog.open(ApprovedLeaveActionRequestComponent, {
        data: { leaveRequestId }
      });
    dialogRef.afterClosed().subscribe(result => {
        if (result == 'applied') {
         this.fetchAllPendingLeaveRequest();
         this.loaderService.updateInboxAction(true);
         
        }
      });
    }
    else {
       const dialogRef = this.dialog.open(LeaveActionRequestComponent, {
        data: { leaveRequestId }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result == 'applied') {
         this.fetchAllPendingLeaveRequest();
         this.loaderService.updateInboxAction(true);
        }
      });
    }
  }

  findHelpUrlPage() {
    const currentPage = "/#_3_1_leave_action";
    window.open(this.helpService.HELP_BASE_URL + currentPage, "_blank");

  }
}
