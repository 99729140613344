import { Injectable } from '@angular/core';
import { HttpRequestService } from '../http/http-request.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { AttendanceRequestActionModel } from 'src/app/shared/models/attendance-request-action-model';

@Injectable({
  providedIn: 'root'
})
export class AttendanceService {

  private stateUrl = 'api/v1';
  public attendanceFieldsDetails = new BehaviorSubject<any>(null);

  constructor(private httpRequest: HttpRequestService) { }

  fetchAllAttendance(pageNo: number, pageSize: number, curDate: String, lastDate: String): Observable<any> {
    return this.httpRequest.get(this.stateUrl
      + '/employee-attendance'
      + '?page=' + pageNo
      + '&size=' + pageSize
      + '&fromDate=' + lastDate
      + '&toDate=' + curDate);
  }

  fetchAllEntryAttendanceLog(date:String): Observable<any>{
    return this.httpRequest.get(this.stateUrl + '/employee-attendance/attendance-entry-logs?currentDate='+date);
  }

  routeToSectionFieldsIds(attendanceRequestActionModel) {
    this.attendanceFieldsDetails.next(attendanceRequestActionModel);
  }
}
