import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpRequestService } from '../http/http-request.service';
import { CarryForwardDetailModel } from 'src/app/shared/models/carry-forward-detail-model';
import { CarryForwardActionRequestModel } from 'src/app/shared/models/carry-forward-request-action-model';


@Injectable({
  providedIn: 'root'
})
export class CarryForwardDetailService {

  private stateUrl = 'api/v1/carry-forward-request-detail';

  constructor(
    private httpRequest: HttpRequestService
  ) { }

  approvalAction(carryForwardActionRequestModel:CarryForwardActionRequestModel): Observable<any> {
    return this.httpRequest.put(this.stateUrl,carryForwardActionRequestModel);
  }
  
}
