
import { Injectable } from '@angular/core';
import { HttpRequestService } from '../http/http-request.service';
import { Observable } from 'rxjs';
import { LeaveTypeModel } from '../../shared/models/leave-type-model';

@Injectable({
  providedIn: 'root'
})
export class LeaveTypeService {
  private stateUrl = 'api/v1/leave-type';

  constructor(
    private httpRequest: HttpRequestService
  ) { }

  fetchLeaveType(): Observable<LeaveTypeModel[]> {
    return this.httpRequest.get(this.stateUrl + '/');
  }

  
  updateLeaveType(leaveTypeModel: LeaveTypeModel): Observable<any> {
    return this.httpRequest.put(this.stateUrl, leaveTypeModel);
  }

  addLeaveType(leaveTypeModel: LeaveTypeModel): Observable<any> {
    return this.httpRequest.post(this.stateUrl, leaveTypeModel);
  }
}
