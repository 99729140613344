import { Injectable } from '@angular/core';
import { HttpRequestService } from '../http/http-request.service';
import { Observable } from 'rxjs';
import { LeaveRequestModel } from '../../shared/models/leave-request-model';
import { LeaveRequestActionModel } from '../../shared/models/leave-request-action-model';
import { HistoricalLeaveRequestModel } from 'src/app/shared/models/historical-leave-request-model';


@Injectable({
  providedIn: 'root'
})
export class LeaveRequestApproverService {
  private stateUrl = 'api/v1/action/leave-request';

  constructor(
    private httpRequest: HttpRequestService
  ) { }

  fetchAllPendingLeaveRequest(): Observable<LeaveRequestModel[]> {
    return this.httpRequest.get(this.stateUrl + '/');
  }

  approveLeave(leaveRequestActionModel: LeaveRequestActionModel): Observable<any> {
    return this.httpRequest.put(this.stateUrl + '/approved/', leaveRequestActionModel);
  }
 
  rejectLeave(leaveRequestActionModel: LeaveRequestActionModel): Observable<any> {
    return this.httpRequest.put(this.stateUrl + '/rejected/', leaveRequestActionModel);
  }
  fetchHistoricalLeaveRequest(searchFormFields):  Observable<HistoricalLeaveRequestModel[]> {
    return this.httpRequest.post(this.stateUrl + '/historical-leave-request', searchFormFields);
  }
  approvedLeaveCancelRequestAction(data): Observable<any> {
    return this.httpRequest.post(this.stateUrl + '/approved-leave-cancel-request-action', data);
  }
  fetchChartData():Observable<any>{
    return this.httpRequest.get(this.stateUrl + '/chart');
  }

}
