import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpRequestService } from '..';
import { AttendanceRequestActionModel } from 'src/app/shared/models/attendance-request-action-model';

@Injectable({
  providedIn: 'root'
})
export class AttendanceRequestApproverService {
  private stateUrl = 'api/v1/action/attendance-request';

  constructor(
    private httpRequest: HttpRequestService
  ) { }

  fetchAllPendingAttendanceRequest(): Observable<AttendanceRequestActionModel[]> {
    return this.httpRequest.get(this.stateUrl + '/');
  }

  fetchAllPendingAttendanceRegulationUpdateRequest(): Observable<AttendanceRequestActionModel[]> {
    return this.httpRequest.get(this.stateUrl + '/attendance-update-request');
  }

  approveAttendance(attendanceRequestActionModel: AttendanceRequestActionModel): Observable<any> {
    return this.httpRequest.put(this.stateUrl + '/approved', attendanceRequestActionModel);
  }

  approveUpdateAttendance(attendanceRequestActionModel: AttendanceRequestActionModel): Observable<any> {
    return this.httpRequest.put(this.stateUrl + '/approved-update-request', attendanceRequestActionModel);
  }
  
  rejectAttendance(attendanceRequestActionModel: AttendanceRequestActionModel): Observable<any> {
    return this.httpRequest.put(this.stateUrl + '/rejected', attendanceRequestActionModel);
  }

  rejectUpdateAttendance(attendanceRequestActionModel: AttendanceRequestActionModel): Observable<any> {
    return this.httpRequest.put(this.stateUrl + '/rejected-update-request', attendanceRequestActionModel);
  }

  approvedAttendanceCancelRequestAction(data): Observable<any> {
    return this.httpRequest.put(this.stateUrl + '/approved-attendance-cancel-request-action', data);
  }
  rejectAttendanceCancelRequestAction(data): Observable<any> {
    return this.httpRequest.put(this.stateUrl + '/rejected-attendance-cancel-request-action', data);
  }
  fetchChartData():Observable<any>{
    return this.httpRequest.get(this.stateUrl + '/chart');
  }
}
