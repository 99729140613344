import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { ok } from "assert";
import { UserService } from "src/app/core/services/user.service";

import { CommonService } from "../factory/common.service";
import { LoaderService } from "../factory/loader.service";
import { MessageService } from "../factory/message.service";
import { CancelApprovedLeaveRequestActionBuilder } from "../models/leave-request-action-model";
import { UserModel } from "../models/user-model";
import { CancelApprovedAttendanceRequestActionBuilder, CancelApprovedAttendanceRequestActionModel, AttendanceRequestActionBuilder, AttendanceRequestActionModel } from "../models/attendance-request-action-model";
import { AttendanceRequestBuilder, AttendanceRequestModel } from "../models/attendance-request-model";
import { AttendanceRequestService } from "src/app/core/services/attendance-regularisation-request.service";
import { AttendanceRequestApproverService } from "src/app/core/services/attendance-regularisation-request-approver.service";

/* #1 */
/*Begin Cancel Approved Attendance Request */
@Component({
  selector: 'app-approved-leave-action-dialog',
  template: `
  <form [formGroup] = "cancelAttendanceRequestForm">
  <div mat-dialog-content>
    <p>Do you want to cancel Attendance request?</p>
    <mat-form-field class="w-100">
      <mat-label>Reason for cancel Attendance request</mat-label>
      <input matInput placeholder="Reason"  formControlName="reasonForAttendanceRequestCancel">
      <mat-error *ngIf="cancelAttendanceRequestForm.controls.approverActionComment.invalid">Reason for Attendance request cancel is required!</mat-error>
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button cdkFocusInitial (click)="onOkClick()">Ok</button>
  </div>
  </form>`
})
export class CancelAttendanceApprovedComponent implements OnInit {
  encapsulation: ViewEncapsulation.ShadowDom
  reasonForCancel: string;
  cancelAttendanceRequestBuilder: CancelApprovedAttendanceRequestActionBuilder = new CancelApprovedAttendanceRequestActionBuilder();
  cancelAttendanceRequestModel: CancelApprovedAttendanceRequestActionModel = this.cancelAttendanceRequestBuilder.CancelApprovedAttendanceRequestActionModel;
  cancelAttendanceRequestForm: FormGroup = this.cancelAttendanceRequestBuilder.CancelApprovedAttendanceRequestActionForm;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private attendanceRequestService: AttendanceRequestService,
    public router: Router,
    public commonServices: CommonService,
    public messageService: MessageService,
    public dialogRef: MatDialogRef<CancelAttendanceApprovedComponent>,
    private loaderService: LoaderService,
  ) { }

  ngOnInit() { }


  onNoClick(): void {
    this.dialogRef.close();
  }

  onOkClick() {
    this.cancelAttendanceRequestModel.attendanceRequestId = this.data.attendanceRequestId;
    // this.cancelAttendanceRequestModel.attendanceRequestStatus = this.data.attendanceRequestStatus;
    this.attendanceRequestService.cancelApprovedAttendanceRequest(this.cancelAttendanceRequestModel).subscribe(() => {
      this.messageService.display('You have successfully applied for Attendance request cancellation!  ', 'OK', 'success');
      this.dialogRef.close('applied');
      this.router.navigate(['/attendanceRequest']);
    }, err => {
      this.messageService.display(err.error.errors.AttendanceRequestApproveValidation[0].message, 'OK', 'error');
    });
    this.dialogRef.close();
  }
}
/*End Cancel Approved Attendance */


/* 3 */
/* Begin Approved Leave Action */
@Component({
  selector: 'app-approved-attendance-action-dialog',
  template: `<div [formGroup]="attendanceActionForm"><h1 mat-dialog-title align="center"> Action on cancel approved Attendance request</h1>
     <div mat-dialog-content>
       <hr/>
    <div class="col-sm" formGroupName="employeeDTOLite">
    <div class="row">
    <div class="col-sm">
            <mat-label>Employee Name</mat-label>
            </div>
            <div class="col-sm">
          
            <input matInput readonly formControlName="fullName">
            </div>
       </div>
    </div>
    <hr/>  
  
        <div class="col-sm">
          <div class="row">
              <div class="col-sm">
                    <div *ngIf="attendanceActionForm.controls.attendanceRequestStatus.value==='PENDING_APPROVED_ATTENDANCE_CANCELLATION'">
                      <mat-label>Reason for cancel</mat-label>
                    </div>
                    <div *ngIf="attendanceActionForm.controls.attendanceRequestStatus.value==='PENDING_APPROVAL'">
                      <mat-label>Reason for cancel</mat-label>
                    </div>
              </div>
                  <div class="col-sm" *ngIf="attendanceActionForm.controls.attendanceRequestStatus.value==='PENDING_APPROVED_ATTENDANCE_CANCELLATION'">  
                    <mat-label>{{attendanceActionForm.controls.attendanceCancelReason.value}}</mat-label>
                  </div>
                  <div class="col-sm"  *ngIf="attendanceActionForm.controls.attendanceRequestStatus.value==='PENDING_APPROVAL' ">
                      <mat-label>{{attendanceActionForm.controls.reasonForAttendanceRequest.value}}</mat-label>
                  </div>
          </div>
        </div>
        <hr/>
        <div class="col-sm">
          <div class="row">
            <div class="col-sm">
                <mat-label>Requested Date</mat-label>
              </div>
              <div class="col-sm"> 
                  <mat-label>{{attendanceActionForm.controls.attendanceRequestedDate.value}}</mat-label>
              </div>
            </div>
        </div>
        <hr/>
        <div class="col-sm">
          <div class="row">
              <div class="col-sm">
                <mat-label>Attendance Requested Date</mat-label>
              </div>
              <div class="col-sm"> 
                  <mat-label style="margin-right: 5px;">{{attendanceActionForm.controls.attendanceFromDate.value}}</mat-label>
              </div>
            </div>
        </div>
        <hr/>
  
  <div class="col-sm mt-3">
    <p>
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Select Users</mat-label>
        <mat-select formControlName="attendanceRequestApprovers" multiple required [(value)]="selectedApprovers">
              <mat-option *ngFor="let user of approvers;"[value]="user.emailOrUsername">
                {{user.name}}
              </mat-option>
          </mat-select>
        <mat-hint>Select users to whom you want to share email notification in case of Attendance request approved</mat-hint>
    </mat-form-field>
    </p>
  </div>
  
    <div class="col-sm">
        <p>
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Approver Comment</mat-label>
                <input formControlName="approverActionComment" maxlength="40" minlength="8" matInput
                    placeholder="Enter Approver Comment" required>
                <mat-hint>Enter comment</mat-hint>
                <mat-error *ngIf="attendanceActionForm.controls.approverActionComment.invalid">Approver comment is required!</mat-error>
            </mat-form-field>
        </p>
    </div>
  
  </div>
  <div class="text-right">
  
    <button  type="submit" mat-raised-button color="primary" class="mat-control w-50 mt-2" (click)="approveAction()" [disabled]="attendanceActionForm.controls.approverActionComment.invalid">Approve</button>
    <button type="submit"  mat-raised-button color="primary" class="mat-control w-50 mt-2" (click)="rejectAction()" [disabled]="attendanceActionForm.controls.approverActionComment.invalid">Reject</button>
    <button mat-raised-button mat-dialog-close class="w-100 mt-2" style="margin-bottom: 5px;">Cancel</button>
  </div>
      </div>`
})
export class ApprovedAttendanceActionRequestComponent implements OnInit {

  attendanceRequest: AttendanceRequestBuilder = new AttendanceRequestBuilder();
  attendanceRequestModel: AttendanceRequestModel = this.attendanceRequest.attendanceRequestModel;
  attendanceActionForm: FormGroup = this.attendanceRequest.attendanceRequestForm;

  dataSource: MatTableDataSource<AttendanceRequestModel>;

  attendanceRequestAction: CancelApprovedAttendanceRequestActionBuilder = new CancelApprovedAttendanceRequestActionBuilder();
  attendanceRequestActionModel: CancelApprovedAttendanceRequestActionModel = this.attendanceRequestAction.CancelApprovedAttendanceRequestActionModel;
  attendanceRequestActionForm: FormGroup = this.attendanceRequestAction.CancelApprovedAttendanceRequestActionForm;

  attendanceRequestForm: any;
  dateTimeFormatters: any;
  reasonForAttendanceCancel: string
  userList = [];
  loggedUserId: number;
  attendanceRequestedDate: string;
  approvers = [];
  selectedApprovers = this.approvers[1];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private attendanceRequestService: AttendanceRequestService,
    private attendanceRequestApproverService: AttendanceRequestApproverService,
    public router: Router,
    public commonServices: CommonService,
    public messageService: MessageService,
    public dialogRef: MatDialogRef<ApprovedAttendanceActionRequestComponent>,
    private loaderService: LoaderService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.populateAttendanceRequestDtoData();
  }

  fetchAllUsers(appliedLeaveUserId) {
    const loggedUser = sessionStorage.getItem('loginData');
    this.loggedUserId = JSON.parse(loggedUser).id;
    this.userService.fetchUsers().subscribe((res: UserModel[]) => {
      res.filter(userData => {
        if (userData.id !== this.loggedUserId && userData.id !== appliedLeaveUserId) {
          this.userList.push(userData);
          this.approvers = res;
        }
      });
    });
  }

  populateAttendanceRequestDtoData() {
    this.attendanceRequestService.fetchAttendanceByIdWithDetail(this.data.attendanceRequestId).subscribe((res) => {
      this.fetchAllUsers(res['userId']);
      // this.attendanceRequestedDate = res['attendanceRequestedDate']; 
      this.attendanceRequest.setModelVals(res);
      this.reasonForAttendanceCancel = res['reasonForAttendanceCancel']
    });
  }

  //'APPROVED';
  approveAction() {
    var retVal = confirm("Do you want to approve this Attendance request?");
    if (retVal) {
      this.attendanceRequestActionModel.approverActionComment = this.attendanceRequestModel.approverActionComment;
      this.attendanceRequestActionModel.attendanceRequestId = this.data.attendanceRequestId;
      this.attendanceRequestActionModel.employeeId = this.data.userId;
      this.attendanceRequestActionModel.listOfUserForNotificationReference = this.attendanceRequestModel.attendanceRequestApprovers;
      this.attendanceRequestActionModel.entryTime = this.attendanceRequestModel.entryTime;
      this.attendanceRequestActionModel.listOfUserForNotificationReference = this.attendanceRequestModel.attendanceRequestApprovers;
      this.attendanceRequestActionModel.exitTime = this.attendanceRequestModel.exitTime;
      this.attendanceRequestActionModel.attendanceFromDate = this.attendanceRequestModel.attendanceFromDate;

    
      this.messageService.display("Loding..." ,"OK","success");
      this.attendanceRequestApproverService.approvedAttendanceCancelRequestAction(this.attendanceRequestActionModel).subscribe((res) => {
        this.messageService.display('Cancel Attendance request approved  successfully!', 'OK', 'success');
        this.dialogRef.close('applied');
        this.loaderService.updateInboxAction(true);

      });
    }
    else {
      return false;
    }
  }

  // 'REJECTED'
  rejectAction() {
    var retVal = confirm("Do you want to reject this Attendance request?");
    if (retVal) {
      this.attendanceRequestActionModel.approverActionComment = this.attendanceRequestModel.approverActionComment;
      this.attendanceRequestActionModel.attendanceRequestId = this.data.attendanceRequestId;
      this.attendanceRequestActionModel.employeeId = this.data.userId;
      // this.attendanceRequestActionModel.employeeId = this.attendanceRequestModel.employeeDTOLite.id;
      this.attendanceRequestActionModel.listOfUserForNotificationReference = this.attendanceRequestModel.attendanceRequestApprovers;
      this.attendanceRequestApproverService.rejectAttendanceCancelRequestAction(this.attendanceRequestActionModel).subscribe((res) => {
        this.messageService.display('Cancel Attendance request rejected  successfully!  ', 'OK', 'success');
        this.dialogRef.close('applied');
        this.loaderService.updateInboxAction(true);
      });
    }
    else {
      return false;
    }
  }

}

/* End Approved Leave Action */




/* 4 */
//Attendance Action  Dialog
@Component({
  selector: 'app-attendance-action-dialog',
  template: `<div [formGroup]="attendanceActionForm"><h1 mat-dialog-title align="center"> Attendance request action </h1>
    <div mat-dialog-content>
      <hr/>
    <div class="col-sm" formGroupName="employeeDTOLite">
    <div class="row">
    <div class="col-sm">
            <mat-label>Employee Name</mat-label>
            </div>
            <div class="col-sm">
          
            <input matInput readonly formControlName="fullName">
            </div>
       </div>
    </div>
  
        <hr/>
        <div class="col-sm">
        <div class="row">
        <div class="col-sm">
                <mat-label>Reason</mat-label>
                </div>
                <div class="col-sm">  
                <mat-label>{{attendanceActionForm.controls.reasonForAttendanceRequest.value}}</mat-label>
               
                </div>
                </div>
        </div>
        <hr/>
        <div class="col-sm">
        <div class="row">
        <div class="col-sm">
                <mat-label>Requested Date</mat-label>
                </div>
                <div class="col-sm">  
                <mat-label>{{attendanceActionForm.controls.attendanceRequestedDate.value}}</mat-label>
               
                </div>
                </div>
        </div>
        <hr/>
        <div class="col-sm">
        <div class="row">
        <div class="col-sm">
                <mat-label>Entry Time</mat-label>
                </div>
                <div class="col-sm">  
                <mat-label>{{attendanceActionForm.controls.entryTime.value}}</mat-label>
               
                </div>
                </div>
        </div>  
        <hr/>
        <div class="col-sm">
        <div class="row">
        <div class="col-sm">
                <mat-label>Exit Time</mat-label>
                </div>
                <div class="col-sm">  
                <mat-label>{{attendanceActionForm.controls.exitTime.value}}</mat-label>
               
                </div>
                </div>
        </div>
        <hr/>
        <div class="col-sm">
          <div class="row">
              <div class="col-sm">
                <mat-label>Attendance Requested Date</mat-label>
              </div>
              <div class="col-sm">
                  <mat-label style="margin-right: 5px;">{{attendanceActionForm.controls.attendanceFromDate.value}}</mat-label>
              </div>
            </div>
        </div>
        <hr/>
        <!-- <div class="col-sm">
    <div formArrayName="leaveRequestDetailDTOs">
        <div 
            *ngFor="let leaveRequestDetailDTO of leaveActionForm.get('leaveRequestDetailDTOs').controls; let i = index;">
            <div class="row">
  
                <div class="col-sm">
                    <input type="text" readonly class="form-control-plaintext"
                        value="{{attendanceActionForm.controls.leaveRequestDetailDTOs.controls[i].controls.leaveDate.value}}">
                </div>
  
                <div class="col-sm">
                    <input type="text" readonly class="form-control-plaintext"
                        value="{{attendanceActionForm.controls.leaveRequestDetailDTOs.controls[i].controls.leaveRequestType.value}}">
                </div>
            </div>
        </div>
    </div>
  </div> -->
  
  <div class="col-sm mt-3">
    <p>
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Select Users</mat-label>
        <mat-select formControlName="attendanceRequestApprovers" multiple>
              <mat-option *ngFor="let user of approvers;"[value]="user.emailOrUsername">
                {{user.name}}
              </mat-option>
          </mat-select>
        <mat-hint>Select users to whom you want to share email notification in case of Attendance request approved</mat-hint>
    </mat-form-field>
    </p>
  </div>
  
    <div class="col-sm">
        <p>
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Approver Comment</mat-label>
                <input formControlName="approverActionComment" maxlength="40" minlength="8" matInput
                    placeholder="Enter Approver Comment" required>
                <mat-hint>Enter comment</mat-hint>
                <mat-error *ngIf="attendanceActionForm.controls.approverActionComment.invalid">Approver comment is required!</mat-error>
            </mat-form-field>
        </p>
    </div>
  
  </div>
  <div class="text-right">
  
  <button  type="submit" mat-raised-button color="primary" class="mat-control w-50 mt-2" (click)="ApproveAction()" [disabled]="isActionTaken || attendanceActionForm.controls.approverActionComment.invalid">Approve</button>
  <button type="submit"  mat-raised-button color="primary" class="mat-control w-50 mt-2" (click)="RejectAction()" [disabled]="isActionTaken || attendanceActionForm.controls.approverActionComment.invalid">Reject</button>
    <button mat-raised-button mat-dialog-close class="w-100 mt-2">Cancel</button>
  </div>
      </div>`
})
export class AttendanceActionRequestComponent implements OnInit {

  attendanceRequest: AttendanceRequestBuilder = new AttendanceRequestBuilder();
  attendanceRequestModel: AttendanceRequestModel = this.attendanceRequest.attendanceRequestModel;
  attendanceActionForm: FormGroup = this.attendanceRequest.attendanceRequestForm;

  dataSource: MatTableDataSource<AttendanceRequestModel>;

  attendanceRequestAction: AttendanceRequestActionBuilder = new AttendanceRequestActionBuilder();
  attendanceRequestActionModel: AttendanceRequestActionModel = this.attendanceRequestAction.attendanceRequestActionModel;
  attendanceRequestActionForm: FormGroup = this.attendanceRequestAction.attendanceRequestActionForm;

  attendanceRequestForm: any;
  dateTimeFormatters: any;
  loggedUserId: number;
  attendanceRequestedDate: string;

  approvers = [];
  isActionTaken: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private attendanceRequestService: AttendanceRequestService,
    private attendanceRequestApproverService: AttendanceRequestApproverService,
    public router: Router,
    public commonServices: CommonService,
    public messageService: MessageService,
    public dialogRef: MatDialogRef<AttendanceActionRequestComponent>,
    private loaderService: LoaderService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.populateWFhRequestDto();
  }

  fetchAllUsers(appliedLeaveUserId) {
    const loggedUser = sessionStorage.getItem('loginData');
    this.loggedUserId = JSON.parse(loggedUser).id;
    this.userService.fetchUsers().subscribe((res: UserModel[]) => {
      res.filter(userData => {
        if (userData.id !== this.loggedUserId && userData.id !== appliedLeaveUserId) {
          // this.userList.push(userData);
          this.approvers = res;
        }
      });
    });
  }


  populateWFhRequestDto() {
    this.attendanceRequestService.fetchAttendanceByIdWithDetail(this.data.attendanceRequestId).subscribe((res) => {
      this.fetchAllUsers(res['userId']);
      res.attendanceRequestApprovers = [];
      this.attendanceRequest.setModelVals(res);
      // this.dialogRef.close();
      // this.messageService.display('Boarding process is successfully completed ! Module name : ' + this.attendanceRequestModel.id, 'OK', 'success');
    });
  }


  ApproveAction() {
    console.log('attendanceRequestModel', this.attendanceRequestModel);
    var retVal = confirm("Do you want to Approve Attendance request?");
    if (retVal == true) {
      this.isActionTaken = true; 
      this.attendanceRequestActionModel.approverActionComment = this.attendanceRequestModel.approverActionComment;
      this.attendanceRequestActionModel.attendanceRequestId = this.data.attendanceRequestId;
      this.attendanceRequestActionModel.attendanceRequestStatus = this.attendanceRequestModel.attendanceRequestStatus;
      this.attendanceRequestActionModel.listOfUserForNotificationReference = this.attendanceRequestModel.attendanceRequestApprovers;
      this.attendanceRequestActionModel.attendanceFromDate = this.attendanceRequestModel.attendanceFromDate;
      this.attendanceRequestActionModel.entryTime = this.attendanceRequestModel.entryTime;
      this.attendanceRequestActionModel.exitTime = this.attendanceRequestModel.exitTime;
      this.attendanceRequestActionModel.attendanceType = this.attendanceRequestModel.attendanceType;
      this.attendanceRequestActionModel.attendanceRequestedDate = this.attendanceRequestModel.attendanceRequestedDate;
      this.attendanceRequestActionModel.reasonForAttendanceRequest = this.attendanceRequestModel.reasonForAttendanceRequest;
      this.attendanceRequestActionModel.attendanceSummaryId = this.attendanceRequestModel.attendanceSummaryId;

      this.messageService.display("Loding...", 'ok', 'success');
      if (this.attendanceRequestActionModel.attendanceSummaryId !=null) {
        this.attendanceRequestApproverService.approveUpdateAttendance(this.attendanceRequestActionModel).subscribe((res) => {
          this.messageService.display('Attendance Update request approved  successfully!', 'OK', 'success');
          this.dialogRef.close('applied');
          this.loaderService.updateInboxAction(true);
        });  
      } else {
        this.attendanceRequestApproverService.approveAttendance(this.attendanceRequestActionModel).subscribe((res) => {
          this.messageService.display('Attendance request approved  successfully!', 'OK', 'success');
          this.dialogRef.close('applied');
          this.loaderService.updateInboxAction(true);
        });
      }
    }
    else {
      return false;
    }
  }

  RejectAction() {
    this.isActionTaken = true;
    var retVal = confirm("Do you want to Reject Attendance request?");
    console.log('attendanceRequestModel', this.attendanceRequestModel);
    if (retVal == true) {
      this.attendanceRequestActionModel.approverActionComment = this.attendanceRequestModel.approverActionComment;
      this.attendanceRequestActionModel.attendanceRequestId = this.data.attendanceRequestId;
      this.attendanceRequestActionModel.attendanceRequestStatus = this.attendanceRequestModel.attendanceRequestStatus;
      this.attendanceRequestActionModel.attendanceSummaryId = this.attendanceRequestModel.attendanceSummaryId;
      this.attendanceRequestActionModel.listOfUserForNotificationReference = [];
      
      if (this.attendanceRequestActionModel.attendanceSummaryId != null) {
        this.attendanceRequestApproverService.rejectUpdateAttendance(this.attendanceRequestActionModel).subscribe((res) => {
          this.messageService.display('Attendance update request rejected  successfully!  ', 'OK', 'success');
          this.dialogRef.close('applied');
          this.loaderService.updateInboxAction(true);
  
        });
      } else {
        this.attendanceRequestApproverService.rejectAttendance(this.attendanceRequestActionModel).subscribe((res) => {
          this.messageService.display('Attendance request rejected  successfully!  ', 'OK', 'success');
          this.dialogRef.close('applied');
          this.loaderService.updateInboxAction(true);
  
        });
      }
    }
    else {
      return false;
    }
  }

}
