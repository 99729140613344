import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { from } from 'rxjs';
import { HelpService } from 'src/app/core/services/help.service';
import { WfhRequestApproverService } from 'src/app/core/services/wfh-request-approver.service';
import { LoaderService } from 'src/app/shared/factory/loader.service';
import { MessageService } from 'src/app/shared/factory/message.service';
import { WfhRequestActionBuilder, WfhRequestActionModel } from 'src/app/shared/models/wfh-request-action-model';
import { ApprovedWfhActionRequestComponent, WfhActionRequestComponent } from 'src/app/shared/popups/wfh-popups';

@Component({
  selector: 'app-wfh-action',
  templateUrl: './wfh-action.component.html',
  styleUrls: ['./wfh-action.component.css']
})
export class WfhActionComponent implements OnInit {

  wfhActionReq: WfhRequestActionBuilder = new WfhRequestActionBuilder();
  wfhActionModel: WfhRequestActionModel = this.wfhActionReq.WfhRequestActionModel;
  wfhActionForm: FormGroup = this.wfhActionReq.wfhRequestActionForm;

  displayedColumns = ['id', 'employeeLite', 'reasonForWfhRequest','wfhDateRange', 'wfhRequestStatus','totalWfhCount', 'action'];
  dataSource: MatTableDataSource<WfhRequestActionModel>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  employees = [];
  param1: number;

  constructor(
    private wfhRequestApproverService:WfhRequestApproverService,
    private helpService: HelpService,
    public router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private loaderService: LoaderService,
    private messageService: MessageService,

  ) {
   // this.param1 = this.route.snapshot.params.param1;

    this.route.queryParams.subscribe(params => {
      this.param1 = params['wfhRequestId'];
     });
   }

  ngOnInit() {
    //this.leaveRequestApproverService.fetchAllPendingLeaveRequest().subscribe((res: LeaveRequestModel[]) => {
    // this.leaveRequestModel.id = res[0].id;
    //  if(this.param1 != null){
    //   this.openWfhActionDialog(this.param1);
    //  }
     this.dataSource = new MatTableDataSource<WfhRequestActionModel>([]);
     this.dataSource.paginator = this.paginator;
  }

  ngAfterViewInit(){
    this.fetchAllPendingWfhRequest();
    // this.dataSource = new MatTableDataSource<LeaveRequestModel>(res);
    this.paginator.page.subscribe(options => {
     this.fetchAllPendingWfhRequest();
    });
  }

  fetchAllPendingWfhRequest() {
    this.wfhRequestApproverService.fetchAllPendingWfhRequest().subscribe((res: WfhRequestActionModel[]) => {
      this.dataSource = new MatTableDataSource<WfhRequestActionModel>(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }
  openWfhActionDialog(wfhRequestId,userId,approverActionComment,wfhRequestApprovers,wfhRequestStatus) {
    this.wfhActionModel.wfhRequestId = wfhRequestId;
    this.wfhActionModel.userId = userId;
    this.wfhActionModel.wfhRequestApprovers= wfhRequestApprovers;
    this.wfhActionModel.wfhRequestStatus = wfhRequestStatus;
    this.wfhActionModel.approverActionComment = approverActionComment;
    if (wfhRequestStatus === 'PENDING_APPROVED_WFH_CANCELLATION') {
     const dialogRef = this.dialog.open(ApprovedWfhActionRequestComponent, {
      data: { wfhRequestId,userId,wfhRequestApprovers,wfhRequestStatus }
      });
        dialogRef.afterClosed().subscribe(result => {
        if (result == 'applied') {
         this.fetchAllPendingWfhRequest();
         this.loaderService.updateInboxAction(true);
        }
      });
    }
    else {
       const dialogRef = this.dialog.open(WfhActionRequestComponent, {
        data: { wfhRequestId,userId,wfhRequestApprovers,wfhRequestStatus }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result == 'applied') {
         this.fetchAllPendingWfhRequest();
         this.loaderService.updateInboxAction(true);
        }
      });
    }
  }

  approverWfhRequest(wfhRequestId,userId,approverActionComment,wfhRequestApprovers,wfhRequestStatus) {
    this.wfhActionModel.wfhRequestId = wfhRequestId;
    this.wfhActionModel.userId = userId;
    this.wfhActionModel.wfhRequestApprovers= wfhRequestApprovers;
    this.wfhActionModel.wfhRequestStatus = wfhRequestStatus;
    this.wfhActionModel.approverActionComment = approverActionComment;
    var retVal = confirm("Do you want to Approve work from home request?");
    if(wfhRequestStatus ==='PENDING_APPROVED_WFH_CANCELLATION' && retVal == true){
      this.approverWfhRequestCancel(this.wfhActionModel);
    }else if (retVal == true) {
      this.wfhRequestApproverService.approveWfh(this.wfhActionModel).subscribe((res) => {
        this.fetchAllPendingWfhRequest();
        this.messageService.display('Work from home Request approved  successfully!', 'OK', 'success');
        this.loaderService.updateInboxAction(true);
      });
    }
    else {
      return false;
    }
  }

  rejectWfhRequest(wfhRequestId,userId, approverActionComment, wfhRequestApprover,wfhRequestStatus) {
    var retVal = confirm("Do you want to Reject Work from home request?");
    this.wfhActionModel.wfhRequestId = wfhRequestId;
    this.wfhActionModel.userId = userId;
    this.wfhActionModel.approverActionComment = approverActionComment;
    this.wfhActionModel.wfhRequestApprovers = wfhRequestApprover;
    this.wfhActionModel.wfhRequestStatus = wfhRequestStatus;
    if(wfhRequestStatus ==="PENDING_APPROVED_WFH_CANCELLATION" && retVal == true){
      this.rejectapprovedWfhRequestCancel(this.wfhActionModel);
    }
    else if (retVal == true) {
      this.wfhRequestApproverService.rejectWfh(this.wfhActionModel).subscribe((res) => {
        this.fetchAllPendingWfhRequest();
        this.messageService.display('Work from home Request rejected  successfully!  ', 'OK', 'success');
        this.loaderService.updateInboxAction(true);
      });
    }
    else {
      return false;
    }
  }

  approverWfhRequestCancel(wfhActionModel){
    this.wfhRequestApproverService.approvedWfhCancelRequestAction(wfhActionModel).subscribe((res) => {
      this.fetchAllPendingWfhRequest();
      this.messageService.display('Work from home Request approved  successfully!', 'OK', 'success');
      this.loaderService.updateInboxAction(true);
    });
  }

  rejectapprovedWfhRequestCancel(wfhActionModel){
    this.wfhRequestApproverService.rejectWfhCancelRequestAction(wfhActionModel).subscribe((res)=>{
      this.fetchAllPendingWfhRequest();
      this.messageService.display('Work from home Request rejected  successfully!', 'OK', 'success');
      this.loaderService.updateInboxAction(true);
    });
  }

  findHelpUrlPage() {
    const currentPage = "/#_3_1_wfh_action";
    window.open(this.helpService.HELP_BASE_URL + currentPage, "_blank");

  }
}

