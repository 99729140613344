import { FormGroup, FormControl, Validators } from '@angular/forms';
import { customValidators } from '../factory/common.service';
export interface LeaveTypeModel {
    id: number,
    name: string,
    description: string,
    code: string,
    leaveEntitlement: {
        accrualPeriod: string,
        joiningDayOfMonth: number,
        joiningLeaveCount: number,
        maxLeaveCountPerFY: number,
        maxLeaveAccumulation: number
    },
    leaveCategory: string,
    leaveUnit: string,
    leaveCarryForward: {
        isEnabled: boolean,
        maxCarryForwardCount: number
    },
    isExcludeHolidays: boolean,
    isExcludeWeekends: boolean,
    leaveAssociation: string
}
export class LeaveTypeBuilder {
    leaveTypeModel: LeaveTypeModel = {
        id: null,
        name: '',
        description: '',
        code: '',
        leaveEntitlement: {
            accrualPeriod: '',
            joiningDayOfMonth: null,
            joiningLeaveCount: null,
            maxLeaveCountPerFY: null,
            maxLeaveAccumulation: null
        },
        leaveCategory: '',
        leaveUnit: '',
        leaveCarryForward: {
            isEnabled: false,
            maxCarryForwardCount: null
        },
        isExcludeHolidays: false,
        isExcludeWeekends: false,
        leaveAssociation: ''
    };

    leaveTypeForm: FormGroup = new FormGroup({
        name: new FormControl(this.leaveTypeModel.name),
        description: new FormControl(this.leaveTypeModel.description),
        code: new FormControl(this.leaveTypeModel.code),
        accrualPeriod: new FormControl(this.leaveTypeModel.leaveEntitlement.accrualPeriod),
        joiningDayOfMonth: new FormControl(this.leaveTypeModel.leaveEntitlement.joiningDayOfMonth),
        joiningLeaveCount: new FormControl(this.leaveTypeModel.leaveEntitlement.joiningLeaveCount),
        maxLeaveCountPerFY: new FormControl(this.leaveTypeModel.leaveEntitlement.maxLeaveCountPerFY),
        maxLeaveAccumulation: new FormControl(this.leaveTypeModel.leaveEntitlement.maxLeaveAccumulation),
        leaveCategory: new FormControl(this.leaveTypeModel.leaveCategory),
        leaveUnit: new FormControl(this.leaveTypeModel.leaveUnit),
        maxCarryForwardCount: new FormControl(this.leaveTypeModel.leaveCarryForward.maxCarryForwardCount),
        isEnabled: new FormControl(this.leaveTypeModel.leaveCarryForward.isEnabled),
        leaveAssociation: new FormControl(this.leaveTypeModel.leaveAssociation),
        isExcludeHolidays: new FormControl(this.leaveTypeModel.isExcludeHolidays),
        isExcludeWeekends: new FormControl(this.leaveTypeModel.isExcludeWeekends)

    });
    constructor() {
        this.leaveTypeForm.valueChanges.subscribe(val => {
            this.leaveTypeModel.name = val.name;
            this.leaveTypeModel.description = val.description;
            this.leaveTypeModel.code = val.code;
            this.leaveTypeModel.leaveEntitlement.accrualPeriod = val.accrualPeriod;
            this.leaveTypeModel.leaveEntitlement.joiningDayOfMonth = val.joiningDayOfMonth;
            this.leaveTypeModel.leaveEntitlement.joiningLeaveCount = val.joiningLeaveCount;
            this.leaveTypeModel.leaveEntitlement.maxLeaveCountPerFY = val.maxLeaveCountPerFY;
            this.leaveTypeModel.leaveEntitlement.maxLeaveAccumulation = val.maxLeaveAccumulation;
            this.leaveTypeModel.leaveCategory = val.leaveCategory;
            this.leaveTypeModel.leaveUnit = val.leaveUnit;
            this.leaveTypeModel.leaveCarryForward.maxCarryForwardCount = val.maxCarryForwardCount;
            this.leaveTypeModel.leaveCarryForward.isEnabled = val.isEnabled;
            this.leaveTypeModel.isExcludeHolidays = val.isExcludeHolidays;
            this.leaveTypeModel.isExcludeWeekends = val.isExcludeWeekends;
            this.leaveTypeModel.leaveAssociation = val.leaveAssociation;

        });
    }
    setModelVals(res: LeaveTypeModel) {
        this.leaveTypeForm.controls.name.setValue(res.name);
        this.leaveTypeForm.controls.description.setValue(res.description);
        this.leaveTypeForm.controls.code.setValue(res.code);
        this.leaveTypeForm.controls.accrualPeriod.setValue(res.leaveEntitlement.accrualPeriod);
        this.leaveTypeForm.controls.joiningDayOfMonth.setValue(res.leaveEntitlement.joiningDayOfMonth);
        this.leaveTypeForm.controls.joiningLeaveCount.setValue(res.leaveEntitlement.joiningLeaveCount);
        this.leaveTypeForm.controls.maxLeaveCountPerFY.setValue(res.leaveEntitlement.maxLeaveCountPerFY);
        this.leaveTypeForm.controls.maxLeaveAccumulation.setValue(res.leaveEntitlement.maxLeaveAccumulation);
        this.leaveTypeForm.controls.leaveCategory.setValue(res.leaveCategory);
        this.leaveTypeForm.controls.leaveUnit.setValue(res.leaveUnit);
        this.leaveTypeForm.controls.maxCarryForwardCount.setValue(res.leaveCarryForward.maxCarryForwardCount);
        this.leaveTypeForm.controls.isCarryForwardEnabled.setValue(res.leaveCarryForward.isEnabled);
        this.leaveTypeForm.controls.isExcludeHolidays.setValue(res.isExcludeHolidays);
        this.leaveTypeForm.controls.isExcludeWeekends.setValue(res.isExcludeWeekends);
        this.leaveTypeForm.controls.leaveAssociation.setValue(res.leaveAssociation);

    }
}