import { Component, OnInit } from '@angular/core';
import { LeaveTypeModel, LeaveTypeBuilder } from '../../shared/models/leave-type-model';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { LeaveTypeService } from '../../core/services/leave-type.service';
import { Validators } from '@angular/forms';
import { MessageService } from '../../shared/factory/message.service';
import { fetchCachedData } from '../../shared/factory/data-caching.service';
import { Location } from '@angular/common';


@Component({
  selector: 'app-leave-type-add-edit',
  templateUrl: './leave-type-add-edit.component.html',
  styleUrls: ['./leave-type-add-edit.component.css']
})
export class LeaveTypeAddEditComponent implements OnInit {

  leaveType: LeaveTypeBuilder = new LeaveTypeBuilder();
  leaveTypeModel: LeaveTypeModel = this.leaveType.leaveTypeModel;
  leaveTypeForm: FormGroup = this.leaveType.leaveTypeForm;
 
  checked: boolean = false;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private leaveTypeService: LeaveTypeService,
    private messageService: MessageService,
    public location: Location
  ) { }

  ngOnInit() {
  }

  addUpdateLeaveType() {

    this.leaveTypeModel.name = this.leaveTypeForm.controls.name.value;
   // this.leaveTypeModel.code = this.leaveTypeForm.controls.code.value;
    this.leaveTypeModel.description = this.leaveTypeForm.controls.description.value;
    this.leaveTypeModel.leaveEntitlement.accrualPeriod = this.leaveTypeForm.controls.accrualPeriod.value;
   // this.leaveTypeModel.leaveEntitlement.joiningDayOfMonth = this.leaveTypeForm.controls.joiningDayOfMonth.value;
   // this.leaveTypeModel.leaveEntitlement.joiningLeaveCount = this.leaveTypeForm.controls.joiningLeaveCount.value;
    this.leaveTypeModel.leaveEntitlement.maxLeaveAccumulation = this.leaveTypeForm.controls.maxLeaveAccumulation.value;
    this.leaveTypeModel.leaveEntitlement.maxLeaveCountPerFY = this.leaveTypeForm.controls.maxLeaveCountPerFY.value;
    this.leaveTypeModel.leaveCategory = this.leaveTypeForm.controls.leaveCategory.value;
    this.leaveTypeModel.leaveUnit = this.leaveTypeForm.controls.leaveUnit.value;

    this.leaveTypeModel.leaveCarryForward.isEnabled = this.leaveTypeForm.controls.isEnabled.value;
    this.leaveTypeModel.leaveCarryForward.maxCarryForwardCount = this.leaveTypeForm.controls.maxCarryForwardCount.value;
  
    this.leaveTypeModel.isExcludeHolidays = this.leaveTypeForm.controls.isExcludeHolidays.value;
    this.leaveTypeModel.isExcludeWeekends = this.leaveTypeForm.controls.isExcludeWeekends.value;
    
    this.leaveTypeModel.leaveAssociation = this.leaveTypeForm.controls.leaveAssociation.value;
   

   if (this.leaveTypeModel.id) {
      this.leaveTypeService.updateLeaveType(this.leaveTypeModel).subscribe((res) => {
        this.messageService.display('Leave update is successfull! Leave ID : ' + this.leaveTypeModel.id, 'OK', 'success');
        this.router.navigate(['/leaveType']);
      });
    } else {
      this.leaveTypeService.addLeaveType(this.leaveTypeModel).subscribe((res) => {
        this.messageService.display('Leave add is successfull! Leave ID : ' + res, 'OK', 'success');
        this.router.navigate(['/leaveType']);
      });
    }
  }

  maxLengthCheck(object : any) {
    if (object.value.length > object.maxLength)
      object.value = object.value.slice(0, object.maxLength)
  }

  changeValue(value) {
    this.checked = !value;
}

}
