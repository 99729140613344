import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { ok } from "assert";
import { UserService } from "src/app/core/services/user.service";
import { WfhRequestApproverService } from "src/app/core/services/wfh-request-approver.service";
import { WfhRequestService } from "src/app/core/services/wfh-request.service";
import { CommonService } from "../factory/common.service";
import { LoaderService } from "../factory/loader.service";
import { MessageService } from "../factory/message.service";
import { CancelApprovedLeaveRequestActionBuilder } from "../models/leave-request-action-model";
import { UserModel } from "../models/user-model";
import { CancelApprovedWfhRequestActionBuilder, CancelApprovedWfhRequestActionModel, WfhRequestActionBuilder, WfhRequestActionModel } from "../models/wfh-request-action-model";
import { WfhRequestBuilder, wfhRequestModel } from "../models/wfh-request-model";

/* #1 */
/*Begin Cancel Approved WFH Request */
@Component({
  selector: 'app-approved-leave-action-dialog',
  template: `
  <form [formGroup] = "cancelWfhRequestForm">
  <div mat-dialog-content>
    <p>Do you want to cancel WFH request?</p>
    <mat-form-field class="w-100">
      <mat-label>Reason for cancel WFH request</mat-label>
      <input matInput placeholder="Reason"  formControlName="reasonForWfhRequestCancel">
      <mat-error *ngIf="cancelWfhRequestForm.controls.approverActionComment.invalid">Reason for WFH request cancel is required!</mat-error>
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button cdkFocusInitial (click)="onOkClick()">Ok</button>
  </div>
  </form>`
})
export class CancelWfhApprovedComponent implements OnInit {
  encapsulation: ViewEncapsulation.ShadowDom
  reasonForCancel: string;
  cancelWfhRequestBuilder: CancelApprovedWfhRequestActionBuilder = new CancelApprovedWfhRequestActionBuilder();
  cancelWfhRequestModel: CancelApprovedWfhRequestActionModel = this.cancelWfhRequestBuilder.CancelApprovedWfhRequestActionModel;
  cancelWfhRequestForm: FormGroup = this.cancelWfhRequestBuilder.CancelApprovedWfhRequestActionForm;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private wfhRequestService: WfhRequestService,
    public router: Router,
    public commonServices: CommonService,
    public messageService: MessageService,
    public dialogRef: MatDialogRef<CancelWfhApprovedComponent>,
    private loaderService: LoaderService,
  ) { }

  ngOnInit() { }


  onNoClick(): void {
    this.dialogRef.close();
  }

  onOkClick() {
    this.cancelWfhRequestModel.wfhRequestId = this.data.wfhRequestId;
    // this.cancelWfhRequestModel.wfhRequestStatus = this.data.wfhRequestStatus;
    this.wfhRequestService.cancelApprovedWfhRequest(this.cancelWfhRequestModel).subscribe(() => {
      this.messageService.display('You have successfully applied for WFH request cancellation!  ', 'OK', 'success');
      this.dialogRef.close('applied');
      this.router.navigate(['/wfhRequest']);
    }, err => {
      this.messageService.display(err.error.errors.WfhRequestApproveValidation[0].message, 'OK', 'error');
    });
    this.dialogRef.close();
  }
}
/*End Cancel Approved WFH */


/* 3 */
/* Begin Arroved Leave Action */
@Component({
  selector: 'app-approved-wfh-action-dialog',
  template: `<div [formGroup]="wfhActionForm"><h1 mat-dialog-title align="center"> Action on cancel approved WFH request</h1>
     <div mat-dialog-content>
       <hr/>
    <div class="col-sm" formGroupName="employeeDTOLite">
    <div class="row">
    <div class="col-sm">
            <mat-label>Employee Name</mat-label>
            </div>
            <div class="col-sm">
          
            <input matInput readonly formControlName="fullName">
            </div>
       </div>
    </div>
    <hr/>
        <div class="col-sm">
        <div class="row">
        <div class="col-sm">
                <mat-label>Total WFH request Count</mat-label>
                </div>
                <div class="col-sm">    
                <input matInput readonly formControlName="totalWfhCount">
           </div>
           </div>
        </div>
  
        <hr/>
  
  
        <div class="col-sm">
          <div class="row">
              <div class="col-sm">
                    <div *ngIf="wfhActionForm.controls.wfhRequestStatus.value==='PENDING_APPROVED_WFH_CANCELLATION'">
                      <mat-label>Reason for cancel</mat-label>
                    </div>
                    <div *ngIf="wfhActionForm.controls.wfhRequestStatus.value==='PENDING_APPROVAL'">
                      <mat-label>Reason for cancel</mat-label>
                    </div>
              </div>
                  <div class="col-sm" *ngIf="wfhActionForm.controls.wfhRequestStatus.value==='PENDING_APPROVED_WFH_CANCELLATION'">  
                    <mat-label>{{wfhActionForm.controls.wfhcancelReason.value}}</mat-label>
                  </div>
                  <div class="col-sm"  *ngIf="wfhActionForm.controls.wfhRequestStatus.value==='PENDING_APPROVAL' ">
                      <mat-label>{{wfhActionForm.controls.reasonForWfhRequest.value}}</mat-label>
                  </div>
          </div>
        </div>
        <hr/>
        <div class="col-sm">
          <div class="row">
            <div class="col-sm">
                <mat-label>Requested Date</mat-label>
              </div>
              <div class="col-sm"> 
                  <mat-label>{{wfhActionForm.controls.wfhRequestedDate.value}}</mat-label>
              </div>
            </div>
        </div>
        <hr/>
        <div class="col-sm">
          <div class="row">
              <div class="col-sm">
                <mat-label>WFH Requested Date</mat-label>
              </div>
              <div class="col-sm"> 
                  <mat-label style="margin-right: 5px;">{{wfhActionForm.controls.wfhFromDate.value}}</mat-label>
                  <mat-label> <b> to </b></mat-label>
                  <mat-label style="margin-left: 5px;">{{wfhActionForm.controls.wfhToDate.value}}</mat-label>
              </div>
            </div>
        </div>
        <hr/>
  
  <div class="col-sm mt-3">
    <p>
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Select Users</mat-label>
        <mat-select formControlName="wfhRequestApprovers" multiple required [(value)]="selectedApprovers">
              <mat-option *ngFor="let user of approvers;"[value]="user.emailOrUsername">
                {{user.name}}
              </mat-option>
          </mat-select>
        <mat-hint>Select users to whom you want to share email notification in case of WFH request approved</mat-hint>
    </mat-form-field>
    </p>
  </div>
  
    <div class="col-sm">
        <p>
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Approver Comment</mat-label>
                <input formControlName="approverActionComment" maxlength="40" minlength="8" matInput
                    placeholder="Enter Approver Comment" required>
                <mat-hint>Enter comment</mat-hint>
                <mat-error *ngIf="wfhActionForm.controls.approverActionComment.invalid">Approver comment is required!</mat-error>
            </mat-form-field>
        </p>
    </div>
  
  </div>
  <div class="text-right">
  
    <button  type="submit" mat-raised-button color="primary" class="mat-control w-50 mt-2" (click)="approveAction()" [disabled]="wfhActionForm.controls.approverActionComment.invalid">Approve</button>
    <button type="submit"  mat-raised-button color="primary" class="mat-control w-50 mt-2" (click)="rejectAction()" [disabled]="wfhActionForm.controls.approverActionComment.invalid">Reject</button>
    <button mat-raised-button mat-dialog-close class="w-100 mt-2" style="margin-bottom: 5px;">Cancel</button>
  </div>
      </div>`
})
export class ApprovedWfhActionRequestComponent implements OnInit {

  wfhRequest: WfhRequestBuilder = new WfhRequestBuilder();
  wfhRequestModel: wfhRequestModel = this.wfhRequest.wfhRequestModel;
  wfhActionForm: FormGroup = this.wfhRequest.wfhRequestForm;

  dataSource: MatTableDataSource<wfhRequestModel>;

  wfhRequestAction: CancelApprovedWfhRequestActionBuilder = new CancelApprovedWfhRequestActionBuilder();
  wfhRequestActionModel: CancelApprovedWfhRequestActionModel = this.wfhRequestAction.CancelApprovedWfhRequestActionModel;
  wfhRequestActionForm: FormGroup = this.wfhRequestAction.CancelApprovedWfhRequestActionForm;

  wfhRequestForm: any;
  dateTimeFormatters: any;
  reasonForWfhCancel: string
  userList = [];
  loggedUserId: number;
  wfhRequestedDate: string;
  approvers = [];
  selectedApprovers = this.approvers[1];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private wfhRequestService: WfhRequestService,
    private wfhRequestApproverService: WfhRequestApproverService,
    public router: Router,
    public commonServices: CommonService,
    public messageService: MessageService,
    public dialogRef: MatDialogRef<ApprovedWfhActionRequestComponent>,
    private loaderService: LoaderService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.populateWfhRequestDtoData();
  }

  fetchAllUsers(appliedLeaveUserId) {
    const loggedUser = sessionStorage.getItem('loginData');
    this.loggedUserId = JSON.parse(loggedUser).id;
    this.userService.fetchUsers().subscribe((res: UserModel[]) => {
      res.filter(userData => {
        if (userData.id !== this.loggedUserId && userData.id !== appliedLeaveUserId) {
          this.userList.push(userData);
          this.approvers = res;
        }
      });
    });
  }

  populateWfhRequestDtoData() {
    this.wfhRequestService.fetchWfhByIdWithDetail(this.data.wfhRequestId).subscribe((res) => {
      this.fetchAllUsers(res['userId']);
      // this.wfhRequestedDate = res['wfhRequestedDate']; 
      this.wfhRequest.setModelVals(res);
      this.reasonForWfhCancel = res['reasonForWfhCancel']
    });
  }

  //'APPROVED';
  approveAction() {
    var retVal = confirm("Do you want to approve this WFH request?");
    if (retVal) {
      this.wfhRequestActionModel.approverActionComment = this.wfhRequestModel.approverActionComment;
      this.wfhRequestActionModel.wfhRequestId = this.data.wfhRequestId;
      this.wfhRequestActionModel.employeeId = this.data.userId;
        // Only assign approvers if selectedApprovers is not empty
        if (this.selectedApprovers && this.selectedApprovers.length > 0) {
          this.wfhRequestActionModel.listOfUserForNotificationReference = this.selectedApprovers;
        } else {
          this.wfhRequestActionModel.listOfUserForNotificationReference = [];
        }
      this.messageService.display("Loding..." ,"OK","success");
      this.wfhRequestApproverService.approvedWfhCancelRequestAction(this.wfhRequestActionModel).subscribe((res) => {
        this.messageService.display('Cancel WFH request approved  successfully!', 'OK', 'success');
        this.dialogRef.close('applied');
        this.loaderService.updateInboxAction(true);

      });
    }
    else {
      return false;
    }
  }

  // 'REJECTED'
  rejectAction() {
    var retVal = confirm("Do you want to reject this WFH request?");
    if (retVal) {
      this.wfhRequestActionModel.approverActionComment = this.wfhRequestModel.approverActionComment;
      this.wfhRequestActionModel.wfhRequestId = this.data.wfhRequestId;
      this.wfhRequestActionModel.employeeId = this.data.userId;
      // this.wfhRequestActionModel.employeeId = this.wfhRequestModel.employeeDTOLite.id;
      this.wfhRequestActionModel.listOfUserForNotificationReference = [];
      this.wfhRequestApproverService.rejectWfhCancelRequestAction(this.wfhRequestActionModel).subscribe((res) => {
        this.messageService.display('Cancel WFH request rejected  successfully!  ', 'OK', 'success');
        this.dialogRef.close('applied');
        this.loaderService.updateInboxAction(true);
      });
    }
    else {
      return false;
    }
  }

}

/* End Approved Leave Action */




/* 4 */
//Wfh Action  Dialog
@Component({
  selector: 'app-wfh-action-dialog',
  template: `<div [formGroup]="wfhActionForm"><h1 mat-dialog-title align="center"> WFH request action </h1>
    <div mat-dialog-content>
      <hr/>
    <div class="col-sm" formGroupName="employeeDTOLite">
    <div class="row">
    <div class="col-sm">
            <mat-label>Employee Name</mat-label>
            </div>
            <div class="col-sm">
          
            <input matInput readonly formControlName="fullName">
            </div>
       </div>
    </div>
    <hr/>
        <div class="col-sm">
        <div class="row">
        <div class="col-sm">
                <mat-label>Total WFH request count</mat-label>
                </div>
                <div class="col-sm">    
                <input matInput readonly formControlName="totalWfhCount">
           </div>
           </div>
        </div>
  
        <hr/>
        <div class="col-sm">
        <div class="row">
        <div class="col-sm">
                <mat-label>Reason</mat-label>
                </div>
                <div class="col-sm">  
                <mat-label>{{wfhActionForm.controls.reasonForWfhRequest.value}}</mat-label>
               
                </div>
                </div>
        </div>
        <hr/>
        <div class="col-sm">
        <div class="row">
        <div class="col-sm">
                <mat-label>Requested Date</mat-label>
                </div>
                <div class="col-sm">  
                <mat-label>{{wfhActionForm.controls.wfhRequestedDate.value}}</mat-label>
               
                </div>
                </div>
        </div>
        <hr/>
        <div class="col-sm">
          <div class="row">
              <div class="col-sm">
                <mat-label>WFH Requested Date</mat-label>
              </div>
              <div class="col-sm">
                  <mat-label style="margin-right: 5px;">{{wfhActionForm.controls.wfhFromDate.value}}</mat-label>
                  <mat-label> <b> to </b></mat-label>
                  <mat-label style="margin-left: 5px;">{{wfhActionForm.controls.wfhToDate.value}}</mat-label>
              </div>
            </div>
        </div>
        <hr/>
        <!-- <div class="col-sm">
    <div formArrayName="leaveRequestDetailDTOs">
        <div 
            *ngFor="let leaveRequestDetailDTO of leaveActionForm.get('leaveRequestDetailDTOs').controls; let i = index;">
            <div class="row">
  
                <div class="col-sm">
                    <input type="text" readonly class="form-control-plaintext"
                        value="{{wfhActionForm.controls.leaveRequestDetailDTOs.controls[i].controls.leaveDate.value}}">
                </div>
  
                <div class="col-sm">
                    <input type="text" readonly class="form-control-plaintext"
                        value="{{wfhActionForm.controls.leaveRequestDetailDTOs.controls[i].controls.leaveRequestType.value}}">
                </div>
            </div>
        </div>
    </div>
  </div> -->
  
  <div class="col-sm mt-3">
    <p>
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Select Users</mat-label>
        <mat-select formControlName="wfhRequestApprovers" multiple>
              <mat-option *ngFor="let user of approvers;"[value]="user.emailOrUsername">
                {{user.name}}
              </mat-option>
          </mat-select>
        <mat-hint>Select users to whom you want to share email notification in case of WFH request approved</mat-hint>
    </mat-form-field>
    </p>
  </div>
  
    <div class="col-sm">
        <p>
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Approver Comment</mat-label>
                <input formControlName="approverActionComment" maxlength="40" minlength="8" matInput
                    placeholder="Enter Approver Comment" required>
                <mat-hint>Enter comment</mat-hint>
                <mat-error *ngIf="wfhActionForm.controls.approverActionComment.invalid">Approver comment is required!</mat-error>
            </mat-form-field>
        </p>
    </div>
  
  </div>
  <div class="text-right">
  
  <button  type="submit" mat-raised-button color="primary" class="mat-control w-50 mt-2" (click)="ApproveAction()" [disabled]="isActionTaken || wfhActionForm.controls.approverActionComment.invalid">Approve</button>
  <button type="submit"  mat-raised-button color="primary" class="mat-control w-50 mt-2" (click)="RejectAction()" [disabled]="isActionTaken || wfhActionForm.controls.approverActionComment.invalid">Reject</button>
    <button mat-raised-button mat-dialog-close class="w-100 mt-2">Cancel</button>
  </div>
      </div>`
})
export class WfhActionRequestComponent implements OnInit {

  wfhRequest: WfhRequestBuilder = new WfhRequestBuilder();
  wfhRequestModel: wfhRequestModel = this.wfhRequest.wfhRequestModel;
  wfhActionForm: FormGroup = this.wfhRequest.wfhRequestForm;

  dataSource: MatTableDataSource<wfhRequestModel>;

  wfhRequestAction: WfhRequestActionBuilder = new WfhRequestActionBuilder();
  wfhRequestActionModel: WfhRequestActionModel = this.wfhRequestAction.WfhRequestActionModel;
  wfhRequestActionForm: FormGroup = this.wfhRequestAction.wfhRequestActionForm;

  wfhRequestForm: any;
  dateTimeFormatters: any;
  loggedUserId: number;
  wfhRequestedDate: string;

  approvers = [];
  isActionTaken: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private wfhRequestService: WfhRequestService,
    private wfhRequestApproverService: WfhRequestApproverService,
    public router: Router,
    public commonServices: CommonService,
    public messageService: MessageService,
    public dialogRef: MatDialogRef<WfhActionRequestComponent>,
    private loaderService: LoaderService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.populateWFhRequestDto();
  }

  fetchAllUsers(appliedLeaveUserId) {
    const loggedUser = sessionStorage.getItem('loginData');
    this.loggedUserId = JSON.parse(loggedUser).id;
    this.userService.fetchUsers().subscribe((res: UserModel[]) => {
      res.filter(userData => {
        if (userData.id !== this.loggedUserId && userData.id !== appliedLeaveUserId) {
          // this.userList.push(userData);
          this.approvers = res;
        }
      });
    });
  }


  populateWFhRequestDto() {
    this.wfhRequestService.fetchWfhByIdWithDetail(this.data.wfhRequestId).subscribe((res) => {
      this.fetchAllUsers(res['userId']);
      res.wfhRequestApprovers = [];
      this.wfhRequest.setModelVals(res);
      // this.dialogRef.close();
      // this.messageService.display('Boarding process is successfully completed ! Module name : ' + this.wfhRequestModel.id, 'OK', 'success');
    });
  }


  ApproveAction() {
    var retVal = confirm("Do you want to Approve WFH request?");
    if (retVal == true) {
      this.isActionTaken = true; 
      this.wfhRequestActionModel.approverActionComment = this.wfhRequestModel.approverActionComment;
      this.wfhRequestActionModel.wfhRequestId = this.data.wfhRequestId;
      this.wfhRequestActionModel.wfhRequestStatus = this.wfhRequestModel.wfhRequestStatus;
      this.wfhRequestActionModel.listOfUserForNotificationReference = this.wfhRequestModel.wfhRequestApprovers;
      this.messageService.display("Loding...",'ok','success');
      this.wfhRequestApproverService.approveWfh(this.wfhRequestActionModel).subscribe((res) => {
        this.messageService.display('WFH request approved  successfully!', 'OK', 'success');
        this.dialogRef.close('applied');
        this.loaderService.updateInboxAction(true);

      });
    }
    else {
      return false;
    }
  }

  RejectAction() {
    var retVal = confirm("Do you want to Reject WFH request?");
    if (retVal == true) {
      this.isActionTaken = true; 
      this.wfhRequestActionModel.approverActionComment = this.wfhRequestModel.approverActionComment;
      this.wfhRequestActionModel.wfhRequestId = this.data.wfhRequestId;
      this.wfhRequestActionModel.wfhRequestStatus = this.wfhRequestModel.wfhRequestStatus;
      this.wfhRequestActionModel.listOfUserForNotificationReference =  [];
      this.wfhRequestApproverService.rejectWfh(this.wfhRequestActionModel).subscribe((res) => {
        this.messageService.display('WFH request rejected  successfully!  ', 'OK', 'success');
        this.dialogRef.close('applied');
        this.loaderService.updateInboxAction(true);

      });
    }
    else {
      return false;
    }
  }

}
