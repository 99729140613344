import { Component, OnInit, ViewChild } from '@angular/core';
import { LeaveRequestService } from '../../core/services/leave-request.service';
import { FormControl, FormGroup } from '@angular/forms';
import { LeaveRequestModel, LeaveRequestBuilder } from '../../shared/models/leave-request-model';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { LeaveActionBuilder, LeaveActionModel } from 'src/app/shared/models/leave-action-model';
import { HelpService } from '../../core/services/help.service';
import { MessageService } from 'src/app/shared/factory/message.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { LoaderService } from 'src/app/shared/factory/loader.service';
import { CancelApprovedComponent } from '../../shared/popups/popups';
import { CancelLeaveRequestBuilder, CancelLeaveRequestModel } from 'src/app/shared/models/leave-request-action-model';

@Component({
  selector: 'app-leave-request',
  templateUrl: './leave-request.component.html',
  styleUrls: ['./leave-request.component.css']
})
export class LeaveRequestComponent implements OnInit {

  leaveActionReq: LeaveActionBuilder = new LeaveActionBuilder();
  leaveActionModel: LeaveActionModel = this.leaveActionReq.leaveActionModel;
  leaveActionRequestForm: FormGroup = this.leaveActionReq.leaveActionRequestForm;

      cancelLeaveRequestBuilder :  CancelLeaveRequestBuilder = new CancelLeaveRequestBuilder();
      cancelLeaveRequestModel: CancelLeaveRequestModel = this.cancelLeaveRequestBuilder.cancelLeaveRequestModel;
      cancelLeaveRequestForm: FormGroup = this.cancelLeaveRequestBuilder.cancelLeaveRequestForm;


  displayedColumns = ['id', 'reasonForLeave', 'leaveRequestState', 'leaveDateRange', 'action', 'totalLeaveCount', 'isDisabled'];
  dataSource: MatTableDataSource<LeaveRequestModel>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private leaveRequestService: LeaveRequestService,
    private helpService: HelpService,
    public messageService: MessageService,
    public router: Router,
    private dialog: MatDialog,
    private loaderService: LoaderService,
  ) { }

  ngOnInit() {
    this.fetchLeaveRequest();
    this.dataSource = new MatTableDataSource<LeaveRequestModel>([]);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  fetchLeaveRequest() {
    this.leaveRequestService.fetchLeaveRequest().subscribe((res: LeaveRequestModel[]) => {
      res.filter(leaveRequest => {
        let isDisabled = this.checkStatus(leaveRequest.leaveRequestState, leaveRequest.leaveRequestDetailDTOs[0].leaveDate);
        leaveRequest['isDisabled'] = isDisabled
     });
      this.dataSource = new MatTableDataSource<LeaveRequestModel>(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  cancelLeaveRequestAction(leaveRequestId, leaveRequestState, reasonForLeaveCancel) {
   if(leaveRequestState == 'APPROVED') {
      this.cancelApprovedLeaveRequestAction(leaveRequestId)
   }
   else {
    var retVal = confirm("Do you want to Cancel Leave?");
    this.cancelLeaveRequestModel.leaveRequestId = leaveRequestId;
    this.cancelLeaveRequestModel.reasonForLeaveCancel = reasonForLeaveCancel;
    if (retVal == true) {
      this.leaveRequestService.CancelLeave(this.cancelLeaveRequestModel).subscribe((res) => {
        this.messageService.display('LeaveRequest Cancelled  successfully!  ', 'OK', 'success');
        this.fetchLeaveRequest();
        this.router.navigate(['/leaveRequest']);
      });
    }
    else {
      // document.write ("User does not want to Approve Leave!");
      return false;
    }
   }
  }

  findHelpUrlPage() {
    const currentPage = "/#_2_2_leave_request";
    window.open(this.helpService.HELP_BASE_URL + currentPage, "_blank");

  }

  cancelApprovedLeaveRequestAction(leaveRequestId) {
    const dialogRef = this.dialog.open(CancelApprovedComponent, {
      data: { leaveRequestId }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'applied') {
        this.fetchLeaveRequest();
        this.loaderService.updateInboxAction(true);
      }
    });
  }

  checkStatus(leaveRequestState, leaveDate1) {
    let isDisabled = true;
    if (leaveRequestState === 'APPROVED') {
      isDisabled = false;
    }
    else if (leaveRequestState === 'PENDING_APPROVAL') {
      isDisabled = false;
    }
    return isDisabled;
  }
}
