import {FormGroup,FormControl, Validator, Validators, FormArray } from '@angular/forms';


export interface wfhRequestModel {
    id: number,
    reasonForWfhRequest: string,
    isDisabled: boolean,
    wfhFromDate:any,
    wfhToDate:any,
    employeeDTOLite: {
        id: number,
        firstName: string,
        lastName: string,
        fullName: String,
    }
    wfhRequestStatus: string,
    wfhRequestApprovers: Array<any>,
    totalWfhCount: number,
    wfhType:string,
    approverActionComment: string,
    listofUserForNotificationReference: Array<any>,
    wfhcancelReason:string,
    wfhRequestedDate:Date,
}

export class WfhRequestBuilder {
    wfhRequestModel : wfhRequestModel = {
        id: null,
        reasonForWfhRequest:'',
        isDisabled: false,
        wfhFromDate: null,
        wfhToDate: null,
        wfhType:'',
        employeeDTOLite:{
            id:null,
            firstName: '',
            lastName: '',
            fullName: ''
        },
        wfhRequestStatus: '',
        wfhRequestApprovers:[],
        totalWfhCount: null,
        approverActionComment:'',
        listofUserForNotificationReference:[],
        wfhcancelReason:'',
        wfhRequestedDate:null,
    };

    wfhRequestForm: FormGroup = new FormGroup({
        reasonForWfhRequest: new FormControl(this.wfhRequestModel.reasonForWfhRequest,[Validators.required]),
        employeeDTOLite: new FormGroup({
            id: new FormControl(this.wfhRequestModel.employeeDTOLite.id),
            firstName: new FormControl(this.wfhRequestModel.employeeDTOLite.firstName),
            lastName: new FormControl(this.wfhRequestModel.employeeDTOLite.lastName),
            fullName: new FormControl(this.wfhRequestModel.employeeDTOLite.firstName + this.wfhRequestModel.employeeDTOLite.lastName ),
        }),
        wfhType: new FormControl(this.wfhRequestModel.wfhType),
        wfhRequestStatus: new FormControl(this.wfhRequestModel.wfhRequestStatus,[Validators.required]),
        wfhRequestApprovers: new FormControl(this.wfhRequestModel.wfhRequestApprovers,[Validators.required]),
        totalWfhCount: new FormControl(this.wfhRequestModel.totalWfhCount),
        wfhFromDate: new FormControl(this.wfhRequestModel.wfhFromDate),
        wfhToDate: new FormControl(this.wfhRequestModel.wfhToDate),
        approverActionComment: new FormControl(this.wfhRequestModel.approverActionComment,[Validators.required]),
        listofUserForNotificationReference: new FormControl(this.wfhRequestModel.listofUserForNotificationReference,[Validators.required]),
        wfhcancelReason: new FormControl(this.wfhRequestModel.wfhcancelReason),
        wfhRequestedDate: new FormControl(this.wfhRequestModel.wfhRequestedDate)
    });

    constructor(){
        this.wfhRequestForm.valueChanges.subscribe(val =>{

            this.wfhRequestModel.reasonForWfhRequest = val.reasonForWfh;
            this.wfhRequestModel.employeeDTOLite = val.employeeDTOLite;
            this.wfhRequestModel.wfhRequestStatus = val.wfhRequestStatus;
            this.wfhRequestModel.wfhRequestApprovers = val.wfhRequestApprovers;
            this.wfhRequestModel.totalWfhCount = val.totalWfhCount;
            this.wfhRequestModel.approverActionComment = val.approverActionComment;
            this.wfhRequestModel.listofUserForNotificationReference = val.listOfUserForNotificationReference;
            this.wfhRequestModel.wfhFromDate = val.wfhFromDate;
            this.wfhRequestModel.wfhType = val.wfhType;
            this.wfhRequestModel.wfhcancelReason = val.wfhcancelReason;
            this.wfhRequestModel.wfhRequestedDate = val.wfhRequestedDate;
        });
    }

    setModelVals(res:wfhRequestModel){
        this.wfhRequestForm.controls.reasonForWfhRequest.setValue(res.reasonForWfhRequest);
        // this.wfhRequestForm.controls.employeeDTOLite.setValue(res.employeeDTOLite);
        this.wfhRequestForm.controls.wfhRequestStatus.setValue(res.wfhRequestStatus);
        this.wfhRequestForm.controls.wfhRequestApprovers.setValue(res.wfhRequestApprovers);
        this.wfhRequestForm.controls.totalWfhCount.setValue(res.totalWfhCount);
        this.wfhRequestForm.controls.approverActionComment.setValue(res.approverActionComment);
        // this.wfhRequestForm.controls.listOfUserForNotificationReference.setValue(res?.listofUserForNotificationReference);
        this.wfhRequestForm.controls.wfhFromDate.setValue(res.wfhFromDate);
        this.wfhRequestForm.controls.wfhToDate.setValue(res.wfhToDate);
        this.wfhRequestForm.controls.wfhType.setValue(res.wfhType);
        this.wfhRequestForm.controls.wfhcancelReason.setValue(res.wfhcancelReason);
        this.wfhRequestForm.controls.wfhRequestedDate.setValue(res.wfhRequestedDate);
        let employeeDetailformGroup = this.wfhRequestForm.get('employeeDTOLite') as FormGroup;
        
        employeeDetailformGroup.controls.id.setValue(res.employeeDTOLite.id);
        employeeDetailformGroup.controls.firstName.setValue(res.employeeDTOLite.firstName);
        employeeDetailformGroup.controls.lastName.setValue(res.employeeDTOLite.lastName);
        employeeDetailformGroup.controls.fullName.setValue(res.employeeDTOLite.firstName + res.employeeDTOLite.lastName);
        
    }
}