import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpRequestService } from '../http/http-request.service';

@Injectable({
  providedIn: 'root'
})
export class ActivitiesService {

  private stateUrl = 'api/v1/activity';

  constructor(private httpRequest: HttpRequestService) { }

  fetchAllActivities(activityFilters,pageNo: number, pageSize: number) {
    return this.httpRequest.get(this.stateUrl + '/filter-specific-activity' + '?page=' + pageNo + '&size=' + pageSize + '&activityFilters=' + activityFilters);
  }
}
