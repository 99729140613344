import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AttendanceRequestApproverService } from 'src/app/core/services/attendance-regularisation-request-approver.service';
import { HelpService } from 'src/app/core/services/help.service';
import { LoaderService } from 'src/app/shared/factory/loader.service';
import { MessageService } from 'src/app/shared/factory/message.service';
import { AttendanceRequestActionBuilder, AttendanceRequestActionModel } from 'src/app/shared/models/attendance-request-action-model';
import { ApprovedAttendanceActionRequestComponent, AttendanceActionRequestComponent } from 'src/app/shared/popups/attendance-popups';
import { ApprovedWfhActionRequestComponent, WfhActionRequestComponent } from 'src/app/shared/popups/wfh-popups';

@Component({
  selector: 'app-attendance-regularisation-action',
  templateUrl: './attendance-regularisation-action.component.html',
  styleUrls: ['./attendance-regularisation-action.component.css']
})
export class AttendanceRegularisationActionComponent implements OnInit {
  attendanceActionReq: AttendanceRequestActionBuilder = new AttendanceRequestActionBuilder();
  attendanceActionModel: AttendanceRequestActionModel = this.attendanceActionReq.attendanceRequestActionModel;
  attendanceActionForm: FormGroup = this.attendanceActionReq.attendanceRequestActionForm;

  displayedColumns = ['id', 'employeeLite', 'reasonForAttendanceRequest', 'attendanceDateRange', 'attendanceSummaryId', 'attendanceRequestStatus', 'action'];
  dataSource: MatTableDataSource<AttendanceRequestActionModel>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  employees = [];
  param1: number;

  constructor(
    private attendanceRequestApproverService: AttendanceRequestApproverService,
    private helpService: HelpService,
    public router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private loaderService: LoaderService,
    private messageService: MessageService,

  ) {
    this.route.queryParams.subscribe(params => {
      this.param1 = params['attendanceRequestId'];
    });
  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource<AttendanceRequestActionModel>([]);
    this.dataSource.paginator = this.paginator;
  }

  ngAfterViewInit() {
    this.fetchAllPendingAttendanceRequest();
    // this.fetchAllPendingAttendanceUpdateRequest();
    this.paginator.page.subscribe(options => {
      this.fetchAllPendingAttendanceRequest();
    });
  }

  fetchAllPendingAttendanceRequest() {
    this.attendanceRequestApproverService.fetchAllPendingAttendanceRequest().subscribe((res: AttendanceRequestActionModel[]) => {
      this.dataSource = new MatTableDataSource<AttendanceRequestActionModel>(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  fetchAllPendingAttendanceUpdateRequest() {
    this.attendanceRequestApproverService.fetchAllPendingAttendanceRegulationUpdateRequest().subscribe((res: AttendanceRequestActionModel[]) => {
      this.dataSource = new MatTableDataSource<AttendanceRequestActionModel>(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  openAttendanceActionDialog(attendanceRequestId, userId, approverActionComment, attendanceRequestApprovers, attendanceRequestStatus,attendanceSummaryId) {
    this.attendanceActionModel.attendanceRequestId = attendanceRequestId;
    this.attendanceActionModel.userId = userId;
    this.attendanceActionModel.attendanceRequestApprovers = attendanceRequestApprovers;
    this.attendanceActionModel.attendanceRequestStatus = attendanceRequestStatus;
    this.attendanceActionModel.approverActionComment = approverActionComment;
    this.attendanceActionModel.attendanceSummaryId = attendanceSummaryId;
    if (attendanceRequestStatus === 'PENDING_APPROVED_ATTENDANCE_CANCELLATION') {
      const dialogRef = this.dialog.open(ApprovedAttendanceActionRequestComponent, {
        data: { attendanceRequestId, userId, attendanceRequestApprovers, attendanceRequestStatus }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result == 'applied') {
          this.fetchAllPendingAttendanceRequest();
          this.loaderService.updateInboxAction(true);
        }
      });
    }
    else {
      const dialogRef = this.dialog.open(AttendanceActionRequestComponent, {
        data: { attendanceRequestId, userId, attendanceRequestApprovers, attendanceRequestStatus }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result == 'applied') {
          this.fetchAllPendingAttendanceRequest();
          this.loaderService.updateInboxAction(true);
        }
      });
    }
  }

  approverAttendanceRequest(attendanceRequestId, userId, approverActionComment, attendanceRequestApprovers, attendanceFromDate, attendanceRequestStatus, entryTime, exitTime, attendanceRequestedDate) {
    this.attendanceActionModel.attendanceRequestId = attendanceRequestId;
    this.attendanceActionModel.userId = userId;
    this.attendanceActionModel.attendanceRequestApprovers = attendanceRequestApprovers;
    this.attendanceActionModel.attendanceRequestStatus = attendanceRequestStatus;
    this.attendanceActionModel.approverActionComment = approverActionComment;
      this.attendanceActionModel.attendanceFromDate = attendanceFromDate;
      this.attendanceActionModel.attendanceRequestedDate = attendanceRequestedDate;
      this.attendanceActionModel.entryTime = entryTime;
      this.attendanceActionModel.exitTime = exitTime;
    var retVal = confirm("Do you want to Approve work from home request?");
    if (attendanceRequestStatus === 'PENDING_APPROVED_ATTENDANCE_CANCELLATION' && retVal == true) {
      this.approverAttendanceRequestCancel(this.attendanceActionModel);
    } else if (retVal == true) {
      this.attendanceRequestApproverService.approveAttendance(this.attendanceActionModel).subscribe((res) => {
        this.fetchAllPendingAttendanceRequest();
        this.messageService.display('Work from home Request approved  successfully!', 'OK', 'success');
        this.loaderService.updateInboxAction(true);
      });
    }
    else {
      return false;
    }
  }

  rejectAttendanceRequest(attendanceRequestId, userId, approverActionComment, attendanceRequestApprover, attendanceRequestStatus) {
    var retVal = confirm("Do you want to Reject Work from home request?");
    this.attendanceActionModel.attendanceRequestId = attendanceRequestId;
    this.attendanceActionModel.userId = userId;
    this.attendanceActionModel.approverActionComment = approverActionComment;
    this.attendanceActionModel.attendanceRequestApprovers = attendanceRequestApprover;
    this.attendanceActionModel.attendanceRequestStatus = attendanceRequestStatus;
    if (attendanceRequestStatus === "PENDING_APPROVED_ATTENDANCE_CANCELLATION" && retVal == true) {
      this.rejectApprovedAttendanceRequestCancel(this.attendanceActionModel);
    }
    else if (retVal == true) {
      this.attendanceRequestApproverService.rejectAttendance(this.attendanceActionModel).subscribe((res) => {
        this.fetchAllPendingAttendanceRequest();
        this.messageService.display('Work from home Request rejected  successfully!  ', 'OK', 'success');
        this.loaderService.updateInboxAction(true);
      });
    }
    else {
      return false;
    }
  }

  approverAttendanceRequestCancel(attendanceActionModel) {
    this.attendanceRequestApproverService.approvedAttendanceCancelRequestAction(attendanceActionModel).subscribe((res) => {
      this.fetchAllPendingAttendanceRequest();
      this.messageService.display('Work from home Request approved  successfully!', 'OK', 'success');
      this.loaderService.updateInboxAction(true);
    });
  }

  rejectApprovedAttendanceRequestCancel(attendanceActionModel) {
    this.attendanceRequestApproverService.rejectAttendanceCancelRequestAction(attendanceActionModel).subscribe((res) => {
      this.fetchAllPendingAttendanceRequest();
      this.messageService.display('Work from home Request rejected  successfully!', 'OK', 'success');
      this.loaderService.updateInboxAction(true);
    });
  }

  findHelpUrlPage() {
    const currentPage = "/#_3_1_attendance_action";
    window.open(this.helpService.HELP_BASE_URL + currentPage, "_blank");

  }
}