import { Component, OnInit, ViewChild } from '@angular/core';
import { LeaveTypeService } from '../../core/services/leave-type.service';
import { FormControl } from '@angular/forms';
import { LeaveTypeModel } from '../../shared/models/leave-type-model';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { HelpService } from '../../core/services/help.service';
import { EmployeeLeaveStatModel } from 'src/app/shared/models/employee-leave-stat-model';
import { LeaveRequestService } from 'src/app/core/services/leave-request.service';


@Component({
  selector: 'app-employee-leave-state',
  templateUrl: './employee-leave-state.component.html',
  styleUrls: ['./employee-leave-state.component.css']
})
export class EmployeeLeaveStateComponent implements OnInit {

  leaveBalance = [];
  leaveTotal: number = 0;
  displayedColumns = ['id', 'employeeDTOLite.firstName', 'leaveTypeName', 'leaveCount'];
  dataSource: MatTableDataSource<EmployeeLeaveStatModel>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  sortingDataAccessor(item, property) {
    if (property.includes('.')) {
      return property.split('.')
        .reduce((object, key) => object[key], item);
    }
    return item[property];
  }

  constructor(
    private leaveTypeService: LeaveTypeService,
    private helpService: HelpService,
    private leaveRequestService: LeaveRequestService
  ) { }

  ngOnInit() {
    this.fetchAllEmployeeLeaveBalance();
   
  }

  fetchAllEmployeeLeaveBalance() {
    this.leaveRequestService.fetchAllEmployeeLeaveBalance().subscribe((res: EmployeeLeaveStatModel[]) => {
     // this.leaveBalance = res;
     this.dataSource = new MatTableDataSource<EmployeeLeaveStatModel>(res);
     this.dataSource.paginator = this.paginator;
     this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
     this.dataSource.sort = this.sort;
      var total = 0;
      this.leaveBalance.forEach(function (value) {
        total += value.leaveCount;
      })
      this.leaveTotal = total;
    });
  }

 

  findHelpUrlPage() {
    const currentPage = "/#_4_4_employee_leave_state";
    window.open(this.helpService.HELP_BASE_URL + currentPage, "_blank");

  }
}


