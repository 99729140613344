import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { LeaveRequestService } from '../../core/services/leave-request.service';
import { FormControl, FormGroup } from '@angular/forms';
import { LeaveRequestModel, LeaveRequestBuilder } from '../../shared/models/leave-request-model';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { LeaveActionBuilder, LeaveActionModel } from 'src/app/shared/models/leave-action-model';
import { HelpService } from '../../core/services/help.service';
import { MessageService } from 'src/app/shared/factory/message.service';
import { Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { HistoricalLeaveRequestBuilder, HistoricalLeaveRequestModel } from 'src/app/shared/models/historical-leave-request-model';
import { LeaveRequestApproverService } from 'src/app/core/services/leave-request-approver.service';
import { HistoricalLeaveComponent } from 'src/app/shared/popups/popups';
import { LoaderService } from 'src/app/shared/factory/loader.service';
import { OrganizationModel } from 'src/app/shared/models/organization-model';
import { OrganizationService } from 'src/app/core/services/organization.service';
import { FinancialYearService } from 'src/app/core/services/financial-year-service.service';
import { FinancialYearModel } from 'src/app/shared/models/financial-year-model';
import { fetchDisplayData, fetchMultiDisplayData, dateTimeFormatters } from 'src/app/shared/factory/common.service';
import { UserService } from 'src/app/core/services/user.service';
import { UserModel } from 'src/app/shared/models/user-model';

@Component({
  selector: 'app-historical-leave-request',
  templateUrl: './historical-leave-request.component.html',
  styleUrls: ['./historical-leave-request.component.css']
})
export class HistoricalLeaveRequestComponent implements OnInit {
  @ViewChild('sidenav') sidenav: MatSidenav;
  totalCount= 0;
  pageNo: number = 0;
  pageSize: number = 10;
  DateTimeFormatters = dateTimeFormatters;
  fetchDisplayData = fetchDisplayData;
  fetchMultiDisplayData = fetchMultiDisplayData;
  
  historicalLeaveRequest: HistoricalLeaveRequestBuilder = new HistoricalLeaveRequestBuilder();
  historicalLeaveRequestModel: HistoricalLeaveRequestModel = this.historicalLeaveRequest.historicalLeaveRequestModel;
  historicalLeaveRequestForm: FormGroup = this.historicalLeaveRequest.historicalLeaveRequestForm;
  
  displayedColumns = ['id', 'employeeLite','leaveTypeName', 'leaveRequestApprovers', 'leaveRequestState', 'totalLeaveCount', 'leaveDateRange', 'action'];
  dataSource: MatTableDataSource<HistoricalLeaveRequestModel>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  searchFormData = {
    financialYearId: "",
    fromLeaveDate: null,
    toLeaveDate:null
  };
  searchFormFields: FormGroup = new FormGroup({
    financialYearId: new FormControl(this.searchFormData.financialYearId),
    fromLeaveDate: new FormControl(this.searchFormData.fromLeaveDate),
    toLeaveDate: new FormControl(this.searchFormData.toLeaveDate)
  });
 
  searched = false;
  expanded = true;
  financialYears = [];
  approvers = [];
  constructor(
    private leaveRequestService: LeaveRequestService,
    private leaveRequestApproverService: LeaveRequestApproverService,
    private organizationService: OrganizationService,
    private helpService: HelpService,
    private userService: UserService,
    private financialYearService: FinancialYearService,
    public messageService: MessageService,
    private eleRef: ElementRef,
    public router: Router,
    private dialog: MatDialog,
    private loaderService: LoaderService,

  ) { }

  ngOnInit() {
    this.fetchFinancialYear();
    this.userService.fetchLeaveApproversEmployeesLite().subscribe((res: UserModel[]) => {
      this.approvers = res;
    });
   this.fetchHistoricalLeaveRequest();
   
    //this.fetchHistoricalLeaveReq(this.pageNo, this.pageSize);
    this.dataSource = new MatTableDataSource<HistoricalLeaveRequestModel>([]);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
   
    // this.paginator.page.subscribe(options => {
    //   this.pageNo = options.pageIndex;
    //   this.pageSize = options.pageSize;
    //   this.searched ? this.searchHistoricalLeave() : this.fetchHistoricalLeaveReq(this.pageNo, this.pageSize);
    // });
  }

   fetchHistoricalLeaveRequest() {
    this.leaveRequestApproverService.fetchHistoricalLeaveRequest(this.searchFormData).subscribe((res: HistoricalLeaveRequestModel[]) => {
       this.dataSource = new MatTableDataSource<HistoricalLeaveRequestModel>(res);
      this.dataSource.paginator = this.paginator;
     this.dataSource.sort = this.sort;
    });
   }

  
  fetchFinancialYear() {
    this.financialYearService.fetchFinancialYears().subscribe((res: FinancialYearModel[]) => {
      this.financialYears = res;
    });
  }
 

  openLeaveActionDialog(leaveRequestId) {
    const dialogRef = this.dialog.open(HistoricalLeaveComponent, {
      data: { leaveRequestId }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'applied') {
        this.loaderService.updateInboxAction(true);
      };
    });
  }

  openSidenav() {
    this.eleRef.nativeElement.offsetParent.classList.add('scrollHide');
    this.sidenav.open();
  }

  closeSidenav() {
    this.eleRef.nativeElement.offsetParent.classList.remove('scrollHide');
    this.sidenav.close();
  }
  
  searchHistoricalLeave() {
    this.searchFormData = this.searchFormFields.value;
    this.searchFormData.fromLeaveDate ? (this.searchFormData.fromLeaveDate = this.DateTimeFormatters.formatDate(this.searchFormData.fromLeaveDate) )  : null;	  
    this.searchFormData.toLeaveDate ? (this.searchFormData.toLeaveDate = this.DateTimeFormatters.formatDate(this.searchFormData.toLeaveDate) ) : null;
    let obj = {};
    this.searchFormData.financialYearId ? (obj['financialYearId'] = this.searchFormData.financialYearId) : null;
    this.searchFormData.fromLeaveDate? (obj['fromLeaveDate'] = this.searchFormData.fromLeaveDate) : null;
    this.searchFormData.toLeaveDate ? (obj['toLeaveDate'] = this.searchFormData.toLeaveDate) : null;
    
    obj != {} ?  this.leaveRequestApproverService.fetchHistoricalLeaveRequest(obj).subscribe(resp => {
        this.dataSource = new MatTableDataSource<HistoricalLeaveRequestModel>(resp);
        // this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.searched = true;
        this.closeSidenav();
      }) : this.messageService.display('No records found!', 'OK', 'warn');
      
   
  }

  refreshSearch() {
    let canBeSearched = false;
    this.searchFormData.financialYearId ? (canBeSearched = true) : null;
    this.searchFormData.fromLeaveDate ? (canBeSearched = true) : null;
    this.searchFormData.toLeaveDate ? (canBeSearched = true) : null;
	
    if (canBeSearched) {
      this.searchHistoricalLeave();
    } else {
      this.searched = false;
      this.fetchHistoricalLeaveRequest();
    }
  }


  resetForm() {
    this.searchFormFields.reset({
      financialYearId: '',
      fromLeaveDate: null,
      toLeaveDate: null
    });
    this.searchFormData = this.searchFormFields.value;
    this.searchFormData.fromLeaveDate ? (this.searchFormData.fromLeaveDate = this.DateTimeFormatters.formatDate(this.searchFormData.fromLeaveDate)) : null;
    this.searchFormData.toLeaveDate ? (this.searchFormData.toLeaveDate = this.DateTimeFormatters.formatDate(this.searchFormData.toLeaveDate)) : null;
    if (this.searched) {
      this.searched = false;
      this.fetchHistoricalLeaveRequest();
    }
  }
  findHelpUrlPage() {
    const currentPage = "/#_2_3_historical_leave";
    window.open(this.helpService.HELP_BASE_URL + currentPage, "_blank");

  }
}
