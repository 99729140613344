import { Component, OnInit, ViewChild } from '@angular/core';
import { HolidayService } from '../../core/services/holiday.service';
import { HolidayModel, HolidayFormBuilder } from '../../shared/models/holiday-model';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FinancialYearConfigModel } from '../../shared/models/financial-year-config-model';
import { FinancialYearConfigService } from '../../core/services/financial-year-config-service.service';
import { Validators } from '@angular/forms';
import { MessageService } from '../../shared/factory/message.service';
import { Location } from '@angular/common';
import { OrganizationService } from '../../core/services/organization.service';
import { OrganizationModel } from 'src/app/shared/models/organization-model';
import { FinancialYearService } from 'src/app/core/services/financial-year-service.service';
import { FinancialYearModel } from 'src/app/shared/models/financial-year-model';
import { dateTimeFormatters } from 'src/app/shared/factory/common.service';


@Component({
  selector: 'app-holiday-add-edit',
  templateUrl: './holiday-add-edit.component.html',
  styleUrls: ['./holiday-add-edit.component.css']
})
export class HolidayAddEditComponent implements OnInit {

  holidayAddForm: HolidayFormBuilder = new HolidayFormBuilder();
  holidayModel: HolidayModel = {
    id: null,
    name: '',
    date: null,
    financialYearId: null
  };
  financialYears = [];
  dateTimeFormatters = dateTimeFormatters;
  

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private holidayService: HolidayService,
    private financialYearConfigService: FinancialYearConfigService,
    private financialYearService: FinancialYearService,
    private organizationService: OrganizationService,
    private messageService: MessageService,
    public location: Location
  ) { }

  ngOnInit() {
    this.fetchFinancialYear();
    //this.holidayModel.financialYearId = 1;
   //this.holidayModel.financialYearId ? this.fetchHolidays() : null;
   var currentFinancialYearInstanceId = '';
   this.organizationService.fetchOrganization().subscribe((res: OrganizationModel[]) => {
    this.holidayModel.financialYearId = res[0].currentFinancialYearInstanceId;
    //this.fetchHolidays();
    this.holidayModel.id = parseInt(this.route.snapshot.paramMap.get('id'), 0);
    this.holidayModel.id ? this.fetchHolidays() : null;
    
   });

  }

   fetchFinancialYear() {
     this.financialYearService.fetchFinancialYears().subscribe((res: FinancialYearModel[]) => {
       this.financialYears = res;
     });
   }
  

   fetchHolidays() {
    this.holidayService.fetchHoliday( this.holidayModel.financialYearId).subscribe((res: HolidayModel) => {
       this.holidayAddForm.name.setValue(res.name);
      this.holidayAddForm.date.setValue(res.date);
     });
   }
  
  addUpdateHoliday() {
    this.holidayModel.name = this.holidayAddForm.name.value;
     var date1 =  this.holidayAddForm.date.value;
    this.holidayModel.date = this.dateTimeFormatters.formatDate(date1);
    this.holidayModel.financialYearId = this.holidayAddForm.financialYearId.value;
    
    //this.holidayModel.employeeLite = this.holidayForm.employeeLite.value;

   if (this.holidayModel.id) {
      this.holidayService.updateHoliday(this.holidayModel).subscribe((res) => {
        this.messageService.display('Holiday successfully updated!  ' , 'OK', 'success');
        this.router.navigate(['/holidays']);
      });
    } else {
      this.holidayService.addHoliday(this.holidayModel).subscribe((res) => {
        this.messageService.display('Holiday successfully added! ' , 'OK', 'success');
        this.router.navigate(['/holidays']);
      });
    }
  }


}
