import { FormControl, FormGroup, Validators } from "@angular/forms";


export interface LeaveEncashmentRequestModel {
    id: number;
    leaveTypeId: number;
    leaveRequestApprover: Array<any>;
    leaveEncashmentCount: number;
    leaveEncashmentRequestState: String;
    teamNotificationEmail: string,
    employeeDTOLite: {
        id: number,
        firstName: string,
        lastName: string,
        fullName: String,
    },
    financialYear: String;
}

export class LeaveEncashmentRequestBuilder {
    leaveEncashmentRequestModel: LeaveEncashmentRequestModel = {
        id: null,
        leaveTypeId: null,
        leaveRequestApprover: [],
        leaveEncashmentCount: null,
        leaveEncashmentRequestState: null,
        financialYear: '',
        teamNotificationEmail: "",
        employeeDTOLite: {
            id: 0,
            firstName: "",
            lastName: "",
            fullName: undefined
        }
    };

    leaveEncashmentRequestForm: FormGroup = new FormGroup({
        leaveType: new FormControl(this.leaveEncashmentRequestModel.leaveTypeId, [Validators.required]),
        leaveRequestApprover: new FormControl(this.leaveEncashmentRequestModel.leaveRequestApprover, [Validators.required]),
        teamNotificationEmail: new FormControl(this.leaveEncashmentRequestModel.teamNotificationEmail),
        leaveEncashmentCount: new FormControl(this.leaveEncashmentRequestModel.leaveEncashmentCount, [Validators.required]),
        employeeDTOLite: new FormGroup({
            id: new FormControl(this.leaveEncashmentRequestModel.employeeDTOLite.id),
            firstName: new FormControl(this.leaveEncashmentRequestModel.employeeDTOLite.firstName),
            lastName: new FormControl(this.leaveEncashmentRequestModel.employeeDTOLite.lastName),
            fullName: new FormControl(this.leaveEncashmentRequestModel.employeeDTOLite.firstName + this.leaveEncashmentRequestModel.employeeDTOLite.lastName ),
        })
       
    });

    constructor() {
        this.leaveEncashmentRequestForm.valueChanges.subscribe(val => {
            this.leaveEncashmentRequestModel.leaveTypeId = val.leaveType;
            this.leaveEncashmentRequestModel.leaveRequestApprover = val.leaveRequestApprover;
            this.leaveEncashmentRequestModel.leaveEncashmentCount = val.leaveEncashmentCount;
            this.leaveEncashmentRequestModel.leaveEncashmentRequestState = val.leaveEncashmentRequestState;
            this.leaveEncashmentRequestModel.financialYear = val.financialYear;
        });
    }
}

