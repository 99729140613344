import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { HelpService } from 'src/app/core/services/help.service';
import { LinkCalendarFormBuilder, LinkCalendarModel } from 'src/app/shared/models/leave-calendar-model';
import { Location } from '@angular/common';
import { UserCalendarService } from 'src/app/core/services/user-calendar.service';
import { MessageService } from 'src/app/shared/factory/message.service';

@Component({
  selector: 'app-link-calendar-add-edit',
  templateUrl: './link-calendar-add-edit.component.html',
  styleUrls: ['./link-calendar-add-edit.component.css'],
})
export class LinkCalendarAddEditComponent implements OnInit {

  linkCalendar: LinkCalendarFormBuilder = new LinkCalendarFormBuilder();
  linkCalendarModel: LinkCalendarModel = this.linkCalendar.linkCalendarModel;
  linkCalendarForm: FormGroup = this.linkCalendar.linkCalendarForm;
  userCalendarsData = [];
  isImplemented = true;

  constructor(public location: Location,
    private helpService: HelpService,
    private userCalendarService: UserCalendarService,
    private messageService: MessageService,
    private router: Router) { }

  ngOnInit(): void {
    this.fetchUserCalendars();
  }

  fetchUserCalendars() {
    this.userCalendarService.fetchUserCalendars(['HOLIDAY', 'LEAVE', 'WFH']).subscribe((res) => {
      res.filter(value => {
        this.userCalendarsData.push(value.calendarEventType);
      })
    });
  }

  findHelpUrlPage() {
    const currentPage = "/#_4_1_add_job_description";
    window.open(this.helpService.HELP_BASE_URL + currentPage, "_blank");
  }

  connectToUserCalendar() {
    if (this.userCalendarsData.indexOf(this.linkCalendarModel.calendarEventType) === -1) {
      this.userCalendarService.connectToUserCalendar(this.linkCalendarModel).subscribe((res) => {
        this.messageService.display('User Calendar linked successfully. ', 'OK', 'success');
        window.open(res.authenticationUrl);
        this.router.navigate(['/link-calendar']);
      });
    }
    else {
      this.messageService.display('Calendar Integration already exist', 'OK', 'error')
    }

  }

  selectedValueChange(value) {
    if (value == 'GOOGLE') {
      this.isImplemented = true;
    }
    else {
      this.isImplemented = false;
    }
  }

}
