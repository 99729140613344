import { Component, OnInit, ViewChild } from '@angular/core';
import { HolidayService } from '../../core/services/holiday.service';
import { HolidayModel, HolidayFormBuilder } from '../../shared/models/holiday-model';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FinancialYearConfigService } from '../../core/services/financial-year-config-service.service';
import { FinancialYearConfigModel,FinancialYearConfigFormBuilder } from 'src/app/shared/models/financial-year-config-model';
import { OrganizationService } from '../../core/services/organization.service';
import { OrganizationModel } from 'src/app/shared/models/organization-model';
import { HelpService } from '../../core/services/help.service';
import { MessageService } from 'src/app/shared/factory/message.service';

@Component({
  selector: 'app-holidays',
  templateUrl: './holidays.component.html',
  styleUrls: ['./holidays.component.css']
})
export class HolidaysComponent implements OnInit {
  holidayForm: HolidayFormBuilder = new HolidayFormBuilder();
  holidayModel: HolidayModel = {
    id: null,
    name: '',
    date: null,
    financialYearId: null
  };
  financialYearConfig: FinancialYearConfigFormBuilder = new FinancialYearConfigFormBuilder();
  financialYearConfigModel: FinancialYearConfigModel = this.financialYearConfig.financialYearConfigModel;
  displayedColumns = ['id','holidayName', 'date', 'action'];
  dataSource: MatTableDataSource<HolidayModel>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private holidayService: HolidayService,
    private helpService: HelpService,
    private financialYearConfigService: FinancialYearConfigService,
    private organizationService: OrganizationService,
    private messageService: MessageService
   
  ) { }

  ngOnInit() {


  }

  ngAfterViewInit(){
    //var currentFinancialYearInstanceId = null;
    this.organizationService.fetchOrganization().subscribe((res: OrganizationModel[]) => {
      this.holidayModel.financialYearId = res[0].currentFinancialYearInstanceId;
      this.fetchHolidays();
      this.dataSource = new MatTableDataSource<HolidayModel>([]);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
     });
  }

  fetchHolidays() {
    this.holidayService.fetchHolidays(this.holidayModel.financialYearId).subscribe((res: HolidayModel[]) => {
      this.dataSource = new MatTableDataSource<HolidayModel>(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  } 
  

  deleteHoliday(Id) {
    var retVal = confirm("Do you want to Delete Holiday?");
    if(retVal == true){
      
      this.holidayService.deleteHoliday(Id).subscribe((res) => {
        this.messageService.display('Holiday  successfully deleted !', 'OK', 'success');
        this.fetchHolidays();
    });    
     
    }
  }

  findHelpUrlPage(){
    const currentPage = "/#_4_1_manage_holidays";
    window.open(this.helpService.HELP_BASE_URL + currentPage, "_blank");
   
  }
}
