import { FormGroup, FormControl, Validators } from '@angular/forms';
import { customValidators } from '../factory/common.service';


export interface FinancialYearConfigModel {
    id: number,
    startDayOfMonth: number,
    startMonth: number,
    startYear: number,
    endDayOfMonth: number,
    endMonth: number,
    endYear: number
}
export class FinancialYearConfigFormBuilder {
    financialYearConfigModel: FinancialYearConfigModel = {
    id: 1, // default
    startDayOfMonth: null,
    startMonth: null,
    startYear: null,
    endDayOfMonth: null,
    endMonth: null,
    endYear: null
    };
   financialYearConfigForm: FormGroup = new FormGroup({
        startDayOfMonth: new FormControl(this.financialYearConfigModel.startDayOfMonth, [Validators.required]),
        startMonth: new FormControl(this.financialYearConfigModel.startMonth, [Validators.required]),
         startYear: new FormControl(this.financialYearConfigModel.startYear, [Validators.required]),
         endDayOfMonth: new FormControl(this.financialYearConfigModel.endDayOfMonth, [Validators.required]),
         endMonth: new FormControl(this.financialYearConfigModel.endMonth, [Validators.required]),
		 endYear: new FormControl(this.financialYearConfigModel.endYear, [Validators.required])
    });
    constructor() {
        this.financialYearConfigForm.valueChanges.subscribe(val => {
            this.financialYearConfigModel.startDayOfMonth = val.startDayOfMonth;
            this.financialYearConfigModel.startMonth = val.startMonth;
            this.financialYearConfigModel.startYear = val.startYear;
            this.financialYearConfigModel.endDayOfMonth = val.endDayOfMonth;
            this.financialYearConfigModel.endMonth = val.endMonth;
            this.financialYearConfigModel.endYear = val.endYear; 
        });
    }
    setModelVals(res: FinancialYearConfigModel[]) {
        this.financialYearConfigForm.controls.startDayOfMonth.setValue(res[0].startDayOfMonth);
        this.financialYearConfigForm.controls.startMonth.setValue(res[0].startMonth);
        this.financialYearConfigForm.controls.startYear.setValue(res[0].startYear);
        this.financialYearConfigForm.controls.endDayOfMonth.setValue(res[0].endDayOfMonth);
        this.financialYearConfigForm.controls.endMonth.setValue(res[0].endMonth);
        this.financialYearConfigForm.controls.endYear.setValue(res[0].endYear);
     
    }
}