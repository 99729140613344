import { Injectable } from '@angular/core';
import { HttpRequestService } from '../http/http-request.service';
import { CarryForwardModel } from 'src/app/shared/models/carry-forward-model';
import { Observable } from 'rxjs';
import { CarryForwardDetailModel } from 'src/app/shared/models/carry-forward-detail-model';

@Injectable({
  providedIn: 'root'
})
export class CarryForwardService {

  private stateUrl = 'api/v1/carry-forward-request';

  constructor(
    private httpRequest: HttpRequestService
  ) { }

  fetchCarryForwardList(): Observable<CarryForwardModel[]> {
    return this.httpRequest.get(this.stateUrl + '/');
  }

  fetchCarryForwardDetailList(carryForwardId: String): Observable<CarryForwardDetailModel[]> {
    return this.httpRequest.get(this.stateUrl + '/detail/' + carryForwardId);
  }

}
