import { FormControl, FormGroup } from "@angular/forms";
import { MissingAttendance } from "./leave-action-model";

export interface WfhApplyRequestModel{
    id: number,
    reasonForWfhRequest: string,
    wfhType:string,
    wfhRequestApprovers: any,
    totalWfhCount: number,
    wfhFromDate: Date,
    wfhToDate: Date,
    approverActionComment: string,
    missingAttendance: string
}




export class WfhApplyRequestBuilder{
    WfhApplyRequestModel: WfhApplyRequestModel = {
        id: null,
        reasonForWfhRequest: '',
        wfhType: '',
        wfhRequestApprovers: [],
        totalWfhCount: null,
        wfhFromDate: null,
        wfhToDate: null,
        approverActionComment: '',
        // wfhRequestDetailDTOs: []
        missingAttendance: MissingAttendance.MISSINGATTENDANCE
    };

    wfhApplyRequestForm: FormGroup = new FormGroup({
        reasonForWfhRequest: new FormControl(this.WfhApplyRequestModel.reasonForWfhRequest),
        wfhType : new FormControl(this.WfhApplyRequestModel.wfhType),
        wfhRequestApprovers: new FormControl(this.WfhApplyRequestModel.wfhRequestApprovers),
        totalwfhCount: new FormControl(this.WfhApplyRequestModel.totalWfhCount),
        wfhFromDate: new FormControl(this.WfhApplyRequestModel.wfhFromDate),
        wfhToDate: new FormControl(this.WfhApplyRequestModel.wfhToDate),
        approverActionComment: new FormControl(this.WfhApplyRequestModel.approverActionComment)
        // wfhRequestDetailDTOs: new FormArray([])
    })

    constructor(){
        this.wfhApplyRequestForm.valueChanges.subscribe(val =>{
            this.WfhApplyRequestModel.reasonForWfhRequest = val.reasonForWfhRequest;
            this.WfhApplyRequestModel.wfhType = val.wfhType;
            this.WfhApplyRequestModel.wfhRequestApprovers = val.wfhRequestApprovers;
            this.WfhApplyRequestModel.totalWfhCount = val.totalWfhCount;
            this.WfhApplyRequestModel.wfhFromDate = val.wfhFromDate;
            this.WfhApplyRequestModel.wfhToDate = val.wfhToDate;
            this.WfhApplyRequestModel.approverActionComment = val.approverActionComment;
            this.WfhApplyRequestModel.missingAttendance = val.missingAttendance;
        });
    }
    setModelVals(res: WfhApplyRequestModel){
        this.wfhApplyRequestForm.controls.reasonForWfhRequest.setValue(res.reasonForWfhRequest);
        this.wfhApplyRequestForm.controls.wfhType.setValue(res.wfhType);
        this.wfhApplyRequestForm.controls.wfhRequestApprovers.setValue(res.wfhRequestApprovers);
        this.wfhApplyRequestForm.controls.totalWfhCount.setValue(res.totalWfhCount);
        this.wfhApplyRequestForm.controls.approverActionComment.setValue(res.approverActionComment);
        this.wfhApplyRequestForm.controls.wfhFromDate.setValue(res.wfhFromDate.toLocaleDateString());
        this.wfhApplyRequestForm.controls.wfhToDate.setValue(res.wfhToDate.toLocaleDateString());
    //    for (var i = 0; i < res.wfhRequestDetailDTOs.length; i++) {
    //         let wfhDetailFormGroup = new FormGroup({
    //             wfhDate: new FormControl(res.wfhRequestDetailDTOs[i].wfhDate),
    //         })
    //         let wfhDetailArray = this.wfhRequestActionForm.get('wfhRequestDetailDTOs') as FormArray;
    //         wfhDetailArray.push(wfhDetailFormGroup);
    //     }
    }
}