import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppConfigService } from 'src/app/configs';

export interface LinkCalendarModel {
    id: number;
    calendarType: string;
    googleRedirectUrl: string;
    calendarEventType: string
}
export class LinkCalendarFormBuilder {
    configs = new AppConfigService();
    linkCalendarModel: LinkCalendarModel = {
        id: null,
        calendarType: '',
        googleRedirectUrl: this.configs.tempApiPath+"api/v1/google-calendar/receive-token",
        calendarEventType: ''
    };
    linkCalendarForm: FormGroup = new FormGroup({
        calendarType: new FormControl(this.linkCalendarModel.calendarType, [Validators.required]),
        calendarEventType: new FormControl(this.linkCalendarModel.calendarEventType, [Validators.required]),
    });
    constructor() {
        this.linkCalendarForm.valueChanges.subscribe(val => {
            this.linkCalendarModel.calendarType = val.calendarType;
            this.linkCalendarModel.calendarEventType = val.calendarEventType;
        });
    }
    setModelVals(res: LinkCalendarModel) {
        this.linkCalendarForm.controls.calendar_type.setValue(res.calendarType);
        this.linkCalendarForm.controls.calendarEventType.setValue(res.calendarEventType);
    }

    
}