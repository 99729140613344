import{FormGroup,FormControl,Validator, Validators, FormArray } from '@angular/forms';


export interface WfhRequestActionModel {
    wfhRequestId: number,
    reasonForWfhRequest: string,
    wfhType:string,
    wfhRequestApprovers: any,
    totalWfhCount: number,
    wfhFromDate: Date,
    wfhToDate: Date,
    wfhRequestStatus: string,
    approverActionComment: string
    userId: number,
    listOfUserForNotificationReference: Array<any>;
    wfhRequestedDate: Date
}

export class WfhRequestActionBuilder{
    WfhRequestActionModel: WfhRequestActionModel = {
        wfhRequestId: null,
        reasonForWfhRequest: '',
        wfhType:'',
        wfhRequestApprovers: [],
        totalWfhCount: null,
        wfhFromDate:null,
        wfhToDate:null,
        approverActionComment: '',
        wfhRequestStatus:'',
        userId: null,
        listOfUserForNotificationReference:[],
        wfhRequestedDate: null,
        // wfhRequestDetailDTOs: []
    };

    wfhRequestActionForm: FormGroup = new FormGroup({
        wfhRequestId : new FormControl(this.WfhRequestActionModel.wfhRequestId),
        reasonForWfhRequest: new FormControl(this.WfhRequestActionModel.reasonForWfhRequest),
        wfhType : new FormControl(this.WfhRequestActionModel.wfhType),
        wfhRequestApprovers: new FormControl(this.WfhRequestActionModel.wfhRequestApprovers),
        totalwfhCount: new FormControl(this.WfhRequestActionModel.totalWfhCount),
        wfhFromDate: new FormControl(this.WfhRequestActionModel.wfhFromDate),
        wfhToDate: new FormControl(this.WfhRequestActionModel.wfhToDate),
        approverActionComment: new FormControl(this.WfhRequestActionModel.approverActionComment),
        wfhRequestStatus:new FormControl(this.WfhRequestActionModel.wfhRequestStatus),
        userId:new FormControl(this.WfhRequestActionModel.userId,Validators.required),
        listOfUserForNotificationReference: new FormControl(this.WfhRequestActionModel.listOfUserForNotificationReference),
        wfhRequestedDate:new FormControl(this.WfhRequestActionModel.wfhRequestedDate)
        // wfhRequestDetailDTOs: new FormArray([])
    });
    wfhRequestModel : WfhRequestActionBuilder;
    constructor(){
        this.wfhRequestActionForm.valueChanges.subscribe(val =>{
            this.WfhRequestActionModel.wfhRequestId = val.wfhRequestId;
            this.WfhRequestActionModel.reasonForWfhRequest = val.reasonForWfhRequest;
            this.WfhRequestActionModel.wfhType = val.wfhType;
            this.WfhRequestActionModel.wfhRequestApprovers = val.wfhRequestApprovers;
            this.WfhRequestActionModel.totalWfhCount = val.totalWfhCount;
            this.WfhRequestActionModel.wfhFromDate = val.wfhFromDate;
            this.WfhRequestActionModel.wfhToDate = val.wfhToDate;
            this.WfhRequestActionModel.approverActionComment = val.approverActionComment;
            this.WfhRequestActionModel.wfhRequestStatus = val.wfhRequestStatus;
            this.WfhRequestActionModel.userId = val.userId;
            // this.WfhRequestActionModel.wfhRequestDetailDTOs = val.wfhRequestDetailDTOs;
            this.WfhRequestActionModel.listOfUserForNotificationReference = val.listOfUserForNotificationReference;
            this.WfhRequestActionModel.wfhRequestedDate  = val.wfhRequestedDate;
        
        });
    }
    setModelVals(res: WfhRequestActionModel){
        this.wfhRequestActionForm.controls.wfhRequestId.setValue(res.wfhRequestId);
        this.wfhRequestActionForm.controls.reasonForWfhRequest.setValue(res.reasonForWfhRequest);
        this.wfhRequestActionForm.controls.wfhType.setValue(res.wfhType);
        this.wfhRequestActionForm.controls.wfhRequestApprovers.setValue(res.wfhRequestApprovers);
        this.wfhRequestActionForm.controls.totalWfhCount.setValue(res.totalWfhCount);
        this.wfhRequestActionForm.controls.approverActionComment.setValue(res.approverActionComment);
        this.wfhRequestActionForm.controls.wfhFromDate.setValue(res.wfhFromDate);
        this.wfhRequestActionForm.controls.wfhToDate.setValue(res.wfhToDate);
        this.wfhRequestActionForm.controls.wfhRequestStatus.setValue(res.wfhRequestStatus);
        this.wfhRequestActionForm.controls.userId.setValue(res.userId);
        this.wfhRequestActionForm.controls.listOfUserForNotificationReference.setValue(res.listOfUserForNotificationReference);
        this.wfhRequestActionForm.controls.wfhRequestedDate.setValue(res.wfhRequestedDate);
    //    for (var i = 0; i < res.wfhRequestDetailDTOs.length; i++) {
    //         let wfhDetailFormGroup = new FormGroup({
    //             wfhDate: new FormControl(res.wfhRequestDetailDTOs[i].wfhDate),
    //         })
    //         let wfhDetailArray = this.wfhRequestActionForm.get('wfhRequestDetailDTOs') as FormArray;
    //         wfhDetailArray.push(wfhDetailFormGroup);
    //     }
    }
}


export interface CancelWfhRequestModel {
    reasonForWfhRequestCancel: string,
     wfhRequestId: number,
     wfhRequestStatus: string,
 }

export class CancelWfhRequestBuilder{
    cancelWfhRequestModel: CancelWfhRequestModel = {
        reasonForWfhRequestCancel:'',
        wfhRequestId: null,
        wfhRequestStatus: '',
    };

    cancelWfhRequestForm: FormGroup = new FormGroup({
        reasonForWfhRequestCancel: new FormControl(this.cancelWfhRequestModel.reasonForWfhRequestCancel,[Validators.required]),
        wfhRequestId: new FormControl(this.cancelWfhRequestModel.wfhRequestId,[Validators.required]),
        wfhRequestStatus:new FormControl(this.cancelWfhRequestModel.wfhRequestStatus,[Validators.required]),
    });
    // wfhRequestModel: CancelWfhRequestModel;
    constructor(){
        this.cancelWfhRequestForm.valueChanges.subscribe(val =>{
            this.cancelWfhRequestModel.reasonForWfhRequestCancel = val.reasonForWfhRequestCancel;
            this.cancelWfhRequestModel.wfhRequestId = val.wfhRequestId;
            this.cancelWfhRequestModel.wfhRequestStatus = val.wfhRequestStatus;
        });
    }

    setModelVals(res: CancelWfhRequestModel){
        this.cancelWfhRequestForm.controls.reasonForWfhRequestCancel.setValue(res.reasonForWfhRequestCancel);
        this.cancelWfhRequestForm.controls.wfhRequestId.setValue(res.wfhRequestId);
        this.cancelWfhRequestForm.controls.wfhRequestStatus.setValue(res.wfhRequestStatus);
    }
}


export interface CancelApprovedWfhRequestActionModel{
    wfhRequestId: number,
    employeeId: number,
    // wfhRequestApprovers: any,
    // wfhRequestStatus: string,
    approverActionComment: string,
    reasonForWfhRequestCancel : string,
    listOfUserForNotificationReference: Array<any>
}
export class CancelApprovedWfhRequestActionBuilder{
    CancelApprovedWfhRequestActionModel: CancelApprovedWfhRequestActionModel ={
        wfhRequestId: null,
        employeeId: null,
        // wfhRequestApprovers: '',
        // wfhRequestStatus: '',
        listOfUserForNotificationReference: [],
        approverActionComment: '',
        reasonForWfhRequestCancel:''
    };
    CancelApprovedWfhRequestActionForm: FormGroup = new FormGroup({
        wfhRequestId: new FormControl(this.CancelApprovedWfhRequestActionModel.wfhRequestId,[Validators.required]),
        employeeId : new FormControl(this.CancelApprovedWfhRequestActionModel.employeeId,[Validators.required]),
        // wfhRequestApprovers: new FormControl(this.CancelApprovedWfhRequestActionModel.wfhRequestApprovers,[Validators.required]),
        // wfhRequestStatus:new FormControl(this.CancelApprovedWfhRequestActionModel.wfhRequestStatus,[Validators.required]),
        listOfUserForNotificationReference:new FormControl(this.CancelApprovedWfhRequestActionModel.listOfUserForNotificationReference),
        approverActionComment: new FormControl(this.CancelApprovedWfhRequestActionModel.approverActionComment),
        reasonForWfhRequestCancel: new FormControl(this.CancelApprovedWfhRequestActionModel.reasonForWfhRequestCancel),
    })

    constructor(){
        this.CancelApprovedWfhRequestActionForm.valueChanges.subscribe(val =>{
            this.CancelApprovedWfhRequestActionModel.wfhRequestId = val.wfhRequestId;
            this.CancelApprovedWfhRequestActionModel.employeeId = val.employeeId;
            // this.CancelApprovedWfhRequestActionModel.wfhRequestApprovers = val.wfhRequestApprovers;
            this.CancelApprovedWfhRequestActionModel.approverActionComment = val.approverActionComment;
            this.CancelApprovedWfhRequestActionModel.reasonForWfhRequestCancel = val.reasonForWfhRequestCancel;
            this.CancelApprovedWfhRequestActionModel.listOfUserForNotificationReference = val.listOfUserForNotificationReference;
            // this.CancelApprovedWfhRequestActionModel.wfhRequestStatus = val.wfhRequestStatus;
        });
    }
    setModelVals(res: CancelApprovedWfhRequestActionModel){
        this.CancelApprovedWfhRequestActionForm.controls.wfhRequestId.setValue(res.wfhRequestId);
        this.CancelApprovedWfhRequestActionForm.controls.employeeId.setValue(res.employeeId);
        this.CancelApprovedWfhRequestActionForm.controls.approverActionComment.setValue(res.approverActionComment);
        this.CancelApprovedWfhRequestActionForm.controls.reasonForWfhRequestCancel.setValue(res.reasonForWfhRequestCancel);
        // this.CancelApprovedWfhRequestActionForm.controls.wfhRequestApprovers.setValue(res.wfhRequestApprovers);
        this.CancelApprovedWfhRequestActionForm.controls.listOfUserForNotificationReference.setValue(res.listOfUserForNotificationReference);
        // this.CancelApprovedWfhRequestActionForm.controls.wfhRequestStatus.setValue(res.wfhRequestStatus);
    }
}