import { Injectable } from '@angular/core';
import { HttpRequestService } from '../http/http-request.service';
import { Observable } from 'rxjs';
import { FinancialYearConfigModel } from '../../shared/models/financial-year-config-model';

@Injectable({
  providedIn: 'root'
})
export class FinancialYearConfigService {
  private stateUrl = 'api/v1/fyconfig';

  constructor(
    private httpRequest: HttpRequestService
  ) { }

  fetchFinancialYears(): Observable<FinancialYearConfigModel[]> {
    return this.httpRequest.get(this.stateUrl + '');
  }
 
  updateFinancialYears(financialYearConfigModel: FinancialYearConfigModel): Observable<any> {
    return this.httpRequest.put(this.stateUrl, financialYearConfigModel);
  }
  fetchFinancialYear(): Observable<FinancialYearConfigModel> {
    return this.httpRequest.get(this.stateUrl + '');
  }

}
