import { Component, OnInit, ViewChild } from '@angular/core';
import { CarryForwardModel} from 'src/app/shared/models/carry-forward-model';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { CarryForwardService } from '../../core/services/carry-forward.service';
import { Router } from '@angular/router';
import { DataService } from 'src/app/shared/factory/data.service';


@Component({
  selector: 'app-carry-forward',
  templateUrl: './carry-forward.component.html',
  styleUrls: ['./carry-forward.component.css']
})
export class CarryForwardComponent implements OnInit {

  carryForwardId:string;
  displayedColumns = ['id', 'startDate', 'endDate', 'leaveTypeName', 'Action'];
  dataSource: MatTableDataSource<CarryForwardModel>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  

  constructor(
    private carryForwardService: CarryForwardService,
    private data: DataService,
    private router:Router
  ) { }

  ngOnInit() {
    this.listOfCarryForwardData();
  }
   getDetailOfCarryRequest(carryForward_Id) {
     this.carryForwardId = carryForward_Id;
      this.data.changeMessage(this.carryForwardId);
     this.router.navigate(['/carryForwardDetail']);
   }

  listOfCarryForwardData() {
    this.carryForwardService.fetchCarryForwardList().subscribe((res: CarryForwardModel[]) => {
      this.dataSource = new MatTableDataSource<CarryForwardModel>(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

}
