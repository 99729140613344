import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { HelpService } from 'src/app/core/services/help.service';
import { MissingAttendanceService } from 'src/app/core/services/missing-attendance.service';
import { MissingAttendanceActionBuilder, MissingAttendanceActionModel } from 'src/app/shared/models/missing-attendance-action-model';

@Component({
  selector: 'app-missing-attendance-action',
  templateUrl: './missing-attendance-action.component.html',
  styleUrls: ['./missing-attendance-action.component.css']
})
export class MissingAttendanceActionComponent implements OnInit {

  missingAttendanceActionBuilder: MissingAttendanceActionBuilder = new MissingAttendanceActionBuilder();
  missingAttendanceModel: MissingAttendanceActionModel = this.missingAttendanceActionBuilder.MissingAttendanceActionModel
  missingAttendanceForm: FormGroup = this.missingAttendanceActionBuilder.missingAttendanceActionForm;

  displayedColumns: string[] = ['id', 'actionDate', 'leaveDate', 'wfhDate', 'present_date', 'holiday', 'missingAttendanceStatus']
  dataSource: MatTableDataSource<MissingAttendanceActionModel>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public location: Location,
    public missingAttendanceService: MissingAttendanceService,
    private helpService: HelpService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.fetchMissingAttendance();
  }

  fetchMissingAttendance() {
    this.missingAttendanceService.fetchMissingAttendance().subscribe((res: MissingAttendanceActionModel[]) => {
      this.dataSource = new MatTableDataSource<MissingAttendanceActionModel>(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })
  }

  findHelpUrlPage() {
    const CurrentPage = "/missingAttendanceActionComponent";
    window.open(this.helpService.HELP_BASE_URL + CurrentPage, "_blank");
  }

  applyLeave(row, columnName: string) {
    switch (columnName) {
      case 'Leave':
        this.router.navigate(['leaveRequest/missingAttendance'], { state: { data: row, isMissingAttendance: true } });
        break;
      case 'Wfh':
        this.router.navigate(['applyWfh/missingAttendance'], { state: { data: row , isMissingAttendance: true} });
        break;
      case 'Attendance':  
        this.router.navigate(['applyAttendanceRegularisation/missingAttendance'], { state: { data: row, isMissingAttendance: true } });
        break;
      default:
        console.log('Default action');
        break;
    }
  }


}
