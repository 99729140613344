import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { customValidators } from '../factory/common.service';

export interface LeaveRequestActionModel {
   // id: number,
    approverActionComment: string,
    leaveRequestId: number,
    employeeId: number
    listOfUserForNotificationReference: Array<any>;
}
export class LeaveRequestActionBuilder {
    leaveRequestActionModel: LeaveRequestActionModel = {
       // id: null,
        approverActionComment: '',
        leaveRequestId: null,
        employeeId: null,
        listOfUserForNotificationReference: []
    };
    leaveRequestActionForm: FormGroup = new FormGroup({
       approverActionComment: new FormControl(this.leaveRequestActionModel.approverActionComment),
       leaveRequestId: new FormControl(this.leaveRequestActionModel.leaveRequestId),
       employeeId: new FormControl(this.leaveRequestActionModel.employeeId),
       listOfUserForNotificationReference: new FormControl(this.leaveRequestActionModel.listOfUserForNotificationReference)
    });
  leaveRequestModel: LeaveRequestActionModel;
    constructor(){
        this.leaveRequestActionForm.valueChanges.subscribe(val => {
            this.leaveRequestActionModel.approverActionComment = val.approverActionComment;
            this.leaveRequestActionModel.leaveRequestId = val.leaveRequestId;
            this.leaveRequestActionModel.employeeId = val.employeeId;
            this.leaveRequestActionModel.listOfUserForNotificationReference = val.listOfUserForNotificationReference;
        });
    }

setModelVals(res: LeaveRequestActionModel){
    this.leaveRequestActionForm.controls.approverActionComment.setValue(res.approverActionComment);
    this.leaveRequestActionForm.controls.leaveRequestId.setValue(res.leaveRequestId);
    this.leaveRequestActionForm.controls.employeeId.setValue(res.employeeId);
    this.leaveRequestActionForm.controls.listOfUserForNotificationReference.setValue(res.listOfUserForNotificationReference);
}
}

export interface CancelApprovedLeaveRequestActionModel {
    leaveRequestId: number,
    employeeId: number,
    leaveRequestState: string,
    reviewerComment: string,
    listOfUserForNotificationReference: Array<any>
 }
 export class CancelApprovedLeaveRequestActionBuilder {
    cancelLeaveRequestActionModel: CancelApprovedLeaveRequestActionModel = {
        leaveRequestId: null,
         employeeId: null,
        leaveRequestState: '',
        reviewerComment: '',
        listOfUserForNotificationReference: []
     };
     leaveRequestActionForm: FormGroup = new FormGroup({
        leaveRequestState: new FormControl(this.cancelLeaveRequestActionModel.leaveRequestState),
        leaveRequestId: new FormControl(this.cancelLeaveRequestActionModel.leaveRequestId),
        employeeId: new FormControl(this.cancelLeaveRequestActionModel.employeeId),
        reviewerComment: new FormControl(this.cancelLeaveRequestActionModel.reviewerComment),
        listOfUserForNotificationReference: new FormControl(this.cancelLeaveRequestActionModel.listOfUserForNotificationReference),
     });
   leaveRequestModel: CancelApprovedLeaveRequestActionModel;
     constructor(){
         this.leaveRequestActionForm.valueChanges.subscribe(val => {
             this.cancelLeaveRequestActionModel.leaveRequestState = val.leaveRequestState;
             this.cancelLeaveRequestActionModel.leaveRequestId = val.leaveRequestId;
             this.cancelLeaveRequestActionModel.employeeId = val.employeeId;
             this.cancelLeaveRequestActionModel.listOfUserForNotificationReference = val.listOfUserForNotificationReference;
         });
     }
 
 setModelVals(res: CancelApprovedLeaveRequestActionModel){
     this.leaveRequestActionForm.controls.leaveRequestState.setValue(res.leaveRequestState);
     this.leaveRequestActionForm.controls.leaveRequestId.setValue(res.leaveRequestId);
     this.leaveRequestActionForm.controls.employeeId.setValue(res.employeeId);
     this.leaveRequestActionForm.controls.reviewerComment.setValue(res.reviewerComment);
     this.leaveRequestActionForm.controls.listOfUserForNotificationReference.setValue(res.listOfUserForNotificationReference);
 }
 }



 export interface CancelLeaveRequestModel {
    reasonForLeaveCancel: string,
     leaveRequestId: number,
 }
 export class CancelLeaveRequestBuilder {
    cancelLeaveRequestModel: CancelLeaveRequestModel = {
        reasonForLeaveCancel: '',
         leaveRequestId: null,
     };
     cancelLeaveRequestForm: FormGroup = new FormGroup({
        reasonForLeaveCancel: new FormControl(this.cancelLeaveRequestModel.reasonForLeaveCancel, Validators.required),
        leaveRequestId: new FormControl(this.cancelLeaveRequestModel.leaveRequestId),
     });
   leaveRequestModel: CancelLeaveRequestModel;
     constructor(){
         this.cancelLeaveRequestForm.valueChanges.subscribe(val => {
             this.cancelLeaveRequestModel.reasonForLeaveCancel = val.reasonForLeaveCancel;
             this.cancelLeaveRequestModel.leaveRequestId = val.leaveRequestId;
         });
     }
 
 setModelVals(res: CancelLeaveRequestModel){
     this.cancelLeaveRequestForm.controls.reasonForLeaveCancel.setValue(res.reasonForLeaveCancel);
     this.cancelLeaveRequestForm.controls.leaveRequestId.setValue(res.leaveRequestId);
 }
 }