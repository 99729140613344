import { Injectable } from '@angular/core';
import { HttpRequestService } from '../http/http-request.service';
import { Observable } from 'rxjs';
import { LeaveRequestModel } from '../../shared/models/leave-request-model';
import { LeaveActionModel } from '../../shared/models/leave-action-model';
import { EmployeeLeaveStatModel } from 'src/app/shared/models/employee-leave-stat-model';
import { AttendanceRequestActionBuilder, AttendanceRequestActionModel } from 'src/app/shared/models/attendance-request-action-model';
import { AttendanceApplyRequestModel } from 'src/app/shared/models/attendance-apply-request-model';
import { AttendanceRequestModel } from 'src/app/shared/models/attendance-request-model';

@Injectable({
  providedIn: 'root'
})
export class AttendanceRequestService {
  // for api call
  private stateUrl = 'api/v1/attendance-request';

  constructor(
    private httpRequest: HttpRequestService
    ) { }

  fetchAttendanceRequest(): Observable<AttendanceRequestModel[]> {
    return this.httpRequest.get(this.stateUrl + '/');
  }

  
  updateAttendanceRequest(AttendanceApplyRequestModel: AttendanceApplyRequestModel): Observable<any> {
    return this.httpRequest.put(this.stateUrl +"/attendance-update", AttendanceApplyRequestModel);
  }

  addAttendanceRequest(attendanceApplyModel: AttendanceApplyRequestModel): Observable<any> {
    return this.httpRequest.post(this.stateUrl, attendanceApplyModel);
  }

  fetchAttendanceByIdWithDetail(Id): Observable<AttendanceRequestModel> {
    return this.httpRequest.get(this.stateUrl + '/' + Id);
  }

  CancelAttendanceRequest(data): Observable<any> {
    return this.httpRequest.put(this.stateUrl + '/cancel' , data);
  }
  
  fetchAllAttendanceRequests(currentMonthStartDate, currentMonthLastDate): Observable<any> {
    return this.httpRequest.post('api/v1/attendance-request', { "startDate": currentMonthStartDate, "endDate": currentMonthLastDate });
  }

  cancelApprovedAttendanceRequest(data): Observable<any> {
    return this.httpRequest.put(this.stateUrl + '/cancel-approved-attendance-request' , data);
  }

  approvedAttendanceCancelRequestAction(data): Observable<any> {
    return this.httpRequest.post(this.stateUrl + '/approved-attendance-cancel-request-action', data);
  }
}
