import { Injectable } from '@angular/core';
import { HttpRequestService } from '../http/http-request.service';
import { Observable } from 'rxjs';
import { UserModel } from '../../shared/models/user-model';
import { FinancialYearConfigModel } from 'src/app/shared/models/financial-year-config-model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private stateUrl = 'api/v1/user';

  constructor(
    private httpRequest: HttpRequestService
  ) { }

  fetchUsers(): Observable<UserModel[]> {
    return this.httpRequest.get(this.stateUrl + '');
  }

  fetchEmployeesLite(): Observable<any> {
    return this.httpRequest.get(this.stateUrl + '/job-req-approvers');
  }

  fetchLeaveApproversEmployeesLite(): Observable<any> {
    return this.httpRequest.get(this.stateUrl + '/leave-req-approvers');
  }

  fetchLeaveEncashmentApproversEmployeesLite(): Observable<any> {
    return this.httpRequest.get(this.stateUrl + '/leave-req-encashment-approvers');
  }

  fetchWfhRequestApproversLite(): Observable<any>{
    return this.httpRequest.get(this.stateUrl + '/wfh-req-approvers');
  }

  fetchAttendanceRequestApproversLite(): Observable<any>{
    return this.httpRequest.get(this.stateUrl + '/attendance-req-approvers');
  }

  fetchUser(userId): Observable<UserModel> {
    return this.httpRequest.get(this.stateUrl + '/' + userId);
  }

  updateUser(userModel: UserModel): Observable<any> {
    return this.httpRequest.put(this.stateUrl, userModel);
  }

  addUser(userModel: UserModel): Observable<any> {
    return this.httpRequest.post(this.stateUrl, userModel);
  }

  fetchInboxCounts(): Observable<any> {
    return this.httpRequest.get(this.stateUrl + '/alert');
  }

  // below requests are needed to be moved to employee services

  fetchEmployees(): Observable<any> {
    return this.httpRequest.get('api/v1/employee/lite');
  }

  fetchNotUserEmployees(): Observable<any> {
    return this.httpRequest.get('api/v1/employee/notuser/lite');
  }

  checkLmsBoardingStatus(financialYearConfigModel: FinancialYearConfigModel): Observable<any> {
    return this.httpRequest.post('api/v1/tenant/boarding-process-lms', financialYearConfigModel);
  }
}
