import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LeaveEncashmentRequestService } from 'src/app/core/services/leave-encashment-request.service';
import { LeaveEncashmentRequestModel } from 'src/app/shared/models/leave-encashment-model';

@Component({
  selector: 'app-leave-encashment-request',
  templateUrl: './leave-encashment-request.component.html',
  styleUrls: ['./leave-encashment-request.component.css']
})
export class LeaveEncashmentRequestComponent implements OnInit {

  displayedColumn = ['id', 'totalLeaveCount', 'leaveEncashmentRequestState', 'financialYear'];
  dataSource: MatTableDataSource<LeaveEncashmentRequestModel>;
  
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  myForm: FormGroup

  constructor(private cdr: ChangeDetectorRef,
    private leaveEncashmentRequestService: LeaveEncashmentRequestService) {
  }

  ngOnInit(): void {
    this.fetchLeaveEncashmentRequest();
    this.dataSource = new MatTableDataSource<LeaveEncashmentRequestModel>([]);
    // this.cdr.detectChanges();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  fetchLeaveEncashmentRequest() {
    this.leaveEncashmentRequestService.fetchLeaveEncashmentRequest().subscribe((res: LeaveEncashmentRequestModel[]) => {
      res.filter(leaveEncashmentRequest => {
        let isDisabled = this.checkStatus(leaveEncashmentRequest.leaveEncashmentRequestState);
        leaveEncashmentRequest['isDisabled'] = isDisabled
      });
      this.dataSource = new MatTableDataSource<LeaveEncashmentRequestModel>(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  checkStatus(leaveRequestState) {
    let isDisabled = true;
    if (leaveRequestState === 'APPROVED') {
      isDisabled = false;
    }
    else if (leaveRequestState === 'PENDING_APPROVAL') {
      isDisabled = false;
    }
    return isDisabled;
  }
}
