import { Injectable } from '@angular/core';
import { HttpRequestService } from '../http/http-request.service';
import { Observable } from 'rxjs';
import { LeaveAdjustModel } from 'src/app/shared/models/leave-adjust-model';
import { EmployeeLeaveStatModel } from 'src/app/shared/models/employee-leave-stat-model';

@Injectable({
  providedIn: 'root'
})
export class LeaveAdjustService {
  private stateUrl = 'api/v1/leave-adjustment';
  constructor(
    private httpRequest: HttpRequestService
  ) { }

  fetchAllEmployeeLeaveBalance():  Observable<EmployeeLeaveStatModel[]> {
    return this.httpRequest.get(this.stateUrl + '/employee-leave-stats');
  }
  
  fetchLeaveType(): Observable<LeaveAdjustModel[]>{
    return this.httpRequest.get(this.stateUrl+'/getAll');
  }

  saveLeaveAdjustmentRequest(requestBody : any) : Observable<any> {
    return this.httpRequest.post(this.stateUrl + '/', requestBody);
  }
   
}
