import { Component, OnInit ,ViewChild} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { CancelApprovedWfhRequestActionBuilder, CancelApprovedWfhRequestActionModel, CancelWfhRequestBuilder, CancelWfhRequestModel, WfhRequestActionBuilder ,WfhRequestActionModel} from 'src/app/shared/models/wfh-request-action-model';
import { FormGroup } from '@angular/forms';
import { wfhRequestModel } from 'src/app/shared/models/wfh-request-model';
import { WfhRequestService } from 'src/app/core/services/wfh-request.service';
import { HelpService } from 'src/app/core/services/help.service';
import { MessageService } from 'src/app/shared/factory/message.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { LoaderService } from 'src/app/shared/factory/loader.service';
import { CancelWfhApprovedComponent } from 'src/app/shared/popups/wfh-popups';

@Component({
  selector: 'app-wfh-request',
  templateUrl: './wfh-request.component.html',
  styleUrls: ['./wfh-request.component.css']
})
export class WfhRequestComponent implements OnInit {

  wfhActionReq: WfhRequestActionBuilder = new WfhRequestActionBuilder();
  wfhActionModel: WfhRequestActionModel = this.wfhActionReq.WfhRequestActionModel;
  wfhActionRequestForm: FormGroup = this.wfhActionReq.wfhRequestActionForm;

    cancelWfhRequestBuiler : CancelWfhRequestBuilder = new CancelWfhRequestBuilder();
    cancelWfhRequestModel : CancelWfhRequestModel = this.cancelWfhRequestBuiler.cancelWfhRequestModel;
    cancelWfhRequestForm: FormGroup = this.cancelWfhRequestBuiler.cancelWfhRequestForm;

    cancelApprovedWfhRequestActionBuilder : CancelApprovedWfhRequestActionBuilder = new CancelApprovedWfhRequestActionBuilder();
    cancelApprovedWfhRequestActionModel : CancelApprovedWfhRequestActionModel = this.cancelApprovedWfhRequestActionBuilder.CancelApprovedWfhRequestActionModel;
    cancelApprovedWfhRequestActionForm : FormGroup = this.cancelApprovedWfhRequestActionBuilder.CancelApprovedWfhRequestActionForm;

  displayedColumns = ['id', 'reasonForWfhRequest', 'wfhRequestStatus','wfhDateRange','isDisabled','action','totalWfhCount'];
  
   dataSource: MatTableDataSource<wfhRequestModel>;s

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private wefRequestService: WfhRequestService,
    private helpService: HelpService,
    public messageService: MessageService,
    public router: Router,
    public dialog: MatDialog,
    public loaderService: LoaderService,
  ) { }

  ngOnInit(){
    this.fetchWfhRequest();
    this.dataSource = new MatTableDataSource<wfhRequestModel>([]);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  

  fetchWfhRequest(){
    this.wefRequestService.fetchWfhRequest().subscribe((res: wfhRequestModel[]) => {
      res.filter(element => {
        let isDisabled = this.checkStatus(element.wfhRequestStatus);
        element['isDisabled'] = isDisabled
      });
      this.dataSource = new MatTableDataSource<wfhRequestModel>(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }
  cancelWfhRequestAction(wfhRequestId, wfhRequestStatus,reasonForWfhRequest){
    if(wfhRequestStatus == 'APPROVED'){
      this.cancelApprovedWfhRequestAction(wfhRequestId,wfhRequestStatus)
    }
    else{
      var retVal = confirm("Do you want to Cancel Work from Home Request?");
      this.cancelWfhRequestModel.wfhRequestId = wfhRequestId;
      this.cancelWfhRequestModel.reasonForWfhRequestCancel = reasonForWfhRequest;
      if(retVal == true){
        this.wefRequestService.CancelWfhRequest(this.cancelWfhRequestModel).subscribe((res) =>{
          this.messageService.display('Work from home Request Cancelled successfully !','OK','success');
          this.fetchWfhRequest();
          this.router.navigate(['/wfhRequest']);
        });
      }else{
        return false;
      }
    }
  }

  findHelpUrlPage(){
    const CurrentPage = "/wfhRequest";
    window.open(this.helpService.HELP_BASE_URL+CurrentPage,"_blank");
  }

  cancelApprovedWfhRequestAction(wfhRequestId,wfhRequestStatus){
    const dialogRef = this.dialog.open(CancelWfhApprovedComponent,{
      data: {wfhRequestId,wfhRequestStatus,reasonForWfhCancel:''}
    });

    dialogRef.afterClosed().subscribe(result =>{
      if(result =='applied'){
        this.fetchWfhRequest();
        this.router.navigate(['/wfhRequest']);
        this.loaderService.updateInboxAction(true);
      }
    });
  }

  checkStatus(wfhRequestStatus){
    let isDisabled = true;
    if(wfhRequestStatus === 'APPROVED'){
      isDisabled = false;
    }
    else if(wfhRequestStatus === 'PENDING_APPROVAL'){
      isDisabled = false;
    }
    return isDisabled;
  }
}
