import { EmployeeService } from './../../core/services/employee.service';
import { HelpService } from './../../core/services/help.service';
import { Component, OnInit } from '@angular/core';
import { ActivitiesService } from 'src/app/core/services/activities.service';

@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.css']
})
export class ActivitiesComponent implements OnInit {

  pageNo = 0;
  pageSize = 20;
  leaveActivities = [];
  systemActivities = [];
  allActivities = [];
  spinner = true;
  employeesId = [];
  uniqueEmployeeId = [];
  profilePic = [];
  allActivity = []
  leaveActivity = [];
  systemActivity = [];
  leaveActivityData:any;
  isCheckRecord: boolean;
  isVisibleRecord: boolean = true;
  img = "../../../assets/images/avatar.jpg";
  isManagementRole: boolean  = false;
  isVisibleNotfoundImg : boolean  = false;

  constructor(private helpService: HelpService,
              private activitiesService: ActivitiesService,
              private employeeService: EmployeeService) { }

  ngOnInit(): void {
    this.getEmployeeId(this.pageNo, this.pageSize , 'All Activity');
  }

  getEmployeeId(pageNo: number, pageSize: number, event) {
    let count=0;
    this.activitiesService.fetchAllActivities(this.activityFilterBasedOnAuthorization(), pageNo, pageSize).subscribe(res => {
      let leaveActivityCount = res?.listOfLeaveActivity?.length;
     if(leaveActivityCount) {
      res?.listOfLeaveActivity?.find(res=> {
        count++;
        if(this.employeesId.indexOf(res.employeeId) == -1) {
          count=0;
          this.employeesId.push(res.employeeId)
          this.fetchEmpProfilePic(res.employeeId, event);
        }
        else if(count == leaveActivityCount) {
         this.isCheckRecord = true;
          this.getAllActivities(this.pageNo, this.pageSize, event);
         }
      });
     }
     else {
        this.spinner = false;
        this.isCheckRecord = true;
        this.allActivities = res.listOfSystemActivity;
        res.listOfSystemActivity.filter(res=>{
          this.allActivity.push(res);
        });
     }
    });
  }

  fetchEmpProfilePic(employeeId, event) {
    this.employeeService.fetchEmpProfilePic(employeeId).subscribe((res: any) => {
      this.uniqueEmployeeId.push(employeeId);
     if (res.isProfileImageExist) {
      this.profilePic.push(res.imageBase64Encoded)
     }
     else {
      this.profilePic.push("../../../assets/images/avatar.jpg")
     }
    if(this.employeesId.length == this.uniqueEmployeeId.length) {
    this.isCheckRecord = true;
      this.getAllActivities(this.pageNo, this.pageSize, event);
    }
    });
  }

  getAllActivities(pageNo, pageSize, event) {
    this.spinner = true;
    let activity;
      switch(event) {
        case "Leave Activity":
          activity = ['LEAVE_ACTIVITY'];
          break;
          case "System Activity":
            activity = ['SYSTEM_ACTIVITY'];
            break;
        default:
          activity = this.activityFilterBasedOnAuthorization();
      }
      this.activitiesService.fetchAllActivities(activity, pageNo, pageSize).subscribe(res=>{
        this.spinner = false;
        res.employeeProfilePic = this.profilePic[this.uniqueEmployeeId.indexOf(res.employeeId)];
        this.leaveActivities = res?.listOfLeaveActivity;
        this.systemActivities = res?.listOfSystemActivity;
     if(res?.listOfSystemActivity && res?.listOfLeaveActivity) {
      this.isVisibleRecord = (res?.listOfSystemActivity.length || res?.listOfLeaveActivity.length) ? false : true;
    res?.listOfLeaveActivity.find(res=> {
      res.employeeProfilePic = this.profilePic[this.uniqueEmployeeId.indexOf(res.employeeId)]
   });
      res?.listOfSystemActivity.filter(res=>{
         this.allActivity.push(res);
         this.allActivities.push(res);
       });
       res?.listOfLeaveActivity.filter(res=> {
        this.allActivity.push(res);
        this.allActivities.push(res);
      });
      this.allActivity.sort(function(a,b) {
        return b.createDateTime - a.createDateTime;
      });
    }
     else  if(res?.listOfLeaveActivity) {
        this.isVisibleRecord = res?.listOfLeaveActivity.length ? false : true;
        res?.listOfLeaveActivity.find(res=> {
          res.employeeProfilePic = this.profilePic[this.uniqueEmployeeId.indexOf(res.employeeId)]
       });
       res?.listOfLeaveActivity.filter(res=> {
        this.allActivity.push(res);
        this.leaveActivity.push(res);
        this.allActivities.push(res);
      });
       }
      else {
        this.isVisibleRecord = res?.listOfSystemActivity.length ? false : true;
        res?.listOfSystemActivity.filter(res=>{
       //   this.isCheckRecord = true;
          this.allActivity.push(res);
          this.systemActivity.push(res);
          this.allActivities.push(res);
        });
      }
    });
  }

  findHelpUrlPage() {
    const currentPage = "/#_2_2_leave_request";
    window.open(this.helpService.HELP_BASE_URL + currentPage, "_blank");
  }

  loadMoreActivity(event) {
    this.allActivities = []
    this.pageNo++;
      this.getEmployeeId(this.pageNo, this.pageSize, event)
  }

  activityData(event) {
    this.allActivities = []
    this.leaveActivity = [];
    this.allActivity = [];
    this.systemActivity = [];
    this.isCheckRecord = false;
    this.pageSize = 20;
    this.pageNo = 0;
    this.spinner = true;
    this.getAllActivities(this.pageNo, this.pageSize, event);  
  }

  loadMoreSystemActivity(event) {
    this.pageNo++;
    this.getAllActivities(this.pageNo, this.pageSize, event);
  }

  activityFilterBasedOnAuthorization() {
    let requestParam  = ['LEAVE_ACTIVITY']
    JSON.parse(sessionStorage.getItem('loginData')).userRoles.filter(res=>{
      res.permissions.filter(permission=>{
        if(permission.name === 'ROLE_VIEW_SYSTEM_ACTIVITY') {
           requestParam.push('SYSTEM_ACTIVITY');
        }
      });
    });
    return requestParam;
  }

}
