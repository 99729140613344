import { FormGroup, FormControl, Validator, Validators, FormArray } from '@angular/forms';


export interface AttendanceRequestActionModel {
    attendanceRequestId: number,
    reasonForAttendanceRequest: string,
    attendanceType: string,
    attendanceRequestApprovers: any,
    attendanceFromDate: Date,
    attendanceSummaryId: number,
    entryTime: Date,
    exitTime: Date,
    attendanceRequestStatus: string,
    approverActionComment: string
    userId: number,
    listOfUserForNotificationReference: Array<any>;
    attendanceRequestedDate: Date
    
}

export class AttendanceRequestActionBuilder {
    attendanceRequestActionModel: AttendanceRequestActionModel = {
        attendanceRequestId: null,
        reasonForAttendanceRequest: '',
        attendanceType: '',
        attendanceRequestApprovers: [],
        attendanceFromDate: null,
        attendanceSummaryId: null,
        entryTime: null,
        exitTime: null,
        approverActionComment: '',
        attendanceRequestStatus: '',
        userId: null,
        listOfUserForNotificationReference: [],
        attendanceRequestedDate: null,
    };

    attendanceRequestActionForm: FormGroup = new FormGroup({
        attendanceRequestId: new FormControl(this.attendanceRequestActionModel.attendanceRequestId),
        reasonForAttendanceRequest: new FormControl(this.attendanceRequestActionModel.reasonForAttendanceRequest),
        attendanceType: new FormControl(this.attendanceRequestActionModel.attendanceType),
        attendanceRequestApprovers: new FormControl(this.attendanceRequestActionModel.attendanceRequestApprovers),
        attendanceFromDate: new FormControl(this.attendanceRequestActionModel.attendanceFromDate),
        entryTime: new FormControl(this.attendanceRequestActionModel.entryTime),
        attendanceSummaryId: new FormControl(this.attendanceRequestActionModel.attendanceSummaryId),
        exitTime: new FormControl(this.attendanceRequestActionModel.exitTime),
        approverActionComment: new FormControl(this.attendanceRequestActionModel.approverActionComment),
        attendanceRequestStatus: new FormControl(this.attendanceRequestActionModel.attendanceRequestStatus),
        userId: new FormControl(this.attendanceRequestActionModel.userId, Validators.required),
        listOfUserForNotificationReference: new FormControl(this.attendanceRequestActionModel.listOfUserForNotificationReference),
        attendanceRequestedDate: new FormControl(this.attendanceRequestActionModel.attendanceRequestedDate)
    });
    attendanceRequestModel: AttendanceRequestActionBuilder;
    constructor() {
        this.attendanceRequestActionForm.valueChanges.subscribe(val => {
            this.attendanceRequestActionModel.attendanceRequestId = val.attendanceRequestId;
            this.attendanceRequestActionModel.reasonForAttendanceRequest = val.reasonForAttendanceRequest;
            this.attendanceRequestActionModel.attendanceType = val.attendanceType;
            this.attendanceRequestActionModel.attendanceRequestApprovers = val.attendanceRequestApprovers;
            this.attendanceRequestActionModel.attendanceFromDate = val.attendanceFromDate;
            this.attendanceRequestActionModel.attendanceSummaryId = val.attendanceSummaryId;
            this.attendanceRequestActionModel.entryTime = val.entryTime;
            this.attendanceRequestActionModel.exitTime = val.exitTime;
            this.attendanceRequestActionModel.approverActionComment = val.approverActionComment;
            this.attendanceRequestActionModel.attendanceRequestStatus = val.attendanceRequestStatus;
            this.attendanceRequestActionModel.userId = val.userId;
            this.attendanceRequestActionModel.listOfUserForNotificationReference = val.listOfUserForNotificationReference;
            this.attendanceRequestActionModel.attendanceRequestedDate = val.attendanceRequestedDate;

        });
    }
    setModelVals(res: AttendanceRequestActionModel) {
        this.attendanceRequestActionForm.controls.attendanceRequestId.setValue(res.attendanceRequestId);
        this.attendanceRequestActionForm.controls.reasonForAttendanceRequest.setValue(res.reasonForAttendanceRequest);
        this.attendanceRequestActionForm.controls.attendanceType.setValue(res.attendanceType);
        this.attendanceRequestActionForm.controls.attendanceRequestApprovers.setValue(res.attendanceRequestApprovers);
        this.attendanceRequestActionForm.controls.approverActionComment.setValue(res.approverActionComment);
        this.attendanceRequestActionForm.controls.attendanceFromDate.setValue(res.attendanceFromDate);
        this.attendanceRequestActionForm.controls.attendanceSummaryId.setValue(res.attendanceSummaryId);
        this.attendanceRequestActionForm.controls.entryTime.setValue(res.entryTime.toLocaleTimeString);
        this.attendanceRequestActionForm.controls.exitTime.setValue(res.exitTime.toLocaleTimeString);
        this.attendanceRequestActionForm.controls.attendanceRequestStatus.setValue(res.attendanceRequestStatus);
        this.attendanceRequestActionForm.controls.userId.setValue(res.userId);
        this.attendanceRequestActionForm.controls.listOfUserForNotificationReference.setValue(res.listOfUserForNotificationReference);
        this.attendanceRequestActionForm.controls.attendanceRequestedDate.setValue(res.attendanceRequestedDate);
    }
}


export interface CancelAttendanceRequestModel {
    reasonForAttendanceRequestCancel: string,
    attendanceRequestId: number,
    attendanceRequestStatus: string,
    attendanceFromDate: Date,
    entryTime: Date,
    exitTime: Date,
}

export class CancelAttendanceRequestBuilder {
    cancelAttendanceRequestModel: CancelAttendanceRequestModel = {
        reasonForAttendanceRequestCancel: '',
        attendanceRequestId: null,
        attendanceRequestStatus: '',
        attendanceFromDate: null,
        entryTime: null,
        exitTime: null,
    };

    cancelAttendanceRequestForm: FormGroup = new FormGroup({
        reasonForAttendanceRequestCancel: new FormControl(this.cancelAttendanceRequestModel.reasonForAttendanceRequestCancel, [Validators.required]),
        attendanceRequestId: new FormControl(this.cancelAttendanceRequestModel.attendanceRequestId, [Validators.required]),
        attendanceRequestStatus: new FormControl(this.cancelAttendanceRequestModel.attendanceRequestStatus, [Validators.required]),
        attendanceFromDate: new FormControl(this.cancelAttendanceRequestModel.attendanceFromDate),
        entryTime: new FormControl(this.cancelAttendanceRequestModel.entryTime),
        exitTime: new FormControl(this.cancelAttendanceRequestModel.exitTime),
    });
    constructor() {
        this.cancelAttendanceRequestForm.valueChanges.subscribe(val => {
            this.cancelAttendanceRequestModel.reasonForAttendanceRequestCancel = val.reasonForAttendanceRequestCancel;
            this.cancelAttendanceRequestModel.attendanceRequestId = val.attendanceRequestId;
            this.cancelAttendanceRequestModel.attendanceRequestStatus = val.attendanceRequestStatus;
            this.cancelAttendanceRequestModel.attendanceFromDate = val.attendanceFromDate;
            this.cancelAttendanceRequestModel.entryTime = val.entryTime;
            this.cancelAttendanceRequestModel.exitTime = val.exitTime;
        });
    }

    setModelVals(res: CancelAttendanceRequestModel) {
        this.cancelAttendanceRequestForm.controls.reasonForAttendanceRequestCancel.setValue(res.reasonForAttendanceRequestCancel);
        this.cancelAttendanceRequestForm.controls.attendanceRequestId.setValue(res.attendanceRequestId);
        this.cancelAttendanceRequestForm.controls.attendanceRequestStatus.setValue(res.attendanceRequestStatus);
        this.cancelAttendanceRequestForm.controls.attendanceFromDate.setValue(res.attendanceFromDate.toLocaleDateString());
        this.cancelAttendanceRequestForm.controls.entryTime.setValue(res.entryTime.toLocaleTimeString());
        this.cancelAttendanceRequestForm.controls.exitTime.setValue(res.exitTime.toLocaleTimeString());
    }
}


export interface CancelApprovedAttendanceRequestActionModel {
    attendanceRequestId: number,
    employeeId: number,
    approverActionComment: string,
    reasonForAttendanceRequestCancel: string,
    listOfUserForNotificationReference: Array<any>
    attendanceFromDate: Date,
    entryTime: Date,
    exitTime: Date,
}
export class CancelApprovedAttendanceRequestActionBuilder {
    CancelApprovedAttendanceRequestActionModel: CancelApprovedAttendanceRequestActionModel = {
        attendanceRequestId: null,
        employeeId: null,
        listOfUserForNotificationReference: [],
        approverActionComment: '',
        reasonForAttendanceRequestCancel: '',
        entryTime: null,
        exitTime: null,
        attendanceFromDate: null
    };
    CancelApprovedAttendanceRequestActionForm: FormGroup = new FormGroup({
        attendanceRequestId: new FormControl(this.CancelApprovedAttendanceRequestActionModel.attendanceRequestId, [Validators.required]),
        employeeId: new FormControl(this.CancelApprovedAttendanceRequestActionModel.employeeId, [Validators.required]),
        listOfUserForNotificationReference: new FormControl(this.CancelApprovedAttendanceRequestActionModel.listOfUserForNotificationReference),
        approverActionComment: new FormControl(this.CancelApprovedAttendanceRequestActionModel.approverActionComment),
        reasonForAttendanceRequestCancel: new FormControl(this.CancelApprovedAttendanceRequestActionModel.reasonForAttendanceRequestCancel),
        entryTime: new FormControl(this.CancelApprovedAttendanceRequestActionModel.entryTime),
        exitTime: new FormControl(this.CancelApprovedAttendanceRequestActionModel.exitTime),
        attendanceFromDate: new FormControl(this.CancelApprovedAttendanceRequestActionModel.attendanceFromDate),

    })

    constructor() {
        this.CancelApprovedAttendanceRequestActionForm.valueChanges.subscribe(val => {
            this.CancelApprovedAttendanceRequestActionModel.attendanceRequestId = val.attendanceRequestId;
            this.CancelApprovedAttendanceRequestActionModel.employeeId = val.employeeId;
            this.CancelApprovedAttendanceRequestActionModel.approverActionComment = val.approverActionComment;
            this.CancelApprovedAttendanceRequestActionModel.reasonForAttendanceRequestCancel = val.reasonForAttendanceRequestCancel;
            this.CancelApprovedAttendanceRequestActionModel.listOfUserForNotificationReference = val.listOfUserForNotificationReference;
            this.CancelApprovedAttendanceRequestActionModel.exitTime = val.exitTime;
            this.CancelApprovedAttendanceRequestActionModel.entryTime = val.entryTime;
            this.CancelApprovedAttendanceRequestActionModel.attendanceFromDate = val.attendanceFromDate;
        });
    }
    setModelVals(res: CancelApprovedAttendanceRequestActionModel) {
        this.CancelApprovedAttendanceRequestActionForm.controls.attendanceRequestId.setValue(res.attendanceRequestId);
        this.CancelApprovedAttendanceRequestActionForm.controls.employeeId.setValue(res.employeeId);
        this.CancelApprovedAttendanceRequestActionForm.controls.approverActionComment.setValue(res.approverActionComment);
        this.CancelApprovedAttendanceRequestActionForm.controls.reasonForAttendanceRequestCancel.setValue(res.reasonForAttendanceRequestCancel);
        this.CancelApprovedAttendanceRequestActionForm.controls.listOfUserForNotificationReference.setValue(res.listOfUserForNotificationReference);
        this.CancelApprovedAttendanceRequestActionForm.controls.entryTime.setValue(res.entryTime.toLocaleTimeString());
        this.CancelApprovedAttendanceRequestActionForm.controls.exitTime.setValue(res.exitTime.toLocaleTimeString());
        this.CancelApprovedAttendanceRequestActionForm.controls.attendanceFromDate.setValue(res.attendanceFromDate.toLocaleDateString());
    }
}