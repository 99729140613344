import { HelpService } from 'src/app/core/services/help.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UserCalendarService } from 'src/app/core/services/user-calendar.service';
import { MatTableDataSource } from '@angular/material/table';
import { LinkCalendarModel } from 'src/app/shared/models/leave-calendar-model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CalendarSummaryComponent, ConfirmationBoxComponent } from 'src/app/shared/popups/popups';
import { LoaderService } from 'src/app/shared/factory/loader.service';
import { MessageService } from 'src/app/shared/factory/message.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-link-calendar',
  templateUrl: './link-calendar.component.html',
  styleUrls: ['./link-calendar.component.css']
})
export class LinkCalendarComponent implements OnInit {

  displayedColumns = ['id', 'calendar_type', 'calendar_event_type', 'email_id', 'action'];
  dataSource: MatTableDataSource<LinkCalendarModel>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  hideAddCalenderLinkBtn: boolean = false;

  constructor(private helpService: HelpService,
    private userCalendarService: UserCalendarService,
    private loaderService: LoaderService,
    private messageService: MessageService,
    private dialog: MatDialog,) { }

  ngOnInit(): void {
    this.fetchUserCalendars();
    this.dataSource = new MatTableDataSource<LinkCalendarModel>([]);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  findHelpUrlPage(){
    const currentPage = "/#_6_8_manage_job_descriptions";
    window.open(this.helpService.HELP_BASE_URL + currentPage, "_blank");
  }

  fetchUserCalendars() {
    this.userCalendarService.fetchUserCalendars(['HOLIDAY', 'LEAVE', 'WFH']).subscribe((res) => {
      if (res.length >= 1) {
        this.hideAddCalenderLinkBtn = true;
      }
      else {
        this.hideAddCalenderLinkBtn = false;
      }
      this.dataSource = new MatTableDataSource<LinkCalendarModel>(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  openDialogCalendarSummaryComponent(rowId) {
    this.userCalendarService.getCalendarSummary(rowId).subscribe((res) => {
      const dialogRef = this.dialog.open(CalendarSummaryComponent, {
        width: '30%',
        data: { res: res, calendarId: rowId }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result == 'applied') {
          this.fetchUserCalendars();
          this.loaderService.updateInboxAction(true);
        }
      });
    },
      () => {
        this.messageService.display('Error while unlinking user account. ', 'OK', 'error');
      });
  }

  unlinkUserCalendar(rowId) {
    const dialogRef = this.dialog.open(ConfirmationBoxComponent, {
      disableClose: true,
      width: '30%',
      data: { calendarId: rowId, title: 'Confirm Deletion', message: 'Are you sure you want to remove the calendar' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'applied') {
        this.onYesClickToDialog(rowId)
      }
    });
  }

  onYesClickToDialog(rowId) {
    this.userCalendarService.unlinkUserCalendar(rowId).subscribe(() => {
      this.messageService.display('User Calendar Link Removed Successfully. ', 'OK', 'success');
      this.fetchUserCalendars();
      this.loaderService.updateInboxAction(true);
    },
      () => {
        this.messageService.display('Error while unlinking user account. ', 'OK', 'error');
      });
  }

}
