import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LeaveEncashmentRequestModel } from 'src/app/shared/models/leave-encashment-model';
import { HttpRequestService } from '../http/http-request.service';

@Injectable({
  providedIn: 'root'
})
export class LeaveEncashmentRequestService {

  private stateUrl = 'api/v1/leave-encashment-request';

  constructor(private httpRequest: HttpRequestService) { }

  fetchLeaveEncashmentRequest(): Observable<LeaveEncashmentRequestModel[]> {
    return this.httpRequest.get(this.stateUrl);
  }

  addLeaveEncashmentRequest(leaveEncashmentModel: LeaveEncashmentRequestModel): Observable<any> {
    return this.httpRequest.post(this.stateUrl, leaveEncashmentModel);
  }
}
