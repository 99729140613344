import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { SSOGatewayComponent } from './sso-gateway/sso-gateway.component';
import { MaterialModule } from '../shared/modules/material/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';  // <-- #1 import module
import { LoginService } from '../core/services/login.service';
import { SSOGatewayService } from '../core/services/sso-gateway.service';
import { AppRoutingModule } from '../app-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './home/home.component';
import { HolidaysComponent } from './holidays/holidays.component';
import { HolidayAddEditComponent } from './holiday-add-edit/holiday-add-edit.component';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog'
import { NgxPermissionsModule } from 'ngx-permissions';
import { FinancialConfigYearComponent } from './financial-config-year/financial-config-year.component';
import { LeaveTypeComponent } from './leave-type/leave-type.component';
import { OrganizationComponent } from './organization/organization.component';
import { LeaveTypeAddEditComponent } from './leave-type-add-edit/leave-type-add-edit.component';
import { LeaveRequestComponent } from './leave-request/leave-request.component';
import { ApplyLeaveComponent } from './apply-leave/apply-leave.component';
import { LeaveActionComponent } from './leave-action/leave-action.component';
import { LeaveActionRequestComponent, BoardingFinancialConfigYearComponent, ConfirmationDialogComponent, AttendanceEntryLogComponent, ApprovedLeaveActionRequestComponent, CancelApprovedComponent, CalendarSummaryComponent, ConfirmationBoxComponent, HistoricalLeaveComponent, AddLeaveAdjustRequestComponent } from '../shared/popups/popups';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { CalendarModule } from 'angular-calendar';
import { EmployeeLeaveStateComponent } from './employee-leave-state/employee-leave-state.component';
import { CarryForwardComponent } from './carry-forward/carry-forward.component';
import { CarryForwardDetailComponent } from './carry-forward-detail/carry-forward-detail.component';
import { HistoricalLeaveRequestComponent } from './historical-leave-request/historical-leave-request.component';
import { ActivitiesComponent } from './activities/activities.component';
import { AgoDatePipe } from '../pipes/ago-date.pipe';
import { UserNamePipe } from '../pipes/user-name.pipe';
import { LinkCalendarComponent } from './link-calendar/link-calendar.component';
import { LinkCalendarAddEditComponent } from './link-calendar-add-edit/link-calendar-add-edit.component';
import { LeaveEncashmentRequestComponent } from './leave-encashment-request/leave-encashment-request/leave-encashment-request.component';
import { ApplyEncashmentLeaveComponent } from './apply-encashment-leave/apply-encashment-leave/apply-encashment-leave.component';
import { LeaveEncashmentActionComponent } from './leave-encashment-action/leave-encashment-action/leave-encashment-action.component';
import { MatSelectModule } from '@angular/material/select';
import { ApplyWfhComponent } from './apply-wfh/apply-wfh.component';
import { ApprovedWfhActionRequestComponent, CancelWfhApprovedComponent, WfhActionRequestComponent } from '../shared/popups/wfh-popups';
import { WfhActionComponent } from './wfh-action/wfh-action.component';
import { WfhRequestComponent } from './wfh-request/wfh-request.component';
import { ApplyAttendanceRegularisationRequestComponent } from './apply-attendance-regularisation/apply-attendance-regularisation-request.component';
import { MyAttendanceComponent } from './my-attendance/my-attendance/my-attendance.component';
import { AttendanceRegularisationActionComponent } from './attendance-regularisation-action/attendance-regularisation-action.component';
import { ApprovedAttendanceActionRequestComponent, AttendanceActionRequestComponent } from '../shared/popups/attendance-popups';
import {NgxMatTimepickerModule} from 'ngx-mat-timepicker';
import { MissingAttendanceActionComponent } from './missing-attendance-action/missing-attendance-action.component';
import { LeaveAdjustComponent } from './leave-adjust/leave-adjust.component';
import { MyDashboardComponent } from './my-dashboard/my-dashboard.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    AppRoutingModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    CalendarModule,
    MatSelectModule,
    NgxMatTimepickerModule,
    NgxPermissionsModule.forRoot()
  ],
  providers: [
    LoginService,
    SSOGatewayService,
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true, disableClose: false } },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 10000 } }
  ],
  declarations: [
    LoginComponent,
    SSOGatewayComponent,
    DashboardComponent,
    HomeComponent,
    HolidaysComponent,
    HolidayAddEditComponent,
    FinancialConfigYearComponent,
    LeaveTypeComponent,
    OrganizationComponent,
    LeaveTypeAddEditComponent,
    LeaveRequestComponent,
    ApplyLeaveComponent,
    LeaveActionComponent,
    LeaveActionRequestComponent,
    BoardingFinancialConfigYearComponent,
    EmployeeLeaveStateComponent,
    CarryForwardComponent,
    CarryForwardDetailComponent,
    ConfirmationDialogComponent,
    HistoricalLeaveComponent,
    AttendanceEntryLogComponent,
    HistoricalLeaveRequestComponent,
    ApprovedLeaveActionRequestComponent,
    CancelApprovedComponent,
    ActivitiesComponent,
    AgoDatePipe,
    UserNamePipe,
    LinkCalendarComponent,
    LinkCalendarAddEditComponent,
    CalendarSummaryComponent,
    ConfirmationBoxComponent,
    LeaveEncashmentRequestComponent,
    ApplyEncashmentLeaveComponent,
    LeaveEncashmentActionComponent,
    ApplyWfhComponent,
    WfhActionComponent,
    WfhRequestComponent,
    CancelApprovedComponent,
    CancelWfhApprovedComponent,
    ApprovedWfhActionRequestComponent,
    WfhActionRequestComponent,
    ApprovedAttendanceActionRequestComponent,
    AttendanceActionRequestComponent,
    MyAttendanceComponent,
    ApplyAttendanceRegularisationRequestComponent,
    AttendanceRegularisationActionComponent,
    MissingAttendanceActionComponent,
    LeaveAdjustComponent,
    AddLeaveAdjustRequestComponent,
    MyDashboardComponent
  ],
  entryComponents: [
    LeaveActionRequestComponent,
    ApplyEncashmentLeaveComponent,
    BoardingFinancialConfigYearComponent,
    ConfirmationDialogComponent,
    HistoricalLeaveComponent,
    AttendanceEntryLogComponent,
    ApprovedLeaveActionRequestComponent,
    CancelApprovedComponent,
    CalendarSummaryComponent,
    ConfirmationBoxComponent,
    CancelWfhApprovedComponent,
    ApprovedWfhActionRequestComponent,
    WfhActionRequestComponent,
    ApprovedAttendanceActionRequestComponent,
    AttendanceActionRequestComponent,
    AddLeaveAdjustRequestComponent
  ]
})
export class ComponentsModule { }
