import { FormControl, FormGroup, Validators } from "@angular/forms";

export interface EmployeeAttendanceDTOsModel {
    entryTime: string,
    exitTime: string,
    date: string,
    totalHours: string,
}

export class EmployeeAttendanceDTOsBuilder {
    employeeAttendanceDTOsModel: EmployeeAttendanceDTOsModel = {
        entryTime: '',
        exitTime: '',
        date: '',
        totalHours: '',
    };

    employeeAttendanceDTOsForm: FormGroup = new FormGroup({

        entryTime: new FormControl(this.employeeAttendanceDTOsModel.entryTime, [Validators.required]),
        exitTime: new FormControl(this.employeeAttendanceDTOsModel.exitTime, [Validators.required]),
        date: new FormControl(this.employeeAttendanceDTOsModel.date, [Validators.required]),
        totalHours: new FormControl(this.employeeAttendanceDTOsModel.totalHours, [Validators.required]),
    });

    constructor() {
        this.employeeAttendanceDTOsForm.valueChanges.subscribe(val => {
            this.employeeAttendanceDTOsModel.entryTime = val.entryTime;
            this.employeeAttendanceDTOsModel.exitTime = val.exitTime;
            this.employeeAttendanceDTOsModel.date = val.date;
            this.employeeAttendanceDTOsModel.totalHours = val.totalHours;
        });
    }
    setModelVals(res: EmployeeAttendanceDTOsModel) {

        this.employeeAttendanceDTOsForm.controls.entryTime.setValue(res.entryTime);
        this.employeeAttendanceDTOsForm.controls.exitTime.setValue(res.exitTime);
        this.employeeAttendanceDTOsForm.controls.date.setValue(res.date);
        this.employeeAttendanceDTOsForm.controls.totalHours.setValue(res.totalHours);
    }
}